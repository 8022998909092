import { FC, ReactNode } from "react";
import { PassContainer } from "./PassContainer";
import { Header } from "./Header";
import { Box } from "@mui/material";
import { PassField } from "../CreatePassEntity";

interface IProps {
  passBgColor?: string;
  passFgColor?: string;
  passLabelColor?: string;
  passBgImage?: string;
  logoText?: string;
  logoTextAlign?: "center" | "left";
  headerFields: PassField[];
  footer?: ReactNode;
  stripBase64?: string;
  stripStyles?: Record<string, string>;
  logo: string;
}
export const PassFront: FC<IProps> = ({
  passBgColor,
  passFgColor,
  passLabelColor,
  passBgImage,
  stripBase64,
  stripStyles,
  logoText,
  logoTextAlign,
  headerFields,
  children,
  footer,
  logo,
}) => (
  <PassContainer
    bgColor={passBgColor}
    color={passFgColor}
    stripBase64={stripBase64}
    stripStyles={stripStyles}
    bgImageUrl={passBgImage}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Header
          headerFields={headerFields}
          logoText={logoText}
          logoTextAlign={logoTextAlign}
          passLabelColor={passLabelColor}
          passFgColor={passFgColor}
          logo={logo}
        />
        {children}
      </Box>
      <Box>{footer}</Box>
    </Box>
  </PassContainer>
);
