import { FC } from "react";
import { get } from "lodash";
import { getContainer } from "../../../diContainer/container";
import { useObservable } from "@roketus/web-toolkit";
import { IWizardService } from "../../../boundary/IWizardService";
import loyoLogoImg from "./previewData/loyo-logo.png";
import { stripBase64StoreCard } from "./previewData/stripBase64";
import { storeCardData } from "./previewData/storeCardData";
import { couponCardData } from "./previewData/couponCardData";
import { getStampCardData } from "./previewData/stampCardData";
import { eventTicketData } from "./previewData/eventTicketData";
import { CardTemplatePreview } from "./CardTemplatePreview";
import { IBarcodeType } from "./sharedInterfaces";
import {
  COUPON_TEMPLATES_IDS,
  EVENT_TICKET_TEMPLATES_IDS,
  STAMP_TEMPLATES_IDS,
} from "./previewData/templatesConfig";
import {
  checkIfEventTicket,
  checkIfStampCard,
} from "../../../adapters/services/wizardService/wizardService";

export const convertBarcodeFormatIdToIBarcodeType = (
  barcodeFormatId?: string
): IBarcodeType => {
  switch (barcodeFormatId) {
    case "1":
      return "PDF_417";
    case "2":
      return "QR_CODE";
    case "3":
      return "AZTEC";
    default:
      return "TEXT";
  }
};

export enum TextFieldAlignment {
  Left = "1",
  Center = "2",
  Right = "3",
  Natural = "4",
}

export interface BaseTextFieldEntity {
  key: string;
  label: string;
  value: string;
  id?: number;
}

export interface TextFieldEntity extends BaseTextFieldEntity {
  alignment: TextFieldAlignment;
}

export interface BackPassFieldEntity extends BaseTextFieldEntity {}

export interface PassField {
  align?: "left" | "right" | "center" | "inherit";
  value?: string;
  label?: string;
  key?: string;
}

const buildFields = (
  field: {
    label: string;
    value: string;
    key: string;
  }[]
): PassField[] => {
  return field.map(({ key, label, value }) => {
    return { key, label, value, align: "left" };
  });
};

const getPassDataByID = (id = "", isActivated = false) => {
  if (COUPON_TEMPLATES_IDS.includes(id)) return couponCardData;
  if (STAMP_TEMPLATES_IDS.includes(id)) return getStampCardData(isActivated);
  if (EVENT_TICKET_TEMPLATES_IDS.includes(id)) return eventTicketData;

  return storeCardData;
};

export const CardIOSPreview: FC = () => {
  const wizardService = getContainer().getDependency(
    "wizardService"
  ) as IWizardService;

  const wizardData = useObservable(wizardService.data$);

  const logoTemplateText = get(wizardData, "template.header.logoText");
  const id = get(wizardData, "template.id");

  const data = getPassDataByID(id, wizardData?.isExecutedTransaction);

  const logo = data.designFiles.logo.url || loyoLogoImg;
  const stripBase64 = data.designFiles.strip.url || stripBase64StoreCard;
  const stripStyles = data.designFiles.strip;
  const passBgColor = data.design.backgroundColor;
  const passFgColor = data.design.foregroundColor;
  const passLabelColor = data.design.labelColor;
  const passBgImage = data.design.backgroundImage;

  const logoText = wizardData?.businessName || logoTemplateText;
  const logoTextAlign =
    checkIfStampCard(id) || checkIfEventTicket(id) ? "left" : "center";
  const bodyPrimaryLabel = data.body.primaryFields[0]?.label;
  const bodyPrimaryValue = data.body.primaryFields[0]?.value;
  const bodyPrimaryStyleId = data.design.styleId;

  const headerFields = buildFields(data.header?.headerFields || []);
  const secondaryFields = buildFields(data.body?.secondaryFields || []);
  const auxiliaryFields = buildFields(data.body?.auxiliaryFields || []);

  const barcodeProps = {
    barcode: {
      text: "My Text",
      type: convertBarcodeFormatIdToIBarcodeType(
        data.barcode.MessageEncodingId
      ),
    },
  };

  return (
    <CardTemplatePreview
      passBgColor={passBgColor}
      passFgColor={passFgColor}
      passLabelColor={passLabelColor}
      passBgImage={passBgImage}
      logoText={logoText}
      logoTextAlign={logoTextAlign}
      bodyPrimaryLabel={bodyPrimaryLabel}
      bodyPrimaryValue={bodyPrimaryValue}
      bodyPrimaryStyleId={bodyPrimaryStyleId}
      headerFields={headerFields}
      secondaryFields={secondaryFields}
      auxiliaryFields={auxiliaryFields}
      stripBase64={stripBase64}
      stripStyles={stripStyles}
      logo={logo}
      {...barcodeProps}
    />
  );
};
