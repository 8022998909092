import { NumericFormat } from 'react-number-format';
import Card from "@mui/material/Card";
import { Box, Typography } from "@mui/material";
import { isUndefined } from "lodash";
import Divider from "@mui/material/Divider";
import { AnalyticsCardProps } from "../../boundary/AnalyticsService/AnalyticCard";
import styles from "./AnalyticsCard.module.css";

function AnalyticsCard({
  color,
  title,
  count,
  icon,
  percentage,
}: AnalyticsCardProps) {
  return (
    <Card
      className={styles.analyticsCard}
      sx={{
        color: "rgba(0, 0, 0, 0.87)",
        overflow: "visible",
      }}
    >
      <Box display="flex" justifyContent="space-between" pt={1} pr={2}>
        <Box className={styles.iconContainer} sx={{ backgroundColor: color }}>
          {icon}
        </Box>
        <Box textAlign="right" lineHeight={1.25}>
          <Typography variant="button" fontWeight="light" color="text" fontSize={12}>
            {title}
          </Typography>
          <Typography variant="h5">
            <NumericFormat
              value={count}
              displayType="text"
              thousandSeparator={' '}
            />
          </Typography>
        </Box>
      </Box>
      {!isUndefined(percentage) && (
        <>
          <Divider />
          <Box pb={2} px={2}>
            <Typography
              component="p"
              variant="button"
              color="text"
              display="flex"
            >
              <Typography
                component="span"
                variant="button"
                fontWeight="bold"
                color={percentage.color}
              >
                {percentage.amount}
              </Typography>
              &nbsp;{percentage.label}
            </Typography>
          </Box>
        </>
      )}
    </Card>
  );
}

export default AnalyticsCard;
