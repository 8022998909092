import { stripBase64StampCard, stripBase64StampCardActivated } from "./stripBase64";
import coffeeLogoImg from "./coffee-logo.png";

export const getStampCardData = (isActivated = false) => ({
  description: "description",
  header: {
    headerFields: [
      {
        label: "Cups",
        value: isActivated ? "5/5" : "4/5",
        key: "bonus",
        alignment: "2",
      },
    ],
  },
  body: {
    auxiliaryFields: [
      {
        label: "Name",
        value: "Luke Cole",
        key: "bonus",
        alignment: "2",
      },
    ],
    primaryFields: [
      {
        label: "",
        value: "",
        key: "bonus",
        alignment: "2",
      },
    ],
    secondaryFields: [],
  },
  barcode: {
    BarcodeFormatId: "2",
    BarcodeMessage: "lorem ipsum",
    MessageEncodingId: "2",
    UseBarcode: true,
  },
  design: {
    backgroundColor: "#1c0f03",
    foregroundColor: "#fffbf4",
    labelColor: "#fffbf4",
    backgroundImage: "none",
    styleId: "1",
  },
  designFiles: {
    icon: {},
    logo: {
      url: coffeeLogoImg,
    },
    strip: {
      url: isActivated ? stripBase64StampCardActivated : stripBase64StampCard,
    },
  },
});
