import { isNull } from "lodash";
import { IBarcodeType } from "./sharedInterfaces";
import bwipjs from "bwip-js";

export const createRenderBarcode =
  (barcode: { text: string; type: IBarcodeType; alternateText?: string }) =>
  (el: HTMLCanvasElement | null) => {
    const { text, type } = barcode;
    if (isNull(el)) return;

    const bcidMap: Record<IBarcodeType, string> = {
      ["PDF_417"]: "pdf417",
      ["QR_CODE"]: "qrcode",
      ["AZTEC"]: "azteccode",
      ["TEXT"]: "noop",
    };

    let width = 110;
    let height = 110;
    if (type === "PDF_417") {
      width = 190;
      height = 52;
    }

    bwipjs.toCanvas(el, {
      bcid: bcidMap[type],
      text,
      width,
      height,
    });
  };
