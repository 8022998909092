import { TEMPLATE_CHOSEN } from "../../../domain/specs/positiveCodes";
import {
  EVENT_TICKET_TEMPLATES_IDS,
  STAMP_TEMPLATES_IDS,
} from "../../../components/PreviewSection/CardIOSPreview/previewData/templatesConfig";
import type { WizardStep } from "../../../boundary/IWizardService";
import { ChooseTemplateStep } from "../../../components/Wizard/Steps/1ChooseTemplateStep";
import { InstallCardPhoneStep } from "../../../components/Wizard/Steps/2InstallCardPhoneStep";
import { CheckWelcomeBonusStep } from "../../../components/Wizard/Steps/CheckWelcomeBonusStep";
import { FeedbackStep } from "../../../components/Wizard/Steps/FeedbackStep";
import { ScanQrStep } from "../../../components/Wizard/Steps/ScanQrStep";
import { PayWithPointsStep } from "../../../components/Wizard/Steps/PayWithPointsStep";
import { MakePurchaseStep } from "../../../components/Wizard/Steps/MakePurchaseStep";
import { OpenPosTerminalStep } from "../../../components/Wizard/Steps/OpenPosTerminalStep";
import { PosMenuStep } from "../../../components/Wizard/Steps/PosMenuStep";
import { CardIOSPreview } from "../../../components/PreviewSection/CardIOSPreview/CardIOSPreview";
import { ContactForm } from "../../../components/PreviewSection/ContactFrom/ContactForm";
import { RelevantTimePreview } from "../../../components/PreviewSection/POSPreview/RelevantTimePreview";
import { PayWithPointsPreview } from "../../../components/PreviewSection/POSPreview/PayWithPointsPreview";
import { MakePurchasePreview } from "../../../components/PreviewSection/POSPreview/MakePurchasePreview";
import { PosMenuPreview } from "../../../components/PreviewSection/POSPreview/PosMenuPreview";
import { OpenPosTerminalPreview } from "../../../components/PreviewSection/POSPreview/OpenPosTerminalPreview";
import { ScanQrPreview } from "../../../components/PreviewSection/POSPreview/ScanQrPreview";
import { WelcomeBonusPreview } from "../../../components/PreviewSection/WelcomeBonusPreview/WelcomeBonusPreview";
import { RelevantTimeStep } from "../../../components/Wizard/Steps/RelevantTimeStep";
import { QRCodePreview } from "../../../components/PreviewSection/QRCodePreview/QRCodePreview";
import { PosHistoryStep } from "../../../components/Wizard/Steps/PosHistoryStep";
import { PosHistoryPreview } from "../../../components/PreviewSection/POSPreview/PosHistoryPreview";
import { PromoCodeStep } from "../../../components/Wizard/Steps/PromoCodeStep";
import { PromoCodeUsageStep } from "../../../components/Wizard/Steps/PromoCodeUsageStep";
import { PromoCodePreview } from "../../../components/PreviewSection/POSPreview/PromoCodePreview";
import { PromoCodeUsagePreview } from "../../../components/PreviewSection/POSPreview/PromoCodeUsagePreview";

export const getStepsByTemplate = (templateId?: string) => {
  if (STAMP_TEMPLATES_IDS.includes(templateId as string)) {
    return [
      "chooseTemplate",
      "previewLoyaltyCard",
      "welcomeBonus",
      "scanQr",
      "openPosTerminal",
      "posMenu",
      "makePurchase",
      "posHistory",
      "feedback",
    ];
  }

  if (EVENT_TICKET_TEMPLATES_IDS.includes(templateId as string)) {
    return [
      "chooseTemplate",
      "previewLoyaltyCard",
      "relevantTime",
      "promoCode",
      "scanQr",
      "openPosTerminal",
      "posMenu",
      "promoCodeUsage",
      "posHistory",
      "feedback",
    ];
  }

  return [
    "chooseTemplate",
    "previewLoyaltyCard",
    "welcomeBonus",
    "scanQr",
    "openPosTerminal",
    "posMenu",
    "payWithPoints",
    "posHistory",
    "feedback",
  ];
};

export const wizardSteps: WizardStep[] = [
  {
    key: "chooseTemplate",
    label: "Select Loyalty Program Template",
    component: ChooseTemplateStep,
    successMessageData: TEMPLATE_CHOSEN,
    helpTitle: "Loyalty Program Template",
    previewComponent: CardIOSPreview,
  },
  {
    key: "previewLoyaltyCard",
    label: "Preview your digital Loyalty Card",
    component: InstallCardPhoneStep,
    previewComponent: QRCodePreview,
  },
  {
    key: "welcomeBonus",
    label: "Welcome Bonus",
    component: CheckWelcomeBonusStep,
    helpTitle: "Welcome Bonus Example",
    previewComponent: WelcomeBonusPreview,
  },
  {
    key: "relevantTime",
    label: "Relevant time",
    component: RelevantTimeStep,
    previewComponent: RelevantTimePreview,
  },
	{
		key: "promoCode",
		label: "Promo code",
		component: PromoCodeStep,
		previewComponent: PromoCodePreview,
	},
  {
    key: "scanQr",
    label: "Scan a QR on the Digital Card",
    component: ScanQrStep,
    previewComponent: ScanQrPreview,
  },
  {
    key: "openPosTerminal",
    label: "Or open a POS Terminal",
    component: OpenPosTerminalStep,
    previewComponent: OpenPosTerminalPreview,
  },
  {
    key: "posMenu",
    label: "POS / Card Menu",
    component: PosMenuStep,
    previewComponent: PosMenuPreview,
  },
  {
    key: "payWithPoints",
    label: "Pay with Points from bonus account",
    component: PayWithPointsStep,
    previewComponent: PayWithPointsPreview,
  },
  {
    key: "makePurchase",
    label: "Make a purchase of a promotional item",
    component: MakePurchaseStep,
    helpTitle: "Card Preview",
    previewComponent: CardIOSPreview,
  },
	{
		key: "promoCodeUsage",
		label: "Promo code usage",
		component: PromoCodeUsageStep,
		previewComponent: PromoCodeUsagePreview,
	},
  {
    key: "posHistory",
    label: "POS / Transactions History",
    component: PosHistoryStep,
    previewComponent: PosHistoryPreview,
  },
  {
    key: "feedback",
    label: "Enjoyed the Demo?",
    component: FeedbackStep,
    helpTitle: "Book a demo",
    previewComponent: ContactForm,
  },
];
