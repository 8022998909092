const COUPON_TEMPLATE_ID_DEV = "1863";
const COUPON_TEMPLATE_ID_STAGE = "1614";
export const COUPON_TEMPLATES_IDS = [
  COUPON_TEMPLATE_ID_DEV,
  COUPON_TEMPLATE_ID_STAGE,
];

export const STAMP_TEMPLATE_ID_DEV = "2858";
export const STAMP_TEMPLATE_ID_STAGE = "1631"; // 1616
export const STAMP_TEMPLATES_IDS = [
  STAMP_TEMPLATE_ID_DEV,
  STAMP_TEMPLATE_ID_STAGE,
];

export const EVENT_TICKET_TEMPLATE_ID_DEV = "3063";
export const EVENT_TICKET_TEMPLATE_ID_STAGE = "1643";
export const EVENT_TICKET_TEMPLATES_IDS = [
  EVENT_TICKET_TEMPLATE_ID_DEV,
  EVENT_TICKET_TEMPLATE_ID_STAGE,
];
