import { FC } from "react";
import { Box } from "@mui/material";

import { BarcodeQR } from "./BarcodeQR";

import styled from "@emotion/styled";
import { IBarcodeTypeIOS } from "../../sharedInterfaces";

interface ICardBarcodeProps {
  text: string;
  type: IBarcodeTypeIOS;
}

const Container = styled(Box)`
  margin: 6px 16px 0;
  display: flex;
  justify-content: center;
`;

export const CardBarcode: FC<ICardBarcodeProps> = ({ text }) => {
  return (
    <Container>
      <BarcodeQR text={text} />
    </Container>
  );
};
