import { IMessage } from "../../../boundary/IMessage";

export interface PositiveMessageEntity extends IMessage {
  type: "positiveEvent";
  message: "";
  source: string;
  data: string;
}

export const isPositiveMessageEntity = (
  message: any
): message is PositiveMessageEntity => {
  return message.type === "positiveEvent";
};
