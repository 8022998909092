import {
  CreateTemplateDTO,
  UpdateTemplateDTO,
} from "../../../boundary/TemplateRepository/TemplateRepositoryDTO";
import {
  ExistingPassEntity,
  GenericPassEntity,
} from "../../../domain/entities/GenericPassEntity/GenericPassEntity";
import { getEventDateFromCurrent } from "../../../domain/utils";
import { convertToCreateTemplateDTO } from "./convertToCreateTemplateDTO";

export const convertToUpdateTemplateDTO = (
  pass: ExistingPassEntity
): CreateTemplateDTO => {
  const convertedTemplate = convertToCreateTemplateDTO(
    pass as GenericPassEntity
  );
  convertedTemplate.PassInfo.PassInfoId = parseInt(pass.id, 10);
  return convertedTemplate;
};

export const convertToUpdateRelevantDateDTO = (
  id: string
): UpdateTemplateDTO => {
  return {
    PassInfo: {
      PassInfoId: parseInt(id, 10),
      RelevantDate: getEventDateFromCurrent(),
    },
  };
};
