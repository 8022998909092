import { Typography } from "@mui/material";
import { StepDetails } from "../StepDetails";
import { WizardHelp } from "../WizardHelp";
import { StepNavigation } from "../StepNavigation";

export const CheckWelcomeBonusStep: React.FC = () => {
  return (
    <>
      <Typography gutterBottom>
        You will receive a welcome bonus on your loyalty account
      </Typography>
      <Typography gutterBottom>
        *You could check your bonus balance in Pass Details (three dots menu)
      </Typography>
      <Typography gutterBottom>*It may take up to 30 sec.</Typography>
      <StepDetails>
        <WizardHelp />
      </StepDetails>
      <StepNavigation />
    </>
  );
};
