import { FC } from "react";
import { Container, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import { isOS } from "../adapters/OS/isOS";
import { AutoUserIDSetter } from "./AutoUserIDSetter";
import PlaneSvg from "./PlaneSvg";

const isIOSSystem = isOS();

interface IProps {
  isDownloadAndroidCard?: boolean;
}

const CardDownloadMessageBox: FC<IProps> = ({ isDownloadAndroidCard }) => {
  return (
    <AutoUserIDSetter>
      <Container maxWidth="lg" sx={{ p: 2 }}>
        <Paper
          sx={{
            maxWidth: 380,
            padding: "1.5rem 0 1rem",
            margin: "0 auto",
            borderRadius: "12px",
            boxShadow: "0px 1px 12px 0px #00000026",
          }}
        >
          <Typography px={2}>
            {isIOSSystem || isDownloadAndroidCard ? (
              <span>
                <b>The Atomic Loyalty Demo card</b> is now being downloaded to
                your mobile wallet.
                <br />
                You may close the window when the downloading is finished.
              </span>
            ) : (
              "Please wait you'll be signed in..."
            )}
          </Typography>
          <PlaneSvg />
        </Paper>
      </Container>
    </AutoUserIDSetter>
  );
};

export default CardDownloadMessageBox;
