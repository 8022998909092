import { IMessage } from "../../../../boundary/IMessage";
import { isBoolean, isString, isUndefined } from "lodash";

export interface AuthDataEntity {
  isAuth: boolean;
  userName: string;
  useRefreshTokens: boolean;
  token?: string;
  refreshToken?: string;
  apiKey?: string;
}

export interface AuthMessageEntity extends IMessage {
  type: "authEntityChange";
  message: "";
  source: string;
  data: AuthDataEntity;
}

export const isAuthDataEntity = (obj: any): obj is AuthDataEntity => {
  return (
    !isUndefined(obj) &&
    isBoolean(obj.isAuth) &&
    isString(obj.userName) &&
    isBoolean(obj.useRefreshTokens) &&
    (isUndefined(obj.token) || isString(obj.token)) &&
    (isUndefined(obj.refreshToken) || isString(obj.refreshToken))
  );
};

export const isAuthMessageEntity = (obj: any): obj is AuthMessageEntity => {
  return (
    isAuthDataEntity(obj.data) &&
    obj.type === "authEntityChange" &&
    obj.message === "" &&
    isString(obj.source)
  );
};
