import { IHTTPClientResponse } from "@roketus/web-toolkit";
import { sdk } from "@roketus/loyalty-js-sdk";
import {
  IProfileService,
  ProfileEntity,
} from "../../boundary/ProfileService/ProfileService";
import {
  GetIssuerResponseDTO,
  GetProfileResponseDTO,
  ProfileSubscribeDTO,
  RefreshApiKeyResponseDTO,
} from "../../boundary/ProfileService/ProfileServiceDTOs";
import { getContainer } from "../../diContainer/container";
import { ClientResponse, HTTPClient } from "../../boundary/HTTPClient";
import {
  createErrorMessage,
  ErrorMessageBus,
  LoadableData,
  PersistentStorage,
  StateSubject,
} from "@roketus/web-toolkit";
import {
  ERROR_CODE_GET_ISSUER,
  ERROR_CODE_GET_PROFILE_FAILED,
  ERROR_CODE_REFRESH_API_KEY,
  ERROR_CODE_SET_ISSUER,
} from "../../domain/specs/errorCodes";

const stateMachine = new StateSubject<
  LoadableData & {
    profile: ProfileEntity;
    Issuer: string;
  }
>({
  profile: {
    Username: "",
    ApiKey: "",
  },
  Issuer: "",
  loading: false,
  isLoaded: false,
  failed: false,
});

const ISSUER_TEMPLATE_KEY = "default-template";

const fetchProfile = () => {
  const container = getContainer();
  const client = container.getDependency(
    "privatePassServerClients"
  ) as HTTPClient;

  stateMachine.setState({
    loading: true,
  });

  client
    .get("api/user/profile")
    .then((data: ClientResponse<GetProfileResponseDTO>) => {
      stateMachine.setState({
        profile: data.data.data,
        failed: false,
        isLoaded: true,
        loading: false,
      });
    })
    .catch((e: unknown) => {
      const messageBus = container.getDependency(
        "errorMessageBus"
      ) as ErrorMessageBus;
      const errorMessage = createErrorMessage({
        code: ERROR_CODE_GET_PROFILE_FAILED,
        source: "profileService",
        message: "Failed To Get Profile",
        error: e as Error,
      });
      messageBus.send(errorMessage);

      stateMachine.setState({
        failed: true,
        isLoaded: false,
        loading: false,
      });
    });
};

const saveProfile = (profile: unknown) => {
  const container = getContainer();
  const client = container.getDependency(
    "privatePassServerClients"
  ) as HTTPClient;
  return client.put("user", profile);
};

const refreshApiKey = () => {
  const container = getContainer();

  stateMachine.setState({
    loading: true,
  });

  sdk.issuer
    .refreshApiKey()
    .then((data: IHTTPClientResponse<RefreshApiKeyResponseDTO, any>) => {
      const prevState = stateMachine.state$.value;

      stateMachine.setState({
        profile: {
          ...prevState.profile,
          ApiKey: data.data.data[0].attributes.passKey,
        },
        failed: false,
        isLoaded: true,
        loading: false,
      });
    })
    .catch((e: unknown) => {
      const messageBus = container.getDependency(
        "errorMessageBus"
      ) as ErrorMessageBus;
      const errorMessage = createErrorMessage({
        code: ERROR_CODE_REFRESH_API_KEY,
        source: "profileService",
        message: "Failed to refresh Api Key",
        error: e as Error,
      });
      messageBus.send(errorMessage);

      stateMachine.setState({
        failed: true,
        isLoaded: false,
        loading: false,
      });
    });
};

const getSubscriptionPlans = () => {
  const container = getContainer();
  const client = container.getDependency(
    "privatePassServerClients"
  ) as HTTPClient;
  return client.get("subscriptionPlan/passtrek");
};

const subscribe = ({ token, planId, userId }: ProfileSubscribeDTO) => {
  const container = getContainer();
  const client = container.getDependency(
    "privatePassServerClients"
  ) as HTTPClient;
  return client.post("subscribe/", {
    userId: userId,
    stripeToken: token,
    subscriptionPlanId: planId,
  });
};

const getDefaultTemplateID = () => {
  const container = getContainer();
  const storage = container.getDependency(
    "persistentStorage"
  ) as PersistentStorage;
  return storage.getItem<string>(ISSUER_TEMPLATE_KEY);
};

const cleanUpState = () => {
  const container = getContainer();
  const storage = container.getDependency(
    "persistentStorage"
  ) as PersistentStorage;
  return storage.removeItem(ISSUER_TEMPLATE_KEY);
};

const setDefaultTemplateIdToStorage = (templateId: string) => {
  const container = getContainer();
  const storage = container.getDependency(
    "persistentStorage"
  ) as PersistentStorage;
  return storage.setItem(ISSUER_TEMPLATE_KEY, templateId);
};

const fetchIssuer = () => {
  const container = getContainer();

  const messageBus = container.getDependency(
    "errorMessageBus"
  ) as ErrorMessageBus;

  stateMachine.setState({
    loading: true,
  });

  return sdk.issuer
    .getIssuer()
    .then((data: GetIssuerResponseDTO) => {
      const prevState = stateMachine.state$.value;

      try {
        stateMachine.setState({
          ...prevState,
          Issuer: data.data[0].attributes.systemName,
          failed: false,
          isLoaded: true,
          loading: false,
        });
      } catch (e: unknown) {
        const errorMessage = createErrorMessage({
          code: ERROR_CODE_GET_ISSUER,
          source: "profileService",
          message: "get issuer wrong response",
          error: e as Error,
        });
        messageBus.send(errorMessage);
      }

      try {
        const templateId =
          data.data[0].attributes.settings.templateID.toString();
        setDefaultTemplateIdToStorage(templateId);
      } catch (e: unknown) {
        const errorMessage = createErrorMessage({
          code: ERROR_CODE_GET_ISSUER,
          source: "profileService",
          message: "get issuer wrong response",
          error: e as Error,
        });
        messageBus.send(errorMessage);
      }
    })
    .catch((e: unknown) => {
      const errorMessage = createErrorMessage({
        code: ERROR_CODE_GET_ISSUER,
        source: "profileService",
        message: "Failed to get Issuer",
        error: e as Error,
      });
      messageBus.send(errorMessage);

      stateMachine.setState({
        failed: true,
        isLoaded: false,
        loading: false,
      });
    });
};

const setIssuer = ({ issuerName }: { issuerName: string }) => {
  const container = getContainer();

  stateMachine.setState({
    loading: true,
  });

  const username = "dumb"; //@todo

  sdk.issuer
    .createIssuer({ name: username, systemName: issuerName })
    .then(() => {
      const prevState = stateMachine.state$.value;
      stateMachine.setState({
        ...prevState,
        Issuer: issuerName,
        failed: false,
        isLoaded: true,
        loading: false,
      });
    })
    .catch((e: unknown) => {
      const messageBus = container.getDependency(
        "errorMessageBus"
      ) as ErrorMessageBus;
      const errorMessage = createErrorMessage({
        code: ERROR_CODE_SET_ISSUER,
        source: "profileService",
        message: "Failed to set Issuer",
        error: e as Error,
      });
      messageBus.send(errorMessage);

      stateMachine.setState({
        failed: true,
        isLoaded: false,
        loading: false,
      });
    });
};

export const profileService: IProfileService = {
  getDefaultTemplateID,
  fetchProfile,
  saveProfile,
  refreshApiKey,
  getSubscriptionPlans,
  setDefaultTemplateIdToStorage,
  subscribe,
  fetchIssuer: fetchIssuer,
  setIssuer,
  state$: stateMachine.state$,
  cleanUpState,
};
