import { Features } from "./features";

export const defaultBackgroundColor = "#ffffff";
export const defaultForegroundColor = "#000000";
export const defaultLabelColor = "#000000";

export const defaultRoute = "/dashboard";
export const DEFAULT_FEATURES_STATE: Record<Features, boolean> = {
  passes: true,
  templates: true,
  analytics: true,
  profile: true,
  "smart-links": true,
  campaigns: true,
  pos: true,
  apiKey: false,
  pwaTemplateID: false,
};
export const DEFAULT_FEATURES_VERSIONS: Partial<Record<Features, string>> = {
  pos: "v1",
};
