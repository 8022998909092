import { AddStampCardBonusDTO } from "../../../boundary/PassRepository/PassRepositoryDTO";
import { getEnvStripUrlId } from "../../environment";

export const convertToAddStampCardBonusDTO = (
  passId: string,
): AddStampCardBonusDTO => {

  return {
		PassHeader: [
			{
				PassInfoId: parseInt(passId, 10),
				TypeId: 1,
				Key: "cumulative_bonus",
				Value: "5/5",
				Label: "Cups",
				AlignmentId: 4,
				ChangeMessage: "%@",
			},
		],
		PassInfo: {
			PassInfoId: parseInt(passId, 10),
		},
		PassStyle: {
			StripUrl: getEnvStripUrlId(),
		},
		PassBack: [
			{
				Value: "Get your next coffee for free!",
				Key: "notification",
				Label: "notification",
				ChangeMessage: "%@",
			},
		],
  };
};
