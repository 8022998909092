import { FC, useEffect, useRef } from "react";
import { getContainer } from "../diContainer/container";
import { useQuery } from "../adapters/hooks/useQuery";
import { GA_USER_ID } from "../domain/specs/urlParams";
import { isEmpty } from "lodash";
import { IUserAnalyticsService } from "../boundary/IUserAnalyticsService";

export const AutoUserIDSetter: FC = ({ children }) => {
  let query = useQuery();
  const userId = query.get(GA_USER_ID) || "";
  const _userId = useRef("");
  const userAnalyticsService = getContainer().getDependency(
    "userAnalyticsService"
  ) as IUserAnalyticsService;

  useEffect(() => {
    if (_userId.current !== userId && !isEmpty(userId)) {
      userAnalyticsService.setUserID(userId);
      _userId.current = userId;
    }
  }, [userId, userAnalyticsService]);

  return <>{children}</>;
};
