import {
  ISignUpService,
  SignUpFormattedErrors,
} from "../../../boundary/SignUpService/SignUpService";
import { SignUpServiceRequestDTO } from "../../../boundary/SignUpService/SignUpServiceDTO";
import {
  createErrorMessage,
  ErrorMessageBus,
  LoadableData,
  MessageBus,
  StateSubject,
} from "@roketus/web-toolkit";
import { ERROR_CODE_SIGNUP_FAILED } from "../../../domain/specs/errorCodes";
import { getContainer } from "../../../diContainer/container";
import { isEmpty } from "lodash";
import { PositiveMessageEntity } from "../../../domain/entities/messages/positiveEntity";
import { POSITIVE_SIGNUP } from "../../../domain/specs/positiveCodes";
import { HTTPClient } from "../../../boundary/HTTPClient";

const stateMachine = new StateSubject<LoadableData>({
  loading: false,
  isLoaded: false,
  failed: false,
});

const buildSignUpPayload = (signUpData: FormData): SignUpServiceRequestDTO => {
  const payload = {
    email: signUpData.get("email") as string,
    password: signUpData.get("password") as string,
    confirmPassword: signUpData.get("confirmPassword") as string,
    plan: 1,
    userName: signUpData.get("username") as string,
  };

  return payload;
};

// const isContainOnlyLatinChars = (str: string): boolean => {
//   return /^[a-zA-Z]+$/.test(str);
// };

const isEmailValid = (email: string): boolean => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};

const formatErrors = (
  errors: {
    field: keyof SignUpFormattedErrors;
    helperText: string;
    error: boolean;
  }[]
): SignUpFormattedErrors => {
  const formattedErrors: SignUpFormattedErrors = {
    username: {},
    issuer: {},
    email: {},
    password: {},
    confirmPassword: {},
  };

  errors.forEach(({ field, helperText, error }) => {
    formattedErrors[field] = {
      helperText,
      error,
    };
  });

  return formattedErrors;
};

const validate = (
  signUpData: FormData
): { formattedErrors: SignUpFormattedErrors; isValid: boolean } => {
  const errors = [];

  const username = signUpData.get("username") as string;
  const email = signUpData.get("email") as string;
  // const issuer = signUpData.get("issuer") as string;
  const password = signUpData.get("password") as string;
  const confirmPassword = signUpData.get("confirmPassword") as string;

  if (isEmpty(username)) {
    errors.push({
      field: "username" as const,
      error: true,
      helperText: "Username is required",
    });
  }

  // if (isEmpty(issuer)) {
  //   errors.push({
  //     field: "issuer" as const,
  //     error: true,
  //     helperText: "Issuer System Name is required",
  //   });
  // }

  // if (!isContainOnlyLatinChars(issuer)) {
  //   errors.push({
  //     field: "issuer" as const,
  //     error: true,
  //     helperText: "Issuer System Name should only contain latin characters",
  //   });
  // }

  if (isEmpty(email)) {
    errors.push({
      field: "email" as const,
      error: true,
      helperText: "Email is required",
    });
  }

  if (!isEmailValid(email)) {
    errors.push({
      field: "email" as const,
      error: true,
      helperText: "This is not look like Email",
    });
  }

  if (isEmpty(password)) {
    errors.push({
      field: "password" as const,
      error: true,
      helperText: "Password is required",
    });
  }

  if (password.length < 6) {
    errors.push({
      field: "password" as const,
      error: true,
      helperText: "Password should contain at least 6 symbols",
    });
  }

  if (isEmpty(confirmPassword)) {
    errors.push({
      field: "confirmPassword" as const,
      error: true,
      helperText: "Confirm Password is required",
    });
  }

  if (!isEmpty(confirmPassword) && password !== confirmPassword) {
    errors.push({
      field: "confirmPassword" as const,
      error: true,
      helperText: "Passwords should equal",
    });
  }

  const isValid = !errors.length;
  const formattedErrors = formatErrors(errors);

  return { formattedErrors, isValid };
};

const signUp = (signUpData: FormData): Promise<void> => {
  const container = getContainer();
  const messageBus = container.getDependency("messageBus") as MessageBus;
  const client = container.getDependency(
    "privatePassServerClients"
  ) as HTTPClient;

  stateMachine.setState({ loading: true });

  const payload = buildSignUpPayload(signUpData);

  return client
    .post("/api/account/register", payload)
    .then(() => {
      stateMachine.setState({ loading: false, isLoaded: true, failed: false });
      const msg: PositiveMessageEntity = {
        data: POSITIVE_SIGNUP,
        message: "",
        source: "signUpService",
        type: "positiveEvent",
      };
      messageBus.send(msg);
    })
    .catch((e) => {
      stateMachine.setState({ loading: false, isLoaded: false, failed: true });

      const messageBus = container.getDependency(
        "errorMessageBus"
      ) as ErrorMessageBus;
      const errorMessage = createErrorMessage({
        code: ERROR_CODE_SIGNUP_FAILED,
        source: "signUpService",
        message: "Sign Up Failed",
        error: e as Error,
      });
      messageBus.send(errorMessage);
    });
};

export const signUpService: ISignUpService = {
  signUp,
  validate,
  state$: stateMachine.state$,
};
