import { ErrorMessageBus } from "@roketus/web-toolkit";
import { sdk } from "@roketus/loyalty-js-sdk";
import { getContainer } from "../../diContainer/container";

export const initRemoteLogger = () => {
  const container = getContainer();

  const errorMessageBus = container.getDependency(
    "errorMessageBus"
  ) as ErrorMessageBus;

  errorMessageBus.stream$.subscribe((message) => {
    const payload = {
      message: message.message,
      component: message.source,
      error: message.data.error.toString(),
    };

    sdk.logger.logError(payload);
  });
};
