import {
  createErrorMessage,
  IMessageBus,
  isErrorMessage,
  LoadableData,
  StateSubject,
} from "@roketus/web-toolkit";
import { IListPassService } from "../../boundary/IListPassService";
import { IPassRepository } from "../../boundary/PassRepository/IPassRepository";
import { getContainer } from "../../diContainer/container";
import { ExistingPassEntity } from "../../domain/entities/GenericPassEntity/GenericPassEntity";
import { PagingData } from "../../boundary/PagingData";
import { take } from "rxjs";

export const init = (repository: IPassRepository): IListPassService => {
  const stateMachine = new StateSubject<
    LoadableData & {
      passes: ExistingPassEntity[];
      pagingData: PagingData;
    }
  >({
    loading: false,
    passes: [],
    pagingData: {
      page: 1,
      pageSize: 10,
      totalData: 0,
      totalPages: 0,
    },
    isLoaded: false,
    failed: false,
  });

  const load: IListPassService["load"] = async ({ page }) => {
    const messageBus = getContainer().getDependency(
      "messageBus"
    ) as IMessageBus;

    stateMachine.setState({ loading: true });
    try {
      const result = await repository.list({ page });

      if (isErrorMessage(result)) {
        messageBus.send(result);
        stateMachine.setState({ failed: true, isLoaded: true, loading: false });
      } else {
        const { passes, pagingData } = result;
        stateMachine.setState({
          loading: false,
          isLoaded: true,
          passes,
          pagingData,
        });
      }
    } catch (e) {
      messageBus.send(
        createErrorMessage({
          message: "Error loading passes",
          error: e as Error,
          source: "IListPassService",
        })
      );

      stateMachine.setState({
        loading: false,
        failed: true,
      });
    }
  };

  const deletePass = async (passId: string) => {
    const messageBus = getContainer().getDependency(
      "messageBus"
    ) as IMessageBus;

    try {
      stateMachine.setState({ loading: true });
      await repository.delete(passId);
      stateMachine.state$
        .pipe(take(1))
        .subscribe((state) => {
          console.log("curr state", state);
          const passesWithoutDeleted = state.passes.filter((pass) => {
            console.log(pass.id, passId);
            return pass.id !== passId;
          });
          console.log("passesWithoutDeleted", passesWithoutDeleted);

          stateMachine.setState({ passes: passesWithoutDeleted });
        })
        .unsubscribe();
    } catch (e) {
      messageBus.send(
        createErrorMessage({
          message: "Error delete pass",
          error: e as Error,
          source: "ListPassService",
        })
      );
    } finally {
      stateMachine.setState({ loading: false });
    }
  };

  return { state$: stateMachine.state$, load, delete: deletePass };
};
