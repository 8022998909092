import { IMessage } from "../../../../boundary/IMessage";
import {
  createErrorMessage,
  ErrorMessage,
  isErrorMessage,
} from "@roketus/web-toolkit";
import { get, isBoolean, isObject, isString, isUndefined } from "lodash";
import { AuthDataEntity } from "./authEntity";

export interface AuthMessageEntity extends IMessage {
  type: "authEntityChange";
  message: "";
  source: string;
  data: AuthDataEntity;
}

export const createAuthDataEntity = (
  data: unknown
): AuthDataEntity | ErrorMessage => {
  if (!isObject(data)) {
    return createErrorMessage({
      message: "data is not object",
      source: "createAuthDataEntity",
    });
  }

  const isAuth = get(data, "isAuth");
  const userName = get(data, "userName");
  const useRefreshTokens = get(data, "useRefreshTokens");
  const token = get(data, "token");
  const refreshToken = get(data, "refreshToken");
  const apiKey = get(data, "apiKey");

  if (!isBoolean(isAuth)) {
    return createErrorMessage({
      message: "data.isAuth is not boolean",
      source: "createAuthDataEntity",
    });
  }

  if (!isBoolean(useRefreshTokens)) {
    return createErrorMessage({
      message: "data.useRefreshTokens is not boolean",
      source: "createAuthDataEntity",
    });
  }

  if (!isString(userName)) {
    return createErrorMessage({
      message: "data.userName is not string",
      source: "createAuthDataEntity",
    });
  }

  if (!isUndefined(token) && !isString(token)) {
    return createErrorMessage({
      message: "data.token is not string or undefined",
      source: "createAuthDataEntity",
    });
  }

  if (!isUndefined(apiKey) && !isString(apiKey)) {
    return createErrorMessage({
      message: "data.apiKey is not string or undefined",
      source: "createAuthDataEntity",
    });
  }

  if (!isUndefined(refreshToken) && !isString(refreshToken)) {
    return createErrorMessage({
      message: "data.refreshToken is not string or undefined",
      source: "createAuthDataEntity",
    });
  }

  return {
    isAuth,
    userName,
    useRefreshTokens,
    token,
    refreshToken,
    apiKey,
  };
};

export const createAuthMessageEntity = (
  source: string,
  data: unknown
): AuthMessageEntity | ErrorMessage => {
  const authDataEntity = createAuthDataEntity(data);
  if (isErrorMessage(authDataEntity)) {
    return authDataEntity;
  }

  return {
    type: "authEntityChange",
    message: "",
    source,
    data: authDataEntity,
  };
};
