import loyoLogoImg from "./loyo-logo.png";
import { stripBase64Coupon } from "./stripBase64";

export const couponCardData = {
  description: "description",
  header: {
    headerFields: [],
  },
  body: {
    auxiliaryFields: [
      {
        label: "Offer",
        value: "50% OFF",
        key: "offer",
        alignment: "4",
      },
    ],
    primaryFields: [
      {
        label: "",
        value: "",
      },
    ],
    secondaryFields: [],
  },
  barcode: {
    BarcodeFormatId: "2",
    BarcodeMessage: "lorem ipsum",
    MessageEncodingId: "2",
    UseBarcode: true,
  },
  design: {
    backgroundColor: "#cfcfcf",
    foregroundColor: "#000000",
    labelColor: "#000000",
    backgroundImage: "none",
    styleId: "1",
  },
  designFiles: {
    icon: {},
    logo: {
      url: loyoLogoImg,
    },
    strip: {
      url: stripBase64Coupon,
    },
  },
};
