import { isEmpty } from "lodash";
import styled from "@emotion/styled";
import { PassField } from "../CreatePassEntity";

interface Props {
  secondary: PassField[];
  auxiliary: PassField[];
  labelColor?: string;
  valueColor?: string;
}

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: stretch;
  margin: 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const GridRow = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 38px;
`;

const alingmentMap = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
  inherit: "flex-start",
};
const FieldContainer = styled.div<{ align?: PassField["align"] }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => alingmentMap[props.align || "inherit"]};
`;

const Text = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: inherit;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.1;
`;

const Label = styled(Text)<{ color?: string }>`
  color: ${(props) => props.color};
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 500;
  line-height: 1;
`;

export const CouponTextBlock: React.FC<Props> = ({
  secondary,
  auxiliary,
  labelColor,
  valueColor,
}) => {
  if (isEmpty(secondary) && isEmpty(auxiliary)) {
    return null;
  }

  return (
    <Grid>
      <GridRow>
        {secondary.map((field, index) => (
          <FieldContainer key={index} align={field.align}>
            <Label color={labelColor}>{field.label}</Label>
            <Text color={valueColor}>{field.value}</Text>
          </FieldContainer>
        ))}
        {auxiliary.map((field, index) => (
          <FieldContainer key={index} align={field.align}>
            <Label color={labelColor}>{field.label}</Label>
            <Text color={valueColor}>{field.value}</Text>
          </FieldContainer>
        ))}
      </GridRow>
    </Grid>
  );
};
