import { IMessage } from "@roketus/web-toolkit";
import { get, isString } from "lodash";

export interface InputElementMessage extends IMessage {
  type: "inputElementChanged";
  message: string; // aka input key
  source: string;
  data: string;
}

export const isInputElementMessage = (
  value: unknown
): value is InputElementMessage => {
  const type = get(value, "type");
  const message = get(value, "message");
  const source = get(value, "source");
  const data = get(value, "data");

  return (
    type === "inputElementChanged" &&
    isString(message) &&
    isString(source) &&
    isString(data)
  );
};
