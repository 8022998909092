import { StepNavigation } from "../StepNavigation";
import { StepDetails } from "../StepDetails";
import { AddStampCardBonus } from "./AddStampCardBonus";
import { Typography } from "@mui/material";
import { WizardHelp } from "../WizardHelp";

export const MakePurchaseStep: React.FC = () => {
  return (
    <>
      <Typography gutterBottom>
				Simulate a purchase and get one step closer to a free product
      </Typography>
      <AddStampCardBonus />
      <StepDetails>
        <WizardHelp />
      </StepDetails>
      <StepNavigation />
    </>
  );
};
