import { Line } from "react-chartjs-2";
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { LineChartData } from "../boundary/AnalyticsService/AnalyticsService";

Chart.register(
  BarController,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  BarElement,
  CategoryScale,
  Legend,
  Tooltip,
  Filler
);

const passChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
    },
  },
};

interface LineChartProps {
  data: LineChartData;
  dateRange: number;
  options?: {};
}

export const LineChart = ({
  data,
  dateRange,
  options = {},
}: LineChartProps) => {
  const rangedData = {
    labels: data.labels.slice(dateRange * -1),
    datasets: data.datasets.map((chart) => ({
      ...chart,
      data: chart.data.slice(dateRange * -1),
    })),
  };

  return (
    <Line options={{ ...passChartOptions, ...options }} data={rangedData} />
  );
};
