import { MessageBus } from "@roketus/web-toolkit";
import { sdk } from "@roketus/loyalty-js-sdk";
import { getContainer } from "../../../diContainer/container";
import { isAnalyticEvent } from "../../../domain/entities/messages/analyticEntity";
import { filter } from "rxjs";
import UAParser from "ua-parser-js";

export const initAnalyticsService = () => {
  const messageBus = getContainer().getDependency("messageBus") as MessageBus;

  messageBus.stream$.pipe(filter(isAnalyticEvent)).subscribe((message) => {
    const ua = UAParser();

    // @TODO implement after auth
    const userId = "userId";

    sdk.analytics.createEvent(message.data.action, message.data.section || '', userId, ua.os.name, ua.os.version);
  });
};
