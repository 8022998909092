import { Box, Link, Typography } from "@mui/material";
import { WizardHelp } from "../WizardHelp";
import { StepDetails } from "../StepDetails";

export const FeedbackStep = () => {
  return (
    <Box sx={{ mb: 2 }}>
      <Typography gutterBottom>
        Sign up for your Atomic Loyalty account{" "}
        <Link
          href={process.env.REACT_APP_LOYALTY_DASHBOARD_GUI_URL}
          target="_blank"
        >
          here
        </Link>
      </Typography>
      <Typography gutterBottom>
        Or fill the form to get contacted by our team for a custom demo and to
        learn more about working with Atomic Loyalty
      </Typography>
      <StepDetails>
        <WizardHelp buttonLabel="Book a Demo" buttonType="contained" />
      </StepDetails>
    </Box>
  );
};
