import React from "react";
// @ts-ignore
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { init as initDi } from "./diContainer/initializeDependencies";
import { initConsoleLogger } from "./adapters/services/ConsoleLoggerService";
import { initRemoteLogger } from "./adapters/services/RemoteLoggerService";
import { init as initImageSaverService } from "./adapters/services/imageSaverService";
import { initAnalyticsService } from "./adapters/services/analyticsService/analyticsService";
import { init as initMessageBusLogger } from "./adapters/services/messageBusLogger";
import { sdk } from "@roketus/loyalty-js-sdk";

initDi();
initConsoleLogger();
initRemoteLogger();
initImageSaverService();
initAnalyticsService();
initMessageBusLogger();
sdk.telemetry
  .send(
    process.env.REACT_APP_NAME ?? "unknown",
    process.env.REACT_APP_VERSION ?? "unknown"
  )
  .then(console.log);

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
