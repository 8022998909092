import { filter } from "rxjs";
import { IMessage, IMessageBus } from "@roketus/web-toolkit";
import {
  InputElementMessage,
  isInputElementMessage,
} from "../../../../domain/entities/messages/formElement/InputElement/InputElementMessage";
import {
  FileElementMessage,
  isFileElementMessage,
} from "../../../../domain/entities/messages/formElement/FileElement/FileElementMessage";
import { IFormData } from "../../../../boundary/IFormData";
import {
  ArrayInputElementMessage,
  isMessageIsArrayInputElementMessage,
} from "../../../../domain/entities/messages/formElement/ArrayInputElement/ArrayInputElementMessage";
import {
  InputUpdateMessage,
  isUpdateMessage,
} from "../../../../domain/entities/messages/formElement/InputElement/InputUpdateMessage";
import {
  CheckboxMessage,
  isCheckboxMessage,
} from "../../../../domain/entities/messages/formElement/InputElement/checkboxMessage";

const isFormDataMessage = (
  value: IMessage
): value is
  | InputElementMessage
  | CheckboxMessage
  | FileElementMessage
  | ArrayInputElementMessage
  | InputUpdateMessage =>
  isInputElementMessage(value) ||
  isCheckboxMessage(value) ||
  isFileElementMessage(value) ||
  isMessageIsArrayInputElementMessage(value) ||
  isUpdateMessage(value);

export const init = (messageBus: IMessageBus): IFormData => {
  return messageBus.stream$.pipe(filter(isFormDataMessage));
};
