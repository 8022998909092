import { Alert, Snackbar as MUISnackbar } from "@mui/material";
import { SyntheticEvent, useState } from "react";
import { getContainer } from "../diContainer/container";
import {
  ERROR_CODE_LOGIN_FAILED,
  ERROR_CODE_PASS_REPOSITORY_NETWORK,
  ERROR_CODE_SIGNUP_FAILED,
  ERROR_CODE_UNKNOWN_ERROR,
} from "../domain/specs/errorCodes";
import { useObservable } from "@roketus/web-toolkit";
import {
  INotifyUserService,
  NotificationType,
} from "../boundary/INotifyUserService";
import {
  BONUS_SUBMITTED,
  POSITIVE_CODE_UNKNOWN,
  POSITIVE_TEMPLATE_CREATED,
  PROMO_CODE_RECEIVED,
  PROMO_CODE_USED,
  SUCCESS_SUBMIT_FEEDBACK_FORM,
} from "../domain/specs/positiveCodes";

const AUTO_HIDE_DURATION = 6000;

interface INotification {
  [key: string]: string;
}

const errors: INotification = {
  [ERROR_CODE_UNKNOWN_ERROR]: "Oops something went wrong!",
  [ERROR_CODE_LOGIN_FAILED]: "Login failed!",
  [ERROR_CODE_SIGNUP_FAILED]: "Sign Up failed!",
  [ERROR_CODE_PASS_REPOSITORY_NETWORK]: "Network Error!",
};
const success: INotification = {
  [POSITIVE_CODE_UNKNOWN]: "Success!",
  [POSITIVE_TEMPLATE_CREATED]: "Template created!",
  [BONUS_SUBMITTED]: "Bonuses added to the card!",
  [PROMO_CODE_RECEIVED]: "Promo code added to your card!",
  [PROMO_CODE_USED]: "Promo code was used",
  [SUCCESS_SUBMIT_FEEDBACK_FORM]: "Thank You! We will contact you shortly",
};

export const Snackbar = () => {
  const [open, setOpen] = useState(false);
  const [messageCode, setMessageCode] = useState(ERROR_CODE_UNKNOWN_ERROR);
  const [messageType, setMessageType] = useState<NotificationType>("error");

  const handleClose = (_event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const notifyUserService = getContainer().getDependency(
    "notifyUserService"
  ) as INotifyUserService;

  useObservable(notifyUserService.stream$, ({ message, type }) => {
    setMessageCode(message);
    setMessageType(type);
    setOpen(true);
  });

  const notificationType = messageType === "error" ? errors : success;
  const fallbackMessage =
    messageType === "error" ? `Oops something went wrong!` : `Success!`;

  return (
    <MUISnackbar
      open={open}
      autoHideDuration={AUTO_HIDE_DURATION}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={messageType}
        sx={{ width: "100%" }}
      >
        {notificationType[messageCode] || fallbackMessage}
      </Alert>
    </MUISnackbar>
  );
};
