import { FC } from "react";
import { getContainer } from "../diContainer/container";
import { AuthService } from "../boundary/AuthService/AuthService";
import { useObservable } from "@roketus/web-toolkit";

export const AutoAuthServiceInitializer: FC = ({ children }) => {
  const authService = getContainer().getDependency(
    "authService"
  ) as AuthService;
  const data = useObservable(authService.state$);

  return <>{data?.isAuth ? children : null}</>;
};
