import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Navigation } from "./Navigation";
import { Snackbar } from "./Snackbar";
import CssBaseline from "@mui/material/CssBaseline";
import { RedirectServiceInitializer } from "./RedirectServiceInitializer";
import { AutoAuthServiceInitializer } from "./AutoAuthServiceInitializer";

export default function App() {
  return (
    <AutoAuthServiceInitializer>
      <Router>
        <RedirectServiceInitializer>
          <CssBaseline />
          <Navigation />
          <Snackbar />
        </RedirectServiceInitializer>
      </Router>
    </AutoAuthServiceInitializer>
  );
}
