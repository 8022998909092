import { getContainer } from "../../diContainer/container";
import { IMessage, IMessageBus } from "@roketus/web-toolkit";

const formatMessage = (message: IMessage) => {
  return `${new Date().toISOString()}::${message.source}: ${message.message}`;
};

export const initConsoleLogger = () => {
  const messageBus = getContainer().getDependency("messageBus") as IMessageBus;

  messageBus.stream$.subscribe((message) => {
    if (message.type === "info") {
      console.log(formatMessage(message), message.data);
    }

    if (message.type === "error") {
      console.error(formatMessage(message), message.data);
    }
  });
};
