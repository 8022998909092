import { IMessage } from "@roketus/web-toolkit";
import { isString } from "lodash";
import { BackPassFieldEntity, TextFieldEntity } from "../TextFieldEntity";
import {
  CreatePassEntity,
  PassTypeEntity,
} from "../CreatePassEntity/CreatePassEntity";
import { LocationEntity } from "../LocationEntity";
import {
  CREATE_PASS_FORM_TYPE_BOARDING_PASS,
  CREATE_PASS_FORM_TYPE_COUPON,
  CREATE_PASS_FORM_TYPE_EVENT_TICKET,
  CREATE_PASS_FORM_TYPE_GENERIC,
  CREATE_PASS_FORM_TYPE_STORE_CARD,
} from "../../specs/createPassFormValues";
import { DOWNLOAD_PASS_EVENT } from "../../../domain/specs/passCodes";

interface BasePassEntity {
  orgName: string;
  description: string;
  expirationDate: string | null;
  relevantDate: string | null;
  iconId: string;
  header: {
    logoId?: string;
    logoText?: string;
    headerFields: TextFieldEntity[];
  };
  design: {
    backgroundColor: string;
    foregroundColor: string;
    labelColor: string;
    stripId: string | null;
    thumbnailId: string | null;
    backgroundId: string | null;
  };
}

export interface GenericPassEntity extends BasePassEntity {
  body: {
    primaryFields?: {
      label: string;
      key: string;
      value: string;
    };
    secondaryFields: TextFieldEntity[];
    auxiliaryFields: TextFieldEntity[];
    passBack: BackPassFieldEntity[];
    // thumbnail: string;
  };
  barcode?: {
    BarcodeAltText?: string;
    BarcodeFormatId?: string;
    BarcodeMessage?: string;
    MessageEncodingId?: string;
    UseBarcode?: boolean;
  };
  location?: LocationEntity[];
  type: PassTypeEntity;
}

export interface ExistingPassEntity extends GenericPassEntity {
  id: string;
}

const createPassTypeToPassEntity: Record<string, PassTypeEntity> = {
  [CREATE_PASS_FORM_TYPE_BOARDING_PASS]: PassTypeEntity.BoardingPass,
  [CREATE_PASS_FORM_TYPE_COUPON]: PassTypeEntity.Coupon,
  [CREATE_PASS_FORM_TYPE_EVENT_TICKET]: PassTypeEntity.EventTicket,
  [CREATE_PASS_FORM_TYPE_GENERIC]: PassTypeEntity.Generic,
  [CREATE_PASS_FORM_TYPE_STORE_CARD]: PassTypeEntity.StoreCard,
};

const convertCreatePassTypeToPassTypeEntity = (
  value: string
): PassTypeEntity => {
  const passType = createPassTypeToPassEntity[value];

  if (!passType) {
    throw Error(
      `convertCreatePassTypeToPassTypeEntity:: Invalid pass type: ${value}`
    );
  }

  return passType;
};

export const createCreatePassEntityFromPartial = (
  data: Partial<CreatePassEntity>
): CreatePassEntity => {
  const {
    headerFields = [],
    type = CREATE_PASS_FORM_TYPE_GENERIC,

    orgName = "Default Org Name",
    description = "Default Description",
    expirationDate = null,
    iconId = "",
    relevantDate = null,
  } = data;

  return {
    ...data,
    headerFields,
    type,
    orgName,
    description,
    expirationDate,
    iconId,
    relevantDate,
  };
};

export const createGenericPassEntityFromCreatePassEntity = (
  data: CreatePassEntity
): GenericPassEntity => {
  const headerFields = [];
  const secondaryFields = [];
  const auxiliaryFields = [];
  const passBack = [];
  const location = [];

  if (
    data.header_field_1_key &&
    data.header_field_1_value &&
    data.header_field_1_label &&
    data.header_field_1_align
  ) {
    headerFields.push({
      key: data.header_field_1_key,
      value: data.header_field_1_value,
      label: data.header_field_1_label,
      alignment: data.header_field_1_align,
    });
  }

  if (
    data.header_field_2_key &&
    data.header_field_2_value &&
    data.header_field_2_label &&
    data.header_field_2_align
  ) {
    headerFields.push({
      key: data.header_field_2_key,
      value: data.header_field_2_value,
      label: data.header_field_2_label,
      alignment: data.header_field_2_align,
    });
  }

  if (
    data.header_field_3_key &&
    data.header_field_3_value &&
    data.header_field_3_label &&
    data.header_field_3_align
  ) {
    headerFields.push({
      key: data.header_field_3_key,
      value: data.header_field_3_value,
      label: data.header_field_3_label,
      alignment: data.header_field_3_align,
    });
  }

  if (
    data.body_secondary_1_label &&
    data.body_secondary_1_value &&
    data.body_secondary_1_key &&
    data.body_secondary_1_align
  ) {
    secondaryFields.push({
      label: data.body_secondary_1_label,
      value: data.body_secondary_1_value,
      key: data.body_secondary_1_key,
      alignment: data.body_secondary_1_align,
    });
  }

  if (
    data.body_secondary_2_label &&
    data.body_secondary_2_value &&
    data.body_secondary_2_key &&
    data.body_secondary_2_align
  ) {
    secondaryFields.push({
      label: data.body_secondary_2_label,
      value: data.body_secondary_2_value,
      key: data.body_secondary_2_key,
      alignment: data.body_secondary_2_align,
    });
  }

  if (
    data.body_secondary_3_label &&
    data.body_secondary_3_value &&
    data.body_secondary_3_key &&
    data.body_secondary_3_align
  ) {
    secondaryFields.push({
      label: data.body_secondary_3_label,
      value: data.body_secondary_3_value,
      key: data.body_secondary_3_key,
      alignment: data.body_secondary_3_align,
    });
  }

  if (
    data.body_secondary_4_label &&
    data.body_secondary_4_value &&
    data.body_secondary_4_key &&
    data.body_secondary_4_align
  ) {
    secondaryFields.push({
      label: data.body_secondary_4_label,
      value: data.body_secondary_4_value,
      key: data.body_secondary_4_key,
      alignment: data.body_secondary_4_align,
    });
  }

  if (data.location_1_latitude && data.location_1_longitude) {
    location.push({
      Latitude: data.location_1_latitude,
      Longitude: data.location_1_longitude,
      RelevantText: data.location_1_relevant_text || "",
      LocationName: data.location_1_location_name || "",
    });
  }

  if (data.location_2_latitude && data.location_2_longitude) {
    location.push({
      Latitude: data.location_2_latitude,
      Longitude: data.location_2_longitude,
      RelevantText: data.location_2_relevant_text || "",
      LocationName: data.location_2_location_name || "",
    });
  }

  if (data.location_3_latitude && data.location_3_longitude) {
    location.push({
      Latitude: data.location_3_latitude,
      Longitude: data.location_3_longitude,
      RelevantText: data.location_3_relevant_text || "",
      LocationName: data.location_3_location_name || "",
    });
  }

  if (data.location_4_latitude && data.location_4_longitude) {
    location.push({
      Latitude: data.location_4_latitude,
      Longitude: data.location_4_longitude,
      RelevantText: data.location_4_relevant_text || "",
      LocationName: data.location_4_location_name || "",
    });
  }

  if (data.location_5_latitude && data.location_5_longitude) {
    location.push({
      Latitude: data.location_5_latitude,
      Longitude: data.location_5_longitude,
      RelevantText: data.location_5_relevant_text || "",
      LocationName: data.location_5_location_name || "",
    });
  }

  if (data.location_6_latitude && data.location_6_longitude) {
    location.push({
      Latitude: data.location_6_latitude,
      Longitude: data.location_6_longitude,
      RelevantText: data.location_6_relevant_text || "",
      LocationName: data.location_6_location_name || "",
    });
  }

  if (data.location_7_latitude && data.location_7_longitude) {
    location.push({
      Latitude: data.location_7_latitude,
      Longitude: data.location_7_longitude,
      RelevantText: data.location_7_relevant_text || "",
      LocationName: data.location_7_location_name || "",
    });
  }

  if (data.location_8_latitude && data.location_8_longitude) {
    location.push({
      Latitude: data.location_8_latitude,
      Longitude: data.location_8_longitude,
      RelevantText: data.location_8_relevant_text || "",
      LocationName: data.location_8_location_name || "",
    });
  }

  if (data.location_9_latitude && data.location_9_longitude) {
    location.push({
      Latitude: data.location_9_latitude,
      Longitude: data.location_9_longitude,
      RelevantText: data.location_9_relevant_text || "",
      LocationName: data.location_9_location_name || "",
    });
  }

  if (data.location_10_latitude && data.location_10_longitude) {
    location.push({
      Latitude: data.location_10_latitude,
      Longitude: data.location_10_longitude,
      RelevantText: data.location_10_relevant_text || "",
      LocationName: data.location_10_location_name || "",
    });
  }

  if (
    data.body_auxiliary_1_label &&
    data.body_auxiliary_1_value &&
    data.body_auxiliary_1_key &&
    data.body_auxiliary_1_align
  ) {
    auxiliaryFields.push({
      label: data.body_auxiliary_1_label,
      value: data.body_auxiliary_1_value,
      key: data.body_auxiliary_1_key,
      alignment: data.body_auxiliary_1_align,
    });
  }

  if (
    data.body_auxiliary_2_label &&
    data.body_auxiliary_2_value &&
    data.body_auxiliary_2_key &&
    data.body_auxiliary_2_align
  ) {
    auxiliaryFields.push({
      label: data.body_auxiliary_2_label,
      value: data.body_auxiliary_2_value,
      key: data.body_auxiliary_2_key,
      alignment: data.body_auxiliary_2_align,
    });
  }

  if (
    data.body_auxiliary_3_label &&
    data.body_auxiliary_3_value &&
    data.body_auxiliary_3_key &&
    data.body_auxiliary_3_align
  ) {
    auxiliaryFields.push({
      label: data.body_auxiliary_3_label,
      value: data.body_auxiliary_3_value,
      key: data.body_auxiliary_3_key,
      alignment: data.body_auxiliary_3_align,
    });
  }

  if (
    data.body_auxiliary_4_label &&
    data.body_auxiliary_4_value &&
    data.body_auxiliary_4_key &&
    data.body_auxiliary_4_align
  ) {
    auxiliaryFields.push({
      label: data.body_auxiliary_4_label,
      value: data.body_auxiliary_4_value,
      key: data.body_auxiliary_4_key,
      alignment: data.body_auxiliary_4_align,
    });
  }

  if (
    data.body_auxiliary_5_label &&
    data.body_auxiliary_5_value &&
    data.body_auxiliary_5_key &&
    data.body_auxiliary_5_align
  ) {
    auxiliaryFields.push({
      label: data.body_auxiliary_5_label,
      value: data.body_auxiliary_5_value,
      key: data.body_auxiliary_5_key,
      alignment: data.body_auxiliary_5_align,
    });
  }

  if (
    data.body_back_1_label &&
    data.body_back_1_value &&
    data.body_back_1_key
  ) {
    passBack.push({
      label: data.body_back_1_label,
      value: data.body_back_1_value,
      key: data.body_back_1_key,
    });
  }

  if (
    data.body_back_2_label &&
    data.body_back_2_value &&
    data.body_back_2_key
  ) {
    passBack.push({
      label: data.body_back_2_label,
      value: data.body_back_2_value,
      key: data.body_back_2_key,
    });
  }

  if (
    data.body_back_3_label &&
    data.body_back_3_value &&
    data.body_back_3_key
  ) {
    passBack.push({
      label: data.body_back_3_label,
      value: data.body_back_3_value,
      key: data.body_back_3_key,
    });
  }

  if (
    data.body_back_4_label &&
    data.body_back_4_value &&
    data.body_back_4_key
  ) {
    passBack.push({
      label: data.body_back_4_label,
      value: data.body_back_4_value,
      key: data.body_back_4_key,
    });
  }

  if (
    data.body_back_5_label &&
    data.body_back_5_value &&
    data.body_back_5_key
  ) {
    passBack.push({
      label: data.body_back_5_label,
      value: data.body_back_5_value,
      key: data.body_back_5_key,
    });
  }

  let primaryFields = undefined;
  if (
    data.body_primary_key &&
    data.body_primary_value &&
    data.body_primary_label
  ) {
    primaryFields = {
      key: data.body_primary_key,
      value: data.body_primary_value,
      label: data.body_primary_label,
    };
  }

  return {
    orgName: data.orgName,
    description: data.description,
    expirationDate: data.expirationDate,
    relevantDate: data.relevantDate,
    iconId: data.iconId,
    type: convertCreatePassTypeToPassTypeEntity(data.type),
    header: {
      headerFields,
      logoText: data.logoText,
      logoId: data.logoId,
    },
    body: {
      primaryFields,
      secondaryFields,
      auxiliaryFields,
      passBack,
    },
    design: {
      backgroundColor: data.style_background_color || "#ffffff",
      foregroundColor: data.style_foreground_color || "#000000",
      labelColor: data.style_label_color || "#000000",
      stripId: data.stripId || null,
      backgroundId: data.backgroundId || null,
      thumbnailId: data.thumbnailId || null,
    },
    barcode: {
      BarcodeAltText: data.barcode_alt_text,
      BarcodeFormatId: data.barcode_format,
      BarcodeMessage: data.barcode_message,
      MessageEncodingId: data.barcode_encoding,
      UseBarcode: data.barcode_use,
    },
    location,
  };
};
export interface PassMessageEntity extends IMessage {
  type: "authEntityChange";
  message: string;
  source: string;
  data: string;
}

export const isPassRepository = (obj: any): obj is PassMessageEntity => {
  return (
    obj.type === DOWNLOAD_PASS_EVENT &&
    isString(obj.message) &&
    isString(obj.source) &&
    isString(obj.data)
  );
};
