export const ERROR_CODE_UNKNOWN_ERROR = "UNKNOWN_ERROR";
export const ERROR_CODE_LOGIN_FAILED = "LOGIN_FAILED";
export const ERROR_CODE_LOGIN_BY_APIKEY_FAILED = "LOGIN_BY_APIKEY_FAILED";
export const ERROR_CODE_SIGNUP_FAILED = "SIGNUP_FAILED";

export const ERROR_CODE_PASS_ENTITY_DESCRIPTION_REQUIRED =
  "ERROR_CODE_PASS_ENTITY_DESCRIPTION_REQUIRED";
export const ERROR_CODE_PASS_ENTITY_ORG_NAME_REQUIRED =
  "ERROR_CODE_PASS_ENTITY_ORG_NAME_REQUIRED";
export const ERROR_CODE_PASS_ENTITY_DESCRIPTION_TYPE =
  "ERROR_CODE_PASS_ENTITY_DESCRIPTION_TYPE";
export const ERROR_CODE_PASS_ENTITY_ICON_REQUIRED =
  "ERROR_CODE_PASS_ENTITY_ICON_REQUIRED";
export const ERROR_CODE_PASS_ENTITY_ICON_TYPE =
  "ERROR_CODE_PASS_ENTITY_ICON_TYPE";
export const ERROR_CODE_PASS_ENTITY_ICON_SIZE =
  "ERROR_CODE_PASS_ENTITY_ICON_SIZE";

export const ERROR_CODE_PASS_ENTITY_LOGO_TYPE =
  "ERROR_CODE_PASS_ENTITY_LOGO_TYPE";
export const ERROR_CODE_PASS_ENTITY_LOGO_SIZE =
  "ERROR_CODE_PASS_ENTITY_LOGO_SIZE";
export const ERROR_CODE_PASS_ENTITY_BARCODE_ENCODING_REQUIRED =
  "ERROR_CODE_PASS_ENTITY_BARCODE_ENCODING_REQUIRED";
export const ERROR_CODE_PASS_ENTITY_BARCODE_FORMAT_REQUIRED =
  "ERROR_CODE_PASS_ENTITY_BARCODE_FORMAT_REQUIRED";
export const ERROR_CODE_PASS_ENTITY_BARCODE_MESSAGE_REQUIRED =
  "ERROR_CODE_PASS_ENTITY_BARCODE_MESSAGE_REQUIRED";

export const ERROR_CODE_PASS_REPOSITORY_NETWORK =
  "ERROR_CODE_PASS_REPOSITORY_NETWORK";

export const ERROR_CODE_TEMPLATE_REPOSITORY_NETWORK =
  "ERROR_CODE_TEMPLATE_REPOSITORY_NETWORK";

export const ERROR_CODE_TEMPLATE_REPOSITORY_VALIDATION =
  "ERROR_CODE_TEMPLATE_REPOSITORY_VALIDATION";

export const ERROR_CODE_PASS_REPOSITORY_VALIDATION =
  "ERROR_CODE_PASS_REPOSITORY_VALIDATION";

export const ERROR_CODE_REFRESH_API_KEY = "ERROR_REFRESH_API_KEY";
export const ERROR_CODE_GET_PROFILE_FAILED = "ERROR_CODE_GET_PROFILE_FAILED";
export const ERROR_CODE_GET_ANALYTICS_FAILED =
  "ERROR_CODE_GET_ANALYTICS_FAILED";
export const ERROR_CODE_GET_ISSUER = "ERROR_GET_ISSUER";
export const ERROR_CODE_SET_ISSUER = "ERROR_CODE_SET_ISSUER";
export const ERROR_CODE_HTTP_401 = "ERROR_CODE_HTTP_401";

export const ERROR_CODE_FAILED_SEND_NOTIFICATION =
  "ERROR_CODE_FAILED_SEND_NOTIFICATION";

export const ERROR_CODE_TEMPLATE_FAILED_TO_UPDATE =
  "ERROR_CODE_TEMPLATE_FAILED_TO_UPDATE";
export const ERROR_CODE_TEMPLATE_FAILED_TO_CREATE =
  "ERROR_CODE_TEMPLATE_FAILED_TO_CREATE";

export const ERROR_CODE_PASS_FAILED_TO_CREATE =
  "ERROR_CODE_PASS_FAILED_TO_CREATE";
export const ERROR_CODE_PASS_FAILED_TO_UPDATE =
  "ERROR_CODE_PASS_FAILED_TO_UPDATE";
export const ERROR_CODE_FAILED_TO_ADD_BONUSES =
  "ERROR_CODE_FAILED_TO_ADD_BONUSES";
export const ERROR_CODE_FAILED_TO_SIMULATE_PURCHASE =
  "ERROR_CODE_FAILED_TO_SIMULATE_PURCHASE";
export const ERROR_CODE_FAILED_TO_GET_PROMO_CODE =
  "ERROR_CODE_FAILED_TO_GET_PROMO_CODE";
export const ERROR_CODE_FAILED_TO_USE_PROMO_CODE =
  "ERROR_CODE_FAILED_TO_USE_PROMO_CODE";
export const ERROR_CODE_FAILED_TO_UPDATE_USERNAME =
  "ERROR_CODE_FAILED_TO_UPDATE_USERNAME";

export const ERROR_CODE_SHORT_URL_FAILED_TO_CREATE =
  "ERROR_CODE_FAILED_TO_CREATE_SHORT_URL";

export const ERROR_CODE_FAILED_FETCH_FEATURES_DATA =
  "ERROR_CODE_FAILED_FETCH_FEATURES_DATA";

export const ERROR_CODE_FAILED_TO_SETUP_BY_CONFIG =
  "ERROR_CODE_FAILED_TO_SETUP_BY_CONFIG";
