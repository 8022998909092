import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DatePeriodButtonsProps } from "../../boundary/AnalyticsService/DatePeriosButtons";

export const DatePeriodButtons = ({
  dateRange,
  handleDateRangeChange,
}: DatePeriodButtonsProps) => {
  return (
    <ToggleButtonGroup
      value={dateRange}
      onChange={handleDateRangeChange}
      exclusive
    >
      <ToggleButton value={1}>Today</ToggleButton>
      <ToggleButton value={7}>Last Week</ToggleButton>
      <ToggleButton value={30}>Last Month</ToggleButton>
      <ToggleButton value={60}>Last 60 Days</ToggleButton>
      <ToggleButton value={90}>Last 90 Days</ToggleButton>
      <ToggleButton value={365}>Last Year</ToggleButton>
    </ToggleButtonGroup>
  );
};
