import { IMessage } from "../../../../../boundary/IMessage";

const KEY = "arrayInputElementChanged";
export interface ArrayInputElementMessage extends IMessage {
  type: typeof KEY;
  message: string; // aka input key
  source: string;
  data: {
    key: number;
    value: string;
  };
}

export const isMessageIsArrayInputElementMessage = (
  value: IMessage
): value is ArrayInputElementMessage => {
  return value.type === KEY;
};

export const createArrayInputElementMessage = (
  value: Omit<ArrayInputElementMessage, "type">
): ArrayInputElementMessage => ({
  type: KEY,
  ...value,
});
