import { TemplateEntity } from "../../../domain/entities/TemplateEntity";
import { PagingData } from "../../../boundary/PagingData";
import { createErrorMessage, ErrorMessage } from "@roketus/web-toolkit";
import { get, isNull, isNumber } from "lodash";
import { TemplateListDTO } from "../../../boundary/TemplateRepository/TemplateListDTO";
import {
  TextFieldAlignment,
  TextFieldEntity,
} from "../../../domain/entities/TextFieldEntity";
import {
  PartialPassBarcode,
  PartialPassLocation,
  PassPartialBackField,
  PassPartialTextField,
} from "../../../boundary/PartialPassDTO";
import { convertTemplateItemToEntity } from "../GetTemplateResponseDTO/convertToTemplateEntity";
import { LocationEntity } from "../../../domain/entities/LocationEntity";

export const convertTextField = (
  field: PassPartialTextField
): TextFieldEntity => ({
  key: field.Key ?? "",
  label: field.Label ?? "",
  value: field.Value ?? "",
  alignment: field.AlignmentId?.toString() as TextFieldAlignment,
  id: field.PassFieldId,
});

export const convertBackTextField = (
  field: PassPartialBackField
): TextFieldEntity => ({
  key: field.Key ?? "",
  label: field.Label ?? "",
  value: field.Value ?? "",
  alignment: field.AlignmentId?.toString() as TextFieldAlignment,
  id: field.PassBackId,
});

export const convertBarcode = (barcode: PartialPassBarcode) => ({
  BarcodeAltText: isNull(barcode.BarcodeAltText) ? "" : barcode.BarcodeAltText,
  BarcodeFormatId: barcode.BarcodeFormatId.toString(),
  BarcodeMessage: barcode.BarcodeMessage,
  MessageEncodingId: barcode.MessageEncodingId.toString(),
  UseBarcode: barcode.UseBarcode,
});

export const convertLocations = (
  locations: PartialPassLocation[]
): LocationEntity[] => {
  return locations.map(
    ({ Latitude, Longitude, RelevantText, PassLocationId, LocationName }) => ({
      Latitude: Latitude.toString(),
      Longitude: Longitude.toString(),
      RelevantText: RelevantText ?? undefined,
      PassLocationId,
      LocationName: LocationName ?? "",
    })
  );
};

export const convertTemplateListResponseDTOToTemplateEntity = (
  payload: TemplateListDTO
): { templates: TemplateEntity[]; pagingData: PagingData } | ErrorMessage => {
  const result: {
    templates: TemplateEntity[];
    pagingData: PagingData;
  } = {
    templates: [],
    pagingData: {
      page: 0,
      pageSize: 0,
      totalData: 0,
      totalPages: 0,
    },
  };

  const { data } = payload;
  const templates = data.Passes;
  const pagingData = data.PagingData;

  templates.forEach((template) => {
    try {
      const convertedTemplate = convertTemplateItemToEntity(template);
      result.templates.push(convertedTemplate);
    } catch (e) {
      return createErrorMessage({
        message: "Error during convertation",
        source: "convertPassListResponseDTOToPassEntity",
        error: e as Error,
      });
    }
  });

  const page = get(pagingData, "Page");
  const pageSize = get(pagingData, "PageSize");
  const totalData = get(pagingData, "TotalData");
  const totalPages = get(pagingData, "TotalPages");

  if (!isNumber(page)) {
    return createErrorMessage({
      message: "Page is not a number",
      source: "convertTemplateListResponseDTOToTemplateEntity",
    });
  }

  if (!isNumber(pageSize)) {
    return createErrorMessage({
      message: "pageSize is not a number",
      source: "convertTemplateListResponseDTOToTemplateEntity",
    });
  }

  if (!isNumber(totalData)) {
    return createErrorMessage({
      message: "totalData is not a number",
      source: "convertTemplateListResponseDTOToTemplateEntity",
    });
  }

  if (!isNumber(totalPages)) {
    return createErrorMessage({
      message: "totalPages is not a number",
      source: "convertTemplateListResponseDTOToTemplateEntity",
    });
  }

  result.pagingData = {
    page,
    pageSize,
    totalData,
    totalPages,
  };

  return result;
};
