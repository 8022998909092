import { createErrorMessage, StateSubject } from "@roketus/web-toolkit";
import { ITemplateFeature } from "../../boundary/IFeatureToggleService";
import {
  ISetupServiceData,
  ISetupServiceInit,
} from "../../boundary/ISetupService";
import { isUndefined } from "lodash";
import { ERROR_CODE_FAILED_TO_SETUP_BY_CONFIG } from "../../domain/specs/errorCodes";

const stateMachine = new StateSubject<ISetupServiceData>({
  isReady: false,
});

const source = "SetupService";
export const init: ISetupServiceInit = ({
  authService,
  ftService,
  wizardService,
  messageBus,
}) => {
  ftService.data$.subscribe((data) => {
    const apiKey = data.getFeatureByName<string>("apiKey");
    const templates = data.getFeatureByName<ITemplateFeature>("templates");

    if (isUndefined(apiKey)) {
      const errorMessage = createErrorMessage({
        code: ERROR_CODE_FAILED_TO_SETUP_BY_CONFIG,
        source,
        message: "No API key found",
        error: new Error("No API key found"),
      });
      messageBus.send(errorMessage);

      return;
    }

    if (isUndefined(templates) || templates.length === 0) {
      const errorMessage = createErrorMessage({
        code: ERROR_CODE_FAILED_TO_SETUP_BY_CONFIG,
        source,
        message: "Exception: No templates found",
        error: new Error("Exception: No templates found"),
      });
      messageBus.send(errorMessage);

      return;
    }

    wizardService.setDefaultTemplateID(templates[0].id);
    authService.loginByApiKey(apiKey);
  });
  ftService.fetchFeatures();

  return {
    data$: stateMachine.state$,
  };
};
