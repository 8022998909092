import { StepNavigation } from "../StepNavigation";
import { StepDetails } from "../StepDetails";
import { Typography } from "@mui/material";
import { WizardHelp } from "../WizardHelp";

export const ScanQrStep: React.FC = () => {
  return (
    <>
      <Typography>
        Cashier scans a QR on shopper's digital card with his smartphone or
        tablet camera
      </Typography>
      <StepDetails>
        <WizardHelp />
      </StepDetails>
      <StepNavigation />
    </>
  );
};
