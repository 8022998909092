import styled from "@emotion/styled";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { useObservable } from "@roketus/web-toolkit";
import BwipJs from "bwip-js";
import { get, isNull } from "lodash";
import { getContainer } from "../../../diContainer/container";
import { IWizardService } from "../../../boundary/IWizardService";

const QRCodeBlock = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
  padding: 30px 0;
  text-align: center;
`;

const Canvas = styled.canvas`
  margin: 20px 0;
`;

export const QRCodePreview: React.FC = () => {
  const wizardService = getContainer().getDependency(
    "wizardService"
  ) as IWizardService;
  const data$ = useObservable(wizardService.data$);

  const logoText = get(data$, "template.header.logoText");
  const businessName = data$?.businessName;
  const drawBarcode = (el: HTMLCanvasElement) => {
    BwipJs.toCanvas(el, {
      bcid: "qrcode",
      text: data$?.downloadCardUrl || "e",
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography>
        Place this QR Code in front of your customers to onboard them into your
        Loyalty Program
      </Typography>
      <Divider variant="middle" flexItem sx={{ m: "20px" }} />
      <Typography>
        Scan this QR Code with your phone. You will receive a digital loyalty
        card to add to your phone’s wallet
      </Typography>
      <QRCodeBlock elevation={5}>
        <Typography>Please Scan QR Code</Typography>
        <Canvas
          ref={(el) => {
            if (isNull(el)) return;
            drawBarcode(el);
          }}
          id="barcode"
        />
        <Typography sx={{ m: "0 40px" }}>
          Sign up for {businessName || logoText} Rewards Today
        </Typography>
      </QRCodeBlock>
    </Box>
  );
};
