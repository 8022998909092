import { Box } from "@mui/material";

export const StepDetails: React.FC<{ children: React.ReactChild }> = ({
  children,
}) => {
  return (
    <Box
      sx={{
        display: {
          xs: "block",
          md: "none",
        },
      }}
    >
      {children}
    </Box>
  );
};
