import { FC, useEffect, useRef } from "react";
import { isEmpty, isString } from "lodash";
import { ICardService } from "../../boundary/ICardService";
import { getContainer } from "../../diContainer/container";
import { useQuery } from "../../adapters/hooks/useQuery";
import { ANDROID_CARD_DOWNLOAD_JWT } from "../../domain/specs/urlParams";
import CardDownloadMessageBox from "../CardDownloadMessageBox";

export const DownloadCardAndroid: FC = () => {
  let query = useQuery();
  const jwt = query.get(ANDROID_CARD_DOWNLOAD_JWT);
  const isDownloading = useRef(false);

  const cardService = getContainer().getDependency(
    "cardService"
  ) as ICardService;

  const isValidParams = isString(jwt) && !isEmpty(jwt);

  useEffect(() => {
    if (!isValidParams) return;

    if (isDownloading.current) return;

    cardService.downloadAndroidCard(jwt);

    isDownloading.current = true;
  }, [cardService, jwt, isValidParams]);

  return isValidParams ? (
    <CardDownloadMessageBox isDownloadAndroidCard />
  ) : null;
};
