import { IRedirectService } from "../../../boundary/IRedirectService";
import { getContainer } from "../../../diContainer/container";
import { IMessage, IMessageBus } from "@roketus/web-toolkit";
import { ruleActions } from "./ruleActions";

export type RuleAction = [
  (msg: IMessage) => boolean,
  (navigateFn: (to: string) => void) => void
];

const applyActionByRule = (
  ruleActions: RuleAction[],
  msg: IMessage,
  navigateFn: (to: string) => void
) => {
  ruleActions.forEach(([rule, action]) => {
    if (rule(msg)) {
      action(navigateFn);
    }
  });
};

export const redirectService: IRedirectService = {
  init(navigateFn: (to: string) => void) {
    const messageBus = getContainer().getDependency(
      "messageBus"
    ) as IMessageBus;

    const sub = messageBus.stream$.subscribe((value) => {
      applyActionByRule(ruleActions, value, navigateFn);
    });

    return () => {
      sub.unsubscribe();
    };
  },
};
