import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { FC } from "react";
import { Layout } from "../Layout";
import { Wizard } from "../Wizard/Wizard";
import { Helmet } from "react-helmet";
import { PreviewSection } from "../PreviewSection/PreviewSection";

export const Dashboard: FC = () => {
  return (
    <Layout pageTitle="Try Atomic Loyalty">
      <Helmet>
        <title>Try Atomic Loyalty</title>
      </Helmet>

      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Wizard />
            </Paper>
          </Grid>
          <Grid
            item
            sm={6}
            sx={{
              display: {
                xs: "none",
                md: "block",
              },
            }}
          >
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                // minHeight: "540px",
              }}
            >
              <PreviewSection />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};
