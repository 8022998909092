import { createErrorMessage, ErrorMessage } from "@roketus/web-toolkit";
import { InputElementMessage } from "./InputElementMessage";
import { get, isObject, isString } from "lodash";

export const createInputElementMessage = (
  value: unknown
): InputElementMessage | ErrorMessage => {
  const message = get(value, "message");
  const source = get(value, "source");
  const data = get(value, "data");

  if (!isObject(value)) {
    return createErrorMessage({
      message: "data is not object",
      source: "createInputElementMessage",
    });
  }

  if (!isString(message)) {
    return createErrorMessage({
      message: "message is not string",
      source: "createInputElementMessage",
    });
  }

  if (!isString(data)) {
    return createErrorMessage({
      message: "data is not string",
      source: "createFileElementMessage",
    });
  }

  if (!isString(source)) {
    return createErrorMessage({
      message: "source is not string",
      source: "createInputElementMessage",
    });
  }

  return {
    type: "inputElementChanged",
    message,
    source,
    data,
  };
};
