import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { redirectService } from "../adapters/services/redirectService/redirectService";

export const RedirectServiceInitializer: FC = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    return redirectService.init(navigate);
  }, [navigate]);

  return <>{children}</>;
};
