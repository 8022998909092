import { FC } from "react";
import { Box } from "@mui/material";
import { isEmpty } from "lodash";

import styled from "@emotion/styled";
import { PassField } from "../CreatePassEntity";

interface HeaderProps {
  headerFields: PassField[];
  logoText?: string;
  logoTextAlign?: "center" | "left";
  passLabelColor?: string;
  passFgColor?: string;
  logo: string;
}

const HeaderSection = styled(Box)`
  display: flex;
  flex-direction: row;
  height: 33px;
  justify-content: space-between;
  align-items: flex-end;
  gap: 8px;
`;

const LogoText = styled(Box)`
  font-size: 13px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 1 0;
`;

const Logo = styled(Box)`
  height: 20px;

  img {
    height: 20px;
  }
`;

const HeaderFields = styled(Box)`
  display: flex;
`;

const HeaderField = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  line-height: 20px;
  font-size: 12px;
  margin-right: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-align: right;
`;

const Value = styled.span<{ color?: string }>`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => props.color};
  font-size: 16px;
  line-height: 1;
`;

const Label = styled(Value)<{ color?: string }>`
  font-size: 9px;
  text-transform: uppercase;
`;

export const Header: FC<HeaderProps> = ({
  headerFields,
  logoText,
  logoTextAlign = 'center',
  passLabelColor,
  passFgColor,
  logo,
}) => (
  <HeaderSection>
    <Logo>
      <img src={logo} alt="logo" />
    </Logo>
    <LogoText>
      <Box sx={{ whiteSpace: "nowrap", textAlign: logoTextAlign }}>
        {logoText}
      </Box>
    </LogoText>

    <HeaderFields>
      {!isEmpty(headerFields) &&
        headerFields.map((header, index) => (
          <HeaderField key={index}>
            <Label color={passLabelColor}>{header.label}</Label>
            <Value color={passFgColor}>{header.value}</Value>
          </HeaderField>
        ))}
    </HeaderFields>
  </HeaderSection>
);
