import previewImg from "./images/use-promo-code.png";
import { POSPreview } from "./POSPreview";

export const PromoCodeUsagePreview: React.FC = () => {
  return (
    <POSPreview
      paragraph="Click “Create Transaction” and enter Total amount to pay in USD. Then enter points amount to withdraw in the “Pay with Points” field"
      imageSrc={previewImg}
    />
  );
};
