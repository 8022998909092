import { ErrorMessageBus, IMessage, IMessageBus, StateSubject, createErrorMessage, isErrorMessage } from "@roketus/web-toolkit";
import { filter, map } from "rxjs";
import { IPassServiceV2 } from "../../boundary/IPassService";
import { CreatePassEntity } from "../../domain/entities/CreatePassEntity/CreatePassEntity";
import { InputElementMessage } from "../../domain/entities/messages/formElement/InputElement/InputElementMessage";
import {
  PASS_DESCRIPTION,
  PASS_HEADER_ALIGNMENT_1,
  PASS_HEADER_ALIGNMENT_2,
  PASS_HEADER_ALIGNMENT_3,
  PASS_HEADER_KEY_1,
  PASS_HEADER_KEY_2,
  PASS_HEADER_KEY_3,
  PASS_HEADER_LABEL_1,
  PASS_HEADER_LABEL_2,
  PASS_HEADER_LABEL_3,
  PASS_HEADER_VALUE_1,
  PASS_HEADER_VALUE_2,
  PASS_HEADER_VALUE_3,
  PASS_ICON,
  PASS_LOGO,
  PASS_LOGO_TEXT,
  PASS_ORG_NAME,
  PASS_PRIMARY_KEY,
  PASS_PRIMARY_LABEL,
  PASS_PRIMARY_VALUE,
  PASS_SECONDARY_ALIGNMENT_1,
  PASS_SECONDARY_ALIGNMENT_2,
  PASS_SECONDARY_ALIGNMENT_3,
  PASS_SECONDARY_ALIGNMENT_4,
  PASS_SECONDARY_KEY_1,
  PASS_SECONDARY_KEY_2,
  PASS_SECONDARY_KEY_3,
  PASS_SECONDARY_KEY_4,
  PASS_SECONDARY_LABEL_1,
  PASS_SECONDARY_LABEL_2,
  PASS_SECONDARY_LABEL_3,
  PASS_SECONDARY_LABEL_4,
  PASS_SECONDARY_VALUE_1,
  PASS_SECONDARY_VALUE_2,
  PASS_SECONDARY_VALUE_3,
  PASS_SECONDARY_VALUE_4,
  PASS_BACKGROUND_COLOR,
  PASS_FOREGROUND_COLOR,
  PASS_LABEL_COLOR,
  PASS_BARCODE_FORMAT,
  PASS_BARCODE_ENCODING,
  PASS_BARCODE_MESSAGE,
  PASS_BARCODE_ALT_TEXT,
  PASS_BARCODE_USE,
} from "../../domain/specs/inputData";
import { FileElementMessage } from "../../domain/entities/messages/formElement/FileElement/FileElementMessage";
import { IFormData } from "../../boundary/IFormData";
import { noop } from "lodash";
import {
  createEmptyPass,
  createPassWithBonusQRUseCase,
} from "../../domain/useCases/createPassUseCase";
import { IPassRepository } from "../../boundary/PassRepository/IPassRepository";
import { getContainer } from "../../diContainer/container";
import { PositiveMessageEntity } from "../../domain/entities/messages/positiveEntity";
import { POSITIVE_PASS_UPDATED } from "../../domain/specs/positiveCodes";
import { ERROR_CODE_PASS_FAILED_TO_UPDATE } from "../../domain/specs/errorCodes";
import { PassItem } from "../../boundary/PassRepository/PassItem";

const inputs = [
  PASS_ORG_NAME,
  PASS_DESCRIPTION,
  PASS_ICON,
  PASS_LOGO,
  PASS_LOGO_TEXT,
  PASS_PRIMARY_VALUE,
  PASS_PRIMARY_KEY,
  PASS_PRIMARY_LABEL,
  PASS_HEADER_KEY_1,
  PASS_HEADER_KEY_2,
  PASS_HEADER_KEY_3,
  PASS_HEADER_LABEL_1,
  PASS_HEADER_LABEL_2,
  PASS_HEADER_LABEL_3,
  PASS_HEADER_VALUE_1,
  PASS_HEADER_VALUE_2,
  PASS_HEADER_VALUE_3,
  PASS_HEADER_ALIGNMENT_1,
  PASS_HEADER_ALIGNMENT_2,
  PASS_HEADER_ALIGNMENT_3,

  PASS_SECONDARY_LABEL_1,
  PASS_SECONDARY_VALUE_1,
  PASS_SECONDARY_KEY_1,
  PASS_SECONDARY_ALIGNMENT_1,

  PASS_SECONDARY_LABEL_2,
  PASS_SECONDARY_VALUE_2,
  PASS_SECONDARY_KEY_2,
  PASS_SECONDARY_ALIGNMENT_2,

  PASS_SECONDARY_LABEL_3,
  PASS_SECONDARY_VALUE_3,
  PASS_SECONDARY_KEY_3,
  PASS_SECONDARY_ALIGNMENT_3,

  PASS_SECONDARY_LABEL_4,
  PASS_SECONDARY_VALUE_4,
  PASS_SECONDARY_KEY_4,
  PASS_SECONDARY_ALIGNMENT_4,

  PASS_BACKGROUND_COLOR,
  PASS_FOREGROUND_COLOR,
  PASS_LABEL_COLOR,

  PASS_BARCODE_FORMAT,
  PASS_BARCODE_ENCODING,
  PASS_BARCODE_MESSAGE,
  PASS_BARCODE_ALT_TEXT,
  PASS_BARCODE_USE,
];

const isPassServiceMessageData = (
  value: IMessage
): value is InputElementMessage | FileElementMessage =>
  inputs.includes(value.message);

export const init = (
  formData: IFormData,
  passRepository: IPassRepository
): IPassServiceV2 => {
  const stateMachine = new StateSubject<Partial<CreatePassEntity>>({});
  // let state: Partial<CreatePassEntity> = {};
  formData
    .pipe(
      filter(isPassServiceMessageData),
      map((value) => {
        return {
          key: value.message,
          value: value.data,
        };
      })
    )
    .subscribe(() => {
      // stateMachine.setState({
      //   [createPassKeyByInputName[v.key] || "nokey"]: v.value,
      // });
    });

  stateMachine.state$.subscribe(() => {
    // state = currentState;
  });

  const updatePass: IPassServiceV2["updatePass"] = async (pass) => {
    const container = getContainer();
    const messageBus = container.getDependency("messageBus") as IMessageBus;

    try {
      await passRepository.updatePass(pass);

      const msg: PositiveMessageEntity = {
        type: "positiveEvent",
        message: "",
        source: "passService",
        data: POSITIVE_PASS_UPDATED,
      };
      messageBus.send(msg);
    } catch (err) {
      const messageBus = container.getDependency(
        "errorMessageBus"
      ) as ErrorMessageBus;
      const errorMessage = createErrorMessage({
        code: ERROR_CODE_PASS_FAILED_TO_UPDATE,
        source: "passService",
        message: "Update Pass Server Error",
        error: err as Error,
      });
      messageBus.send(errorMessage);
    }
  };

  const getLastCard: IPassServiceV2["getLastCard"] = async () => {
    const container = getContainer();
    const messageBus = container.getDependency("messageBus") as IMessageBus;

    try {
      const result = await passRepository.list({ page: 1, pageSize: 1 });

      if (isErrorMessage(result)) {
        messageBus.send(result);
        return null;
      } else {
        const { passes } = result;
        return passes[0];
      }
    } catch (err) {
      const messageBus = container.getDependency(
        "errorMessageBus"
      ) as ErrorMessageBus;
      const errorMessage = createErrorMessage({
        code: ERROR_CODE_PASS_FAILED_TO_UPDATE,
        source: "passService",
        message: "Update Pass Server Error",
        error: err as Error,
      });
      messageBus.send(errorMessage);

      return null;
    }
  };

  return {
    data$: stateMachine.state$,
    submit: noop,
    createPassWithQRBonusLink: async (templateId: string) => {
      await createPassWithBonusQRUseCase(templateId, passRepository);
    },
    createPass: async (templateId: string) => {
      await createEmptyPass(templateId, passRepository);
    },
    updatePass,
    getLastCard,
  };
};
