import previewImg from "./images/pos-menu.png";
import { POSPreview } from "./POSPreview";

export const PosMenuPreview: React.FC = () => {
  return (
    <POSPreview
      paragraph="Apply points or discount to cheque, upgrade shopper’s card tier, stamp a card, redeem a coupon - all operations depends on Loyalty Program Rules, defined by business owner"
      imageSrc={previewImg}
    />
  );
};
