import { StepNavigation } from "../StepNavigation";
import { StepDetails } from "../StepDetails";
import { Typography } from "@mui/material";
import { WizardHelp } from "../WizardHelp";

export const OpenPosTerminalStep: React.FC = () => {
  return (
    <>
      <Typography gutterBottom>
        Enter customer’s phone or card number on the POS Terminal main screen
      </Typography>
      <Typography gutterBottom>
        *You should signup to use POS Terminal features
      </Typography>
      <StepDetails>
        <WizardHelp />
      </StepDetails>
      <StepNavigation />
    </>
  );
};
