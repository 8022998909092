export const PASS_ORG_NAME = "PASS_ORG_NAME";
export const PASS_DESCRIPTION = "PASS_DESCRIPTION";
export const PASS_ICON = "PASS_ICON";

export const PASS_LOGO_TEXT = "PASS_LOGO_TEXT";
export const PASS_LOGO = "PASS_LOGO";

export const PASS_PRIMARY_LABEL = "PASS_PRIMARY_LABEL";
export const PASS_PRIMARY_VALUE = "PASS_PRIMARY_VALUE";
export const PASS_PRIMARY_KEY = "PASS_PRIMARY_KEY";

export const PASS_SECONDARY_LABEL_1 = "PASS_SECONDARY_LABEL_1";
export const PASS_SECONDARY_VALUE_1 = "PASS_SECONDARY_VALUE_1";
export const PASS_SECONDARY_KEY_1 = "PASS_SECONDARY_KEY_1";
export const PASS_SECONDARY_ALIGNMENT_1 = "PASS_SECONDARY_ALIGNMENT_1";

export const PASS_SECONDARY_LABEL_2 = "PASS_SECONDARY_LABEL_2";
export const PASS_SECONDARY_VALUE_2 = "PASS_SECONDARY_VALUE_2";
export const PASS_SECONDARY_KEY_2 = "PASS_SECONDARY_KEY_2";
export const PASS_SECONDARY_ALIGNMENT_2 = "PASS_SECONDARY_ALIGNMENT_2";

export const PASS_SECONDARY_LABEL_3 = "PASS_SECONDARY_LABEL_3";
export const PASS_SECONDARY_VALUE_3 = "PASS_SECONDARY_VALUE_3";
export const PASS_SECONDARY_KEY_3 = "PASS_SECONDARY_KEY_3";
export const PASS_SECONDARY_ALIGNMENT_3 = "PASS_SECONDARY_ALIGNMENT_3";

export const PASS_SECONDARY_LABEL_4 = "PASS_SECONDARY_LABEL_4";
export const PASS_SECONDARY_VALUE_4 = "PASS_SECONDARY_VALUE_4";
export const PASS_SECONDARY_KEY_4 = "PASS_SECONDARY_KEY_4";
export const PASS_SECONDARY_ALIGNMENT_4 = "PASS_SECONDARY_ALIGNMENT_4";

export const PASS_HEADER_LABEL_1 = "PASS_HEADER_LABEL_1";
export const PASS_HEADER_VALUE_1 = "PASS_HEADER_VALUE_1";
export const PASS_HEADER_KEY_1 = "PASS_HEADER_KEY_1";
export const PASS_HEADER_ALIGNMENT_1 = "PASS_HEADER_ALIGNMENT_1";

export const PASS_HEADER_LABEL_2 = "PASS_HEADER_LABEL_2";
export const PASS_HEADER_VALUE_2 = "PASS_HEADER_VALUE_2";
export const PASS_HEADER_KEY_2 = "PASS_HEADER_KEY_2";
export const PASS_HEADER_ALIGNMENT_2 = "PASS_HEADER_ALIGNMENT_2";

export const PASS_HEADER_LABEL_3 = "PASS_HEADER_LABEL_3";
export const PASS_HEADER_VALUE_3 = "PASS_HEADER_VALUE_3";
export const PASS_HEADER_KEY_3 = "PASS_HEADER_KEY_3";
export const PASS_HEADER_ALIGNMENT_3 = "PASS_HEADER_ALIGNMENT_3";

export const PASS_BACKGROUND_COLOR = "PASS_BACKGROUND_COLOR";
export const PASS_FOREGROUND_COLOR = "PASS_FOREGROUND_COLOR";
export const PASS_LABEL_COLOR = "PASS_LABEL_COLOR";

export const PASS_BARCODE_FORMAT = "PASS_BARCODE_FORMAT";
export const PASS_BARCODE_ENCODING = "PASS_BARCODE_ENCODING";
export const PASS_BARCODE_MESSAGE = "PASS_BARCODE_MESSAGE";
export const PASS_BARCODE_ALT_TEXT = "PASS_BARCODE_ALT_TEXT";
export const PASS_BARCODE_USE = "PASS_BARCODE_USE";

export const PASS_LOCATION_LATITUDE_1 = "PASS_LOCATION_LATITUDE_1";
export const PASS_LOCATION_LONGITUDE_1 = "PASS_LOCATION_LONGITUDE_1";
export const PASS_LOCATION_RELEVANT_TEXT_1 = "PASS_LOCATION_RELEVANT_TEXT_1";

export const PASS_LOCATION_LATITUDE_2 = "PASS_LOCATION_LATITUDE_2";
export const PASS_LOCATION_LONGITUDE_2 = "PASS_LOCATION_LONGITUDE_2";
export const PASS_LOCATION_RELEVANT_TEXT_2 = "PASS_LOCATION_RELEVANT_TEXT_2";

export const PASS_LOCATION_LATITUDE_3 = "PASS_LOCATION_LATITUDE_3";
export const PASS_LOCATION_LONGITUDE_3 = "PASS_LOCATION_LONGITUDE_3";
export const PASS_LOCATION_RELEVANT_TEXT_3 = "PASS_LOCATION_RELEVANT_TEXT_3";

export const PASS_LOCATION_LATITUDE_4 = "PASS_LOCATION_LATITUDE_4";
export const PASS_LOCATION_LONGITUDE_4 = "PASS_LOCATION_LONGITUDE_4";
export const PASS_LOCATION_RELEVANT_TEXT_4 = "PASS_LOCATION_RELEVANT_TEXT_4";

export const PASS_LOCATION_LATITUDE_5 = "PASS_LOCATION_LATITUDE_5";
export const PASS_LOCATION_LONGITUDE_5 = "PASS_LOCATION_LONGITUDE_5";
export const PASS_LOCATION_RELEVANT_TEXT_5 = "PASS_LOCATION_RELEVANT_TEXT_5";

export const PASS_LOCATION_LATITUDE_6 = "PASS_LOCATION_LATITUDE_6";
export const PASS_LOCATION_LONGITUDE_6 = "PASS_LOCATION_LONGITUDE_6";
export const PASS_LOCATION_RELEVANT_TEXT_6 = "PASS_LOCATION_RELEVANT_TEXT_6";

export const PASS_LOCATION_LATITUDE_7 = "PASS_LOCATION_LATITUDE_7";
export const PASS_LOCATION_LONGITUDE_7 = "PASS_LOCATION_LONGITUDE_7";
export const PASS_LOCATION_RELEVANT_TEXT_7 = "PASS_LOCATION_RELEVANT_TEXT_7";

export const PASS_LOCATION_LATITUDE_8 = "PASS_LOCATION_LATITUDE_8";
export const PASS_LOCATION_LONGITUDE_8 = "PASS_LOCATION_LONGITUDE_8";
export const PASS_LOCATION_RELEVANT_TEXT_8 = "PASS_LOCATION_RELEVANT_TEXT_8";

export const PASS_LOCATION_LATITUDE_9 = "PASS_LOCATION_LATITUDE_9";
export const PASS_LOCATION_LONGITUDE_9 = "PASS_LOCATION_LONGITUDE_9";
export const PASS_LOCATION_RELEVANT_TEXT_9 = "PASS_LOCATION_RELEVANT_TEXT_9";

export const PASS_LOCATION_LATITUDE_10 = "PASS_LOCATION_LATITUDE_10";
export const PASS_LOCATION_LONGITUDE_10 = "PASS_LOCATION_LONGITUDE_10";
export const PASS_LOCATION_RELEVANT_TEXT_10 = "PASS_LOCATION_RELEVANT_TEXT_10";
