import previewImg from "./images/pos-terminal.png";
import { POSPreview } from "./POSPreview";

export const OpenPosTerminalPreview: React.FC = () => {
  return (
    <POSPreview
      paragraph="Enter customer’s phone or card number on the POS Terminal main screen"
      imageSrc={previewImg}
    />
  );
};
