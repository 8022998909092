const labels = [
  "1 / 1 / 2022",
  "1 / 2 / 2022",
  "1 / 3 / 2022",
  "1 / 4 / 2022",
  "1 / 5 / 2022",
  "1 / 6 / 2022",
  "1 / 7 / 2022",
  "1 / 8 / 2022",
  "1 / 9 / 2022",
  "1 / 10 / 2022",
  "1 / 11 / 2022",
  "1 / 12 / 2022",
  "1 / 13 / 2022",
  "1 / 14 / 2022",
  "1 / 15 / 2022",
  "1 / 16 / 2022",
  "1 / 17 / 2022",
  "1 / 18 / 2022",
  "1 / 19 / 2022",
  "1 / 20 / 2022",
  "1 / 21 / 2022",
  "1 / 22 / 2022",
  "1 / 23 / 2022",
  "1 / 24 / 2022",
  "1 / 25 / 2022",
  "1 / 26 / 2022",
  "1 / 27 / 2022",
  "1 / 28 / 2022",
  "1 / 29 / 2022",
  "1 / 30 / 2022",
  "1 / 31 / 2022",
  " 2 / 1 / 2022",
  " 2 / 2 / 2022",
  " 2 / 3 / 2022",
  " 2 / 4 / 2022",
  " 2 / 5 / 2022",
  " 2 / 6 / 2022",
  " 2 / 7 / 2022",
  " 2 / 8 / 2022",
  " 2 / 9 / 2022",
  "2 / 10 / 2022",
  "2 / 11 / 2022",
  "2 / 12 / 2022",
  "2 / 13 / 2022",
  "2 / 14 / 2022",
  "2 / 15 / 2022",
  "2 / 16 / 2022",
  "2 / 17 / 2022",
  "2 / 18 / 2022",
  "2 / 19 / 2022",
  "2 / 20 / 2022",
  "2 / 21 / 2022",
  "2 / 22 / 2022",
  "2 / 23 / 2022",
  "2 / 24 / 2022",
  "2 / 25 / 2022",
  "2 / 26 / 2022",
  "2 / 27 / 2022",
  "2 / 28 / 2022",
  " 3 / 1 / 2022",
  " 3 / 2 / 2022",
  " 3 / 3 / 2022",
  " 3 / 4 / 2022",
  " 3 / 5 / 2022",
  " 3 / 6 / 2022",
  " 3 / 7 / 2022",
  " 3 / 8 / 2022",
  " 3 / 9 / 2022",
  "3 / 10 / 2022",
  "3 / 11 / 2022",
  "3 / 12 / 2022",
  "3 / 13 / 2022",
  "3 / 14 / 2022",
  "3 / 15 / 2022",
  "3 / 16 / 2022",
  "3 / 17 / 2022",
  "3 / 18 / 2022",
  "3 / 19 / 2022",
  "3 / 20 / 2022",
  "3 / 21 / 2022",
  "3 / 22 / 2022",
  "3 / 23 / 2022",
  "3 / 24 / 2022",
  "3 / 25 / 2022",
  "3 / 26 / 2022",
  "3 / 27 / 2022",
  "3 / 28 / 2022",
  "3 / 29 / 2022",
  "3 / 30 / 2022",
  "3 / 31 / 2022",
  " 4 / 1 / 2022",
  " 4 / 2 / 2022",
  " 4 / 3 / 2022",
  " 4 / 4 / 2022",
  " 4 / 5 / 2022",
  " 4 / 6 / 2022",
  " 4 / 7 / 2022",
  " 4 / 8 / 2022",
  " 4 / 9 / 2022",
  "4 / 10 / 2022",
  "4 / 11 / 2022",
  "4 / 12 / 2022",
  "4 / 13 / 2022",
  "4 / 14 / 2022",
  "4 / 15 / 2022",
  "4 / 16 / 2022",
  "4 / 17 / 2022",
  "4 / 18 / 2022",
  "4 / 19 / 2022",
  "4 / 20 / 2022",
  "4 / 21 / 2022",
  "4 / 22 / 2022",
  "4 / 23 / 2022",
  "4 / 24 / 2022",
  "4 / 25 / 2022",
  "4 / 26 / 2022",
  "4 / 27 / 2022",
  "4 / 28 / 2022",
  "4 / 29 / 2022",
  "4 / 30 / 2022",
  " 5 / 1 / 2022",
  " 5 / 2 / 2022",
  " 5 / 3 / 2022",
  " 5 / 4 / 2022",
  " 5 / 5 / 2022",
  " 5 / 6 / 2022",
  " 5 / 7 / 2022",
  " 5 / 8 / 2022",
  " 5 / 9 / 2022",
  "5 / 10 / 2022",
  "5 / 11 / 2022",
  "5 / 12 / 2022",
  "5 / 13 / 2022",
  "5 / 14 / 2022",
  "5 / 15 / 2022",
  "5 / 16 / 2022",
  "5 / 17 / 2022",
  "5 / 18 / 2022",
  "5 / 19 / 2022",
  "5 / 20 / 2022",
  "5 / 21 / 2022",
  "5 / 22 / 2022",
  "5 / 23 / 2022",
  "5 / 24 / 2022",
  "5 / 25 / 2022",
  "5 / 26 / 2022",
  "5 / 27 / 2022",
  "5 / 28 / 2022",
  "5 / 29 / 2022",
  "5 / 30 / 2022",
  "5 / 31 / 2022",
  " 6 / 1 / 2022",
  " 6 / 2 / 2022",
  " 6 / 3 / 2022",
  " 6 / 4 / 2022",
  " 6 / 5 / 2022",
  " 6 / 6 / 2022",
  " 6 / 7 / 2022",
  " 6 / 8 / 2022",
  " 6 / 9 / 2022",
  "6 / 10 / 2022",
  "6 / 11 / 2022",
  "6 / 12 / 2022",
  "6 / 13 / 2022",
  "6 / 14 / 2022",
  "6 / 15 / 2022",
  "6 / 16 / 2022",
  "6 / 17 / 2022",
  "6 / 18 / 2022",
  "6 / 19 / 2022",
  "6 / 20 / 2022",
  "6 / 21 / 2022",
  "6 / 22 / 2022",
  "6 / 23 / 2022",
  "6 / 24 / 2022",
  "6 / 25 / 2022",
  "6 / 26 / 2022",
  "6 / 27 / 2022",
  "6 / 28 / 2022",
  "6 / 29 / 2022",
  "6 / 30 / 2022",
  " 7 / 1 / 2022",
  " 7 / 2 / 2022",
  " 7 / 3 / 2022",
  " 7 / 4 / 2022",
  " 7 / 5 / 2022",
  " 7 / 6 / 2022",
  " 7 / 7 / 2022",
  " 7 / 8 / 2022",
  " 7 / 9 / 2022",
  "7 / 10 / 2022",
  "7 / 11 / 2022",
  "7 / 12 / 2022",
  "7 / 13 / 2022",
  "7 / 14 / 2022",
  "7 / 15 / 2022",
  "7 / 16 / 2022",
  "7 / 17 / 2022",
  "7 / 18 / 2022",
  "7 / 19 / 2022",
  "7 / 20 / 2022",
  "7 / 21 / 2022",
  "7 / 22 / 2022",
  "7 / 23 / 2022",
  "7 / 24 / 2022",
  "7 / 25 / 2022",
  "7 / 26 / 2022",
  "7 / 27 / 2022",
];
const getConversionFunnelLabels = () => {
  return [
    "On-Boarding",
    "Sign-up Start",
    "Cards Installed",
    "Cards Activated",
    "First Transaction",
    "Second Transaction",
  ];
};
const activeUsers = {
  DAU: [
    5.89, 8.5, 7.55, 6.38, 6.62, 6.33, 6.1, 6.35, 9.08, 7.05, 6.1, 6.56, 6.18,
    5.74, 5.48, 8.91, 7.26, 16.71, 9.72, 7.14, 6.39, 10.75, 10.05, 7.5, 6.72,
    6.81, 6.58, 5.64, 5.84, 14.61, 7.25, 6.14, 6.37, 5.9, 6.01, 6.92, 9.93,
    11.41, 18.35, 9.77, 7.25, 6.08, 6.5, 8.73, 7.81, 16.47, 8.43, 7.39, 6.93,
    7.33, 10.49, 8.17, 9.1, 6.43, 7.04, 6.65, 6.8, 8.87, 8.34, 7.08, 7.72, 7.04,
    6.64, 7.07, 10.81, 9.25, 7.54, 6.89, 6.92, 7.13, 7.09, 10.99, 7.4, 6.46,
    6.76, 6.69, 13.34, 8.5, 11.01, 8.13, 7.39, 6.22, 6.68, 6.24, 6.82, 9.79,
    8.29, 18.46, 9.45, 7.77, 6.85, 8.75, 15.36, 12.07, 9.92, 9.03, 8.72, 7.83,
    8.21, 10.27, 9.64, 10.67, 17.75, 8.15, 6.92, 8.86, 11.26, 9.98, 10.36,
    17.72, 7.77, 7.41, 7.39, 10.57, 8.35, 19.57, 9.66, 7.86, 7.29, 7.56, 11.44,
    8.96, 18.51, 10.35, 7.33, 7.34, 7.64, 9.08, 8.67, 7.62, 6.98, 7.45, 11.37,
    10.65, 21.53, 10.8, 9.57, 7.64, 8.4, 11.7, 9.02, 10.87, 16.97, 7.77, 18.22,
    8.62, 12.38, 9.24, 8.82, 7.97, 7.76,
  ],
  MAU: [
    86.35, 87.18, 88.13, 88.66, 89.44, 90.37, 90.92, 91.1, 91.98, 91.03, 90.26,
    89.99, 89.64, 89.23, 88.78, 88.86, 89.14, 93.75, 95.28, 96.06, 97.23, 99.3,
    100, 100.45, 100.43, 100.58, 100.49, 100.28, 99.98, 101.49, 101.19, 100.76,
    100.38, 100.2, 99.88, 99.82, 100.91, 103.69, 107.1, 108.25, 109.11, 108.91,
    108.71, 108.81, 109.28, 110.91, 111.18, 108.72, 107.88, 107.52, 107.16,
    106.09, 106.37, 106.07, 106.47, 106.27, 105.73, 105.93, 106.08, 104.31,
    104.34, 104.48, 104.54, 104.13, 104.47, 104.75, 104.56, 103.18, 99.91,
    98.99, 98.79, 99.63, 99.82, 99.44, 99.31, 95.21, 96.95, 96.84, 96.88, 96.88,
    96.6, 96.06, 95.58, 95.79, 95.68, 95.84, 96.6, 100.43, 101.16, 101.65,
    101.49, 102.41, 106.52, 109.04, 110.3, 111.06, 111.86, 112.64, 112.94,
    113.54, 114.54, 115.25, 118.26, 118.9, 119.01, 119.79, 118.51, 118.61,
    119.25, 121.51, 121.65, 121.88, 121.9, 122.06, 122.2, 124.38, 124.54,
    121.54, 121.12, 120.76, 120.82, 120.92, 121.27, 120.44, 119.71, 119.17,
    118.75, 119.19, 118.92, 117.91, 115.59, 115.35, 116.54, 117.65, 120.75,
    121.46, 122.01, 119.68, 120.13, 120.91, 121.76, 122.86, 125.12, 122.45,
    124.36, 124.65, 125.17, 125.73, 125.73, 123.58, 123.48,
  ],
  WAU: [
    32.28, 32.83, 33.33, 32.97, 34.14, 35.46, 35.76, 35.97, 36.01, 35.56, 35.12,
    35.07, 34.82, 34.93, 34.59, 34.64, 35.25, 43.27, 45.64, 46.31, 46.48, 48.85,
    48.89, 48.96, 42.79, 41, 40.5, 39.76, 35.97, 39.28, 39.37, 39.37, 39.04,
    38.56, 39.22, 40.17, 35.92, 39.04, 47.19, 49.26, 50.22, 50.04, 49.63, 48.88,
    47.13, 45.56, 45, 45.11, 45.55, 45.98, 46.31, 46.66, 41.51, 39.87, 39.7,
    39.35, 39.13, 37.77, 37.83, 36.88, 37.57, 37.89, 37.78, 37.86, 38.15, 38.67,
    38.88, 38.7, 38.76, 39.17, 39.7, 40.02, 39.23, 38.6, 38.49, 38.22, 42.8,
    43.54, 42.98, 43.27, 43.73, 43.76, 43.81, 39.17, 38.22, 37.29, 37.64, 45.8,
    47.99, 48.88, 49.39, 50.62, 54.63, 56.91, 50.49, 49.93, 50.35, 51.02, 50.49,
    47.04, 45.88, 46.29, 52.52, 52.43, 52.1, 52.65, 52.58, 52.55, 52.48, 51.74,
    51.53, 52.19, 51.57, 50.79, 50.09, 54.83, 51.12, 51.29, 51.42, 51.58, 51.52,
    52.03, 50.73, 50.77, 50.38, 50.35, 50.52, 50.59, 43.82, 42.33, 42.27, 42.11,
    42.09, 43.34, 52.76, 55.03, 56.63, 57.09, 57.82, 57.8, 57.29, 49.81, 53.79,
    52.72, 58.68, 58.67, 58.44, 58.89, 57.68, 52.9, 45.61,
  ],
};
const userRetentionLabels = ["1", "2", "3", "4", "5", "6"];
const points = {
  received: [
    0, 269, 536, 801, 1064, 1325, 1584, 1841, 2096, 2349, 2600, 2849, 3096,
    3341, 3584, 3825, 4064, 4301, 4536, 4769, 5000, 5229, 5456, 5681, 5904,
    6125, 6344, 6561, 6776, 6989, 7200, 7409, 7616, 7821, 8024, 8225, 8424,
    8621, 8816, 9009, 9200, 9389, 9576, 9761, 9944, 10125, 10304, 10481, 10656,
    10829, 11000, 11169, 11336, 11501, 11664, 11825, 11984, 12141, 12296, 12449,
    12600, 12749, 12896, 13041, 13184, 13325, 13464, 13601, 13736, 13869, 14000,
    14129, 14256, 14381, 14504, 14625, 14744, 14861, 14976, 15089, 15200, 15309,
    15416, 15521, 15624, 15725, 15824, 15921, 16016, 16109, 16200, 16289, 16376,
    16461, 16544, 16625, 16704, 16781, 16856, 16929, 17000, 17069, 17136, 17201,
    17264, 17325, 17384, 17441, 17496, 17549, 17600, 17649, 17696, 17741, 17784,
    17825, 17864, 17901, 17936, 17969, 18000, 18029, 18056, 18081, 18104, 18125,
    18144, 18161, 18176, 18189, 18200, 18209, 18216, 18221, 18224, 18225, 18224,
    18221, 18216, 18209, 18200, 18189, 18176, 18161, 18144, 18125, 18104, 18081,
    18056, 18029, 18000,
  ],
  used: [
    0, 0.005, 0.04, 0.135, 0.32, 0.625, 1.08, 1.715, 2.56, 3.645, 5, 6.655,
    8.64, 10.985, 13.72, 16.875, 20.48, 24.565, 29.16, 34.295, 40, 46.305,
    53.24, 60.835, 69.12, 78.125, 87.88, 98.415, 109.76, 121.945, 135, 148.955,
    163.84, 179.685, 196.52, 214.375, 233.28, 253.265, 274.36, 296.595, 320,
    344.605, 370.44, 397.535, 425.92, 455.625, 486.68, 519.115, 552.96, 588.245,
    625, 663.255, 703.04, 744.385, 787.32, 831.875, 878.08, 925.965, 975.56,
    1026.895, 1080, 1134.905, 1191.64, 1250.235, 1310.72, 1373.125, 1437.48,
    1503.815, 1572.16, 1642.545, 1715, 1789.555, 1866.24, 1945.085, 2026.12,
    2109.375, 2194.88, 2282.665, 2372.76, 2465.195, 2560, 2657.205, 2756.84,
    2858.935, 2963.52, 3070.625, 3180.28, 3292.515, 3407.36, 3524.845, 3645,
    3767.855, 3893.44, 4021.785, 4152.92, 4286.875, 4423.68, 4563.365, 4705.96,
    4851.495, 5000, 5151.505, 5306.04, 5463.635, 5624.32, 5788.125, 5955.08,
    6125.215, 6298.56, 6475.145, 6655, 6838.155, 7024.64, 7214.485, 7407.72,
    7604.375, 7804.48, 8008.065, 8215.16, 8425.795, 8640, 8857.805, 9079.24,
    9304.335, 9533.12, 9765.625, 10001.88, 10241.915, 10485.76, 10733.445,
    10985, 11240.455, 11499.84, 11763.185, 12030.52, 12301.875, 12577.28,
    12856.765, 13140.36, 13428.095, 13720, 14016.105, 14316.44, 14621.035,
    14929.92, 15243.125, 15560.68, 15882.615, 16208.96, 16539.745, 16875,
  ],
};
export const passesIssued = [
  7, 7, 7, 7, 7, 8, 8, 7.35, 7.35, 7.35, 7.35, 7.35, 8.4, 8.4, 7, 7.7175,
  7.7175, 7.7175, 7.7175, 8.82, 8.82, 7.35, 8.103375, 8.103375, 8.103375,
  8.103375, 9.261, 9.261, 7.7175, 8.50854375, 8.50854375, 8.50854375,
  8.50854375, 9.72405, 9.72405, 8.103375, 8.9339709375, 8.9339709375,
  8.9339709375, 8.9339709375, 10.2102525, 10.2102525, 8.50854375,
  9.380669484375, 9.380669484375, 9.380669484375, 9.380669484375, 10.720765125,
  10.720765125, 8.9339709375, 9.84970295859375, 9.84970295859375,
  9.84970295859375, 9.84970295859375, 11.25680338125, 11.25680338125,
  9.380669484375, 10.3421881065234, 10.3421881065234, 10.3421881065234,
  10.3421881065234, 11.8196435503125, 11.8196435503125, 9.84970295859375,
  10.8592975118496, 10.8592975118496, 10.8592975118496, 10.8592975118496,
  12.4106257278281, 12.4106257278281, 10.3421881065234, 11.4022623874421,
  11.4022623874421, 11.4022623874421, 11.4022623874421, 13.0311570142195,
  13.0311570142195, 10.8592975118496, 11.9723755068142, 11.9723755068142,
  11.9723755068142, 11.9723755068142, 13.6827148649305, 13.6827148649305,
  11.4022623874421, 12.5709942821549, 12.5709942821549, 12.5709942821549,
  12.5709942821549, 14.366850608177, 14.366850608177, 11.9723755068142,
  13.1995439962627, 13.1995439962627, 13.1995439962627, 13.1995439962627,
  15.0851931385859, 15.0851931385859, 12.5709942821549, 13.8595211960758,
  13.8595211960758, 13.8595211960758, 13.8595211960758, 15.8394527955152,
  15.8394527955152, 13.1995439962627, 14.5524972558796, 14.5524972558796,
  14.5524972558796, 14.5524972558796, 16.631425435291, 16.631425435291,
  13.8595211960758, 15.2801221186736, 15.2801221186736, 15.2801221186736,
  15.2801221186736, 17.4629967070555, 17.4629967070555, 14.5524972558796,
  16.0441282246072, 16.0441282246072, 16.0441282246072, 16.0441282246072,
  18.3361465424083, 18.3361465424083, 15.2801221186736, 16.8463346358376,
  16.8463346358376, 16.8463346358376, 16.8463346358376, 19.2529538695287,
  19.2529538695287, 16.0441282246072, 17.6886513676295, 17.6886513676295,
  17.6886513676295, 17.6886513676295, 20.2156015630051, 20.2156015630051,
  16.8463346358376, 18.573083936011, 18.573083936011, 18.573083936011,
  18.573083936011, 21.2263816411554, 21.2263816411554, 17.6886513676295,
  19.5017381328115, 19.5017381328115, 19.5017381328115,
];
export const numberScans = [
  11, 9, 20, 8, 5, 11, 12, 6, 11, 9, 20, 8, 5, 11, 12, 6, 11, 9, 20, 8, 5, 11,
  12, 6, 11, 9, 20, 8, 5, 11, 12, 6, 11, 9, 20, 8, 5, 11, 12, 6, 11, 9, 20, 8,
  5, 11, 12, 6, 11, 9, 20, 8, 5, 11, 12, 6, 11, 9, 20, 8, 5, 11, 12, 6, 11, 9,
  20, 8, 5, 11, 12, 6, 11, 9, 20, 8, 5, 11, 12, 6, 11, 9, 20, 8, 5, 11, 12, 6,
  11, 9, 20, 8, 5, 11, 12, 6, 11, 9, 20, 8, 5, 11, 12, 6, 11, 9, 20, 8, 5, 11,
  12, 6, 11, 9, 20, 8, 5, 11, 12, 6, 11, 9, 20, 8, 5, 11, 12, 6, 11, 9, 20, 8,
  5, 11, 12, 6, 11, 9, 20, 8, 5, 11, 12, 6, 11, 9, 20, 8, 5, 11, 12,
];
export const pointsReceived = [
  0, 269, 536, 801, 1064, 1325, 1584, 1841, 2096, 2349, 2600, 2849, 3096, 3341,
  3584, 3825, 4064, 4301, 4536, 4769, 5000, 5229, 5456, 5681, 5904, 6125, 6344,
  6561, 6776, 6989, 7200, 7409, 7616, 7821, 8024, 8225, 8424, 8621, 8816, 9009,
  9200, 9389, 9576, 9761, 9944, 10125, 10304, 10481, 10656, 10829, 11000, 11169,
  11336, 11501, 11664, 11825, 11984, 12141, 12296, 12449, 12600, 12749, 12896,
  13041, 13184, 13325, 13464, 13601, 13736, 13869, 14000, 14129, 14256, 14381,
  14504, 14625, 14744, 14861, 14976, 15089, 15200, 15309, 15416, 15521, 15624,
  15725, 15824, 15921, 16016, 16109, 16200, 16289, 16376, 16461, 16544, 16625,
  16704, 16781, 16856, 16929, 17000, 17069, 17136, 17201, 17264, 17325, 17384,
  17441, 17496, 17549, 17600, 17649, 17696, 17741, 17784, 17825, 17864, 17901,
  17936, 17969, 18000, 18029, 18056, 18081, 18104, 18125, 18144, 18161, 18176,
  18189, 18200, 18209, 18216, 18221, 18224, 18225, 18224, 18221, 18216, 18209,
  18200, 18189, 18176, 18161, 18144, 18125, 18104, 18081, 18056, 18029, 18000,
];
export const pointsUsed = [
  0, 0.005, 0.04, 0.135, 0.32, 0.625, 1.08, 1.715, 2.56, 3.645, 5, 6.655, 8.64,
  10.985, 13.72, 16.875, 20.48, 24.565, 29.16, 34.295, 40, 46.305, 53.24,
  60.835, 69.12, 78.125, 87.88, 98.415, 109.76, 121.945, 135, 148.955, 163.84,
  179.685, 196.52, 214.375, 233.28, 253.265, 274.36, 296.595, 320, 344.605,
  370.44, 397.535, 425.92, 455.625, 486.68, 519.115, 552.96, 588.245, 625,
  663.255, 703.04, 744.385, 787.32, 831.875, 878.08, 925.965, 975.56, 1026.895,
  1080, 1134.905, 1191.64, 1250.235, 1310.72, 1373.125, 1437.48, 1503.815,
  1572.16, 1642.545, 1715, 1789.555, 1866.24, 1945.085, 2026.12, 2109.375,
  2194.88, 2282.665, 2372.76, 2465.195, 2560, 2657.205, 2756.84, 2858.935,
  2963.52, 3070.625, 3180.28, 3292.515, 3407.36, 3524.845, 3645, 3767.855,
  3893.44, 4021.785, 4152.92, 4286.875, 4423.68, 4563.365, 4705.96, 4851.495,
  5000, 5151.505, 5306.04, 5463.635, 5624.32, 5788.125, 5955.08, 6125.215,
  6298.56, 6475.145, 6655, 6838.155, 7024.64, 7214.485, 7407.72, 7604.375,
  7804.48, 8008.065, 8215.16, 8425.795, 8640, 8857.805, 9079.24, 9304.335,
  9533.12, 9765.625, 10001.88, 10241.915, 10485.76, 10733.445, 10985, 11240.455,
  11499.84, 11763.185, 12030.52, 12301.875, 12577.28, 12856.765, 13140.36,
  13428.095, 13720, 14016.105, 14316.44, 14621.035, 14929.92, 15243.125,
  15560.68, 15882.615, 16208.96, 16539.745, 16875,
];
export const passesDeleted = [
  0.48, 0.16, 0.32, 0.24, 0.36, 0.372, 0.372, 0.3369, 0.324, 0.36, 0.3, 0.3369,
  0.3936, 0.3936, 0.318, 0.356745, 0.356745, 0.356745, 0.356745, 0.41628,
  0.41628, 0.3369, 0.37758225, 0.37758225, 0.37758225, 0.37758225, 0.440094,
  0.440094, 0.356745, 0.3994613625, 0.3994613625, 0.3994613625, 0.3994613625,
  0.4650987, 0.4650987, 0.37758225, 0.422434430625, 0.422434430625,
  0.422434430625, 0.422434430625, 0.491353635, 0.491353635, 0.3994613625,
  0.44655615215625, 0.44655615215625, 0.44655615215625, 0.44655615215625,
  0.51892131675, 0.51892131675, 0.422434430625, 0.471883959764063,
  0.471883959764063, 0.471883959764063, 0.471883959764063, 0.5478673825875,
  0.5478673825875, 0.44655615215625, 0.498478157752266, 0.498478157752266,
  0.498478157752266, 0.498478157752266, 0.578260751716875, 0.578260751716875,
  0.471883959764063, 0.526402065639879, 0.526402065639879, 0.526402065639879,
  0.526402065639879, 0.610173789302719, 0.610173789302719, 0.498478157752266,
  0.555722168921873, 0.555722168921873, 0.555722168921873, 0.555722168921873,
  0.643682478767855, 0.643682478767855, 0.526402065639879, 0.586508277367967,
  0.586508277367967, 0.586508277367967, 0.586508277367967, 0.678866602706248,
  0.678866602706248, 0.555722168921873, 0.618833691236365, 0.618833691236365,
  0.618833691236365, 0.618833691236365, 0.71580993284156, 0.71580993284156,
  0.586508277367967, 0.652775375798184, 0.652775375798184, 0.652775375798184,
  0.652775375798184, 0.754600429483638, 0.754600429483638, 0.618833691236365,
  0.688414144588093, 0.688414144588093, 0.688414144588093, 0.688414144588093,
  0.79533045095782, 0.79533045095782, 0.652775375798184, 0.725834851817497,
  0.725834851817497, 0.725834851817497, 0.725834851817497, 0.838096973505711,
  0.838096973505711, 0.688414144588093, 0.765126594408372, 0.765126594408372,
  0.765126594408372, 0.765126594408372, 0.883001822180997, 0.883001822180997,
  0.725834851817497, 0.806382924128791, 0.806382924128791, 0.806382924128791,
  0.806382924128791, 0.930151913290047, 0.930151913290047, 0.765126594408372,
  0.84970207033523, 0.84970207033523, 0.84970207033523, 0.84970207033523,
  0.979659508954549, 0.979659508954549, 0.806382924128791, 0.895187173851992,
  0.895187173851992, 0.895187173851992, 0.895187173851992, 1.03164248440228,
  1.03164248440228, 0.84970207033523, 0.942946532544592, 0.942946532544592,
  0.942946532544592, 0.942946532544592, 1.08622460862239, 1.08622460862239,
  0.895187173851992, 0.993093859171821, 0.993093859171821, 0.993093859171821,
];

export const getActiveUsersData = () => {
  return {
    labels,
    datasets: [
      {
        label: "DAU",
        data: activeUsers.DAU,
        fill: false,
        borderColor: "red",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "MAU",
        data: activeUsers.MAU,
        fill: false,
        borderColor: "purple",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "WAU",
        data: activeUsers.WAU,
        fill: false,
        borderColor: "magenta",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
};
export const getConversionFunnelData = () => {
  return {
    labels: getConversionFunnelLabels(),
    datasets: [
      {
        label: "Email",
        data: [1274, 600, 505, 431, 383, 100],
        fill: true,
        borderColor: "red",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Scan",
        data: [2000, 1500, 1200, 372, 321, 289],
        fill: true,
        borderColor: "purple",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Message",
        data: [1224, 490, 490, 408, 300, 270],
        fill: true,
        borderColor: "magenta",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Referral",
        data: [1120, 567, 435, 400, 301, 300],
        fill: true,
        borderColor: "green",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
};

export const getUserRetentionData = () => {
  return {
    labels: userRetentionLabels,
    datasets: [
      {
        label: "1/31/2022",
        data: [1274, 640, 505, 431, 383, 337],
        fill: false,
        borderColor: "green",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "2/28/2022",
        data: [1110, 565, 437, 372, 321],
        fill: false,
        borderColor: "red",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "3/31/2022",
        data: [1224, 635, 490, 408],
        fill: false,
        borderColor: "magenta",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "4/30/2022",
        data: [1120, 567, 435],
        fill: false,
        borderColor: "grey",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "5/31/2022",
        data: [1161, 576],
        fill: false,
        borderColor: "blue",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "6/30/2022",
        data: [1003],
        fill: false,
        borderColor: "brown",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
};
export const getPointsData = () => {
  return {
    labels,
    datasets: [
      {
        label: "Points Used",
        data: points.used,
        type: "bar",
        fill: false,
        borderColor: "red",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Points Received",
        data: points.received,
        type: "line",
        fill: false,
        borderColor: "green",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
};
