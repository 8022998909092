import { stripBase64StoreCard } from "./stripBase64";
import loyoLogoImg from "./loyo-logo.png";

export const storeCardData = {
  description: "description",
  header: {
    headerFields: [
      {
        label: "Loyalty",
        value: "Card",
        key: "bonus",
        alignment: "2",
      },
    ],
  },
  body: {
    auxiliaryFields: [
      {
        label: "Name",
        value: "Luke Cole",
        key: "owner_key",
        alignment: "4",
      },
    ],
    primaryFields: [
      {
        label: "Discount",
        value: "10%",
      },
    ],
    secondaryFields: [],
  },
  barcode: {
    BarcodeFormatId: "2",
    BarcodeMessage: "lorem ipsum",
    MessageEncodingId: "2",
    UseBarcode: true,
  },
  design: {
    backgroundColor: "#fdb629",
    foregroundColor: "#000000",
    labelColor: "#000000",
    backgroundImage: "none",
    styleId: "1",
  },
  designFiles: {
    icon: {},
    logo: {
      url: loyoLogoImg,
    },
    strip: {
      url: stripBase64StoreCard,
    },
  },
};
