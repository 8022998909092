import previewImg from "./images/scan-qr.png";
import { POSPreview } from "./POSPreview";

export const ScanQrPreview: React.FC = () => {
  return (
    <POSPreview
      imageSrc={previewImg}
      paragraph="To open POS Terminal with shopper’s account selected, Cashier should
    scan the Loyalty Card QR"
    />
  );
};
