import { Box, Button } from "@mui/material";
import { IWizardService } from "../../../boundary/IWizardService";
import { getContainer } from "../../../diContainer/container";
import { useObservable } from "@roketus/web-toolkit";

export const GetPromoCode: React.FC = () => {
  const wizardService = getContainer().getDependency(
    "wizardService"
  ) as IWizardService;

  const wizardData = useObservable(wizardService.data$);

  const handleApply = () => {
    wizardService.getPromoCode();
  };

  const isPromocodeReceived =
    wizardData?.loading || wizardData?.isPromocodeReceived;

  return (
    <Box m="15px 0 10px 0">
      <Button
        onClick={handleApply}
        variant="contained"
        disabled={isPromocodeReceived}
        sx={{ mb: "5px" }}
      >
        Get Promo
      </Button>
    </Box>
  );
};
