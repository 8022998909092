import { IMessage } from "../../../boundary/IMessage";

type AnalyticMessageActionTypes = "click";

export interface AnalyticMessageEntity extends IMessage {
  type: "analyticsEvent";
  message: "";
  source: string;
  data: {
    action: AnalyticMessageActionTypes;
    section?: string;
  };
}

export const isAnalyticEvent = (
  message: any
): message is AnalyticMessageEntity => {
  return message.type === "analyticsEvent";
};

export const buildAnalyticMessageEntity = ({
  action,
  source,
  section,
}: {
  action: AnalyticMessageActionTypes;
  source: string;
  section?: string;
}): AnalyticMessageEntity => {
  let entity: AnalyticMessageEntity = {
    type: "analyticsEvent",
    message: "",
    source,
    data: {
      action,
    },
  };

  if (section) {
    entity.data.section = section;
  }

  return entity;
};
