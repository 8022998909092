import { IMessage, IMessageBus, StateSubject } from "@roketus/web-toolkit";
import { filter, map } from "rxjs";
import { CreatePassEntity } from "../../domain/entities/CreatePassEntity/CreatePassEntity";
import {
  ERROR_CODE_PASS_ENTITY_DESCRIPTION_REQUIRED,
  ERROR_CODE_PASS_ENTITY_BARCODE_ENCODING_REQUIRED,
  ERROR_CODE_PASS_ENTITY_BARCODE_FORMAT_REQUIRED,
  ERROR_CODE_PASS_ENTITY_BARCODE_MESSAGE_REQUIRED,
  ERROR_CODE_PASS_ENTITY_DESCRIPTION_TYPE,
  ERROR_CODE_PASS_ENTITY_ICON_REQUIRED,
  ERROR_CODE_PASS_ENTITY_ICON_SIZE,
  ERROR_CODE_PASS_ENTITY_ORG_NAME_REQUIRED,
} from "../../domain/specs/errorCodes";
import {
  InputElementMessage,
  isInputElementMessage,
} from "../../domain/entities/messages/formElement/InputElement/InputElementMessage";
import { getContainer } from "../../diContainer/container";
import {
  PASS_BACKGROUND_COLOR,
  PASS_DESCRIPTION,
  PASS_FOREGROUND_COLOR,
  PASS_HEADER_ALIGNMENT_1,
  PASS_HEADER_ALIGNMENT_2,
  PASS_HEADER_ALIGNMENT_3,
  PASS_HEADER_KEY_1,
  PASS_HEADER_KEY_2,
  PASS_HEADER_KEY_3,
  PASS_HEADER_LABEL_1,
  PASS_HEADER_LABEL_2,
  PASS_HEADER_LABEL_3,
  PASS_HEADER_VALUE_1,
  PASS_HEADER_VALUE_2,
  PASS_HEADER_VALUE_3,
  PASS_ICON,
  PASS_LABEL_COLOR,
  PASS_LOGO,
  PASS_LOGO_TEXT,
  PASS_ORG_NAME,
  PASS_PRIMARY_KEY,
  PASS_PRIMARY_LABEL,
  PASS_PRIMARY_VALUE,
  PASS_SECONDARY_ALIGNMENT_1,
  PASS_SECONDARY_ALIGNMENT_2,
  PASS_SECONDARY_ALIGNMENT_3,
  PASS_SECONDARY_ALIGNMENT_4,
  PASS_SECONDARY_KEY_1,
  PASS_SECONDARY_KEY_2,
  PASS_SECONDARY_KEY_3,
  PASS_SECONDARY_KEY_4,
  PASS_SECONDARY_LABEL_1,
  PASS_SECONDARY_LABEL_2,
  PASS_SECONDARY_LABEL_3,
  PASS_SECONDARY_LABEL_4,
  PASS_SECONDARY_VALUE_1,
  PASS_SECONDARY_VALUE_2,
  PASS_SECONDARY_VALUE_3,
  PASS_SECONDARY_VALUE_4,
  PASS_BARCODE_ALT_TEXT,
  PASS_BARCODE_ENCODING,
  PASS_BARCODE_FORMAT,
  PASS_BARCODE_MESSAGE,
  PASS_BARCODE_USE,
  PASS_LOCATION_LATITUDE_1,
  PASS_LOCATION_LONGITUDE_1,
  PASS_LOCATION_RELEVANT_TEXT_1,
  PASS_LOCATION_LATITUDE_2,
  PASS_LOCATION_LONGITUDE_2,
  PASS_LOCATION_RELEVANT_TEXT_2,
  PASS_LOCATION_LATITUDE_3,
  PASS_LOCATION_LONGITUDE_3,
  PASS_LOCATION_RELEVANT_TEXT_3,
  PASS_LOCATION_LATITUDE_4,
  PASS_LOCATION_LONGITUDE_4,
  PASS_LOCATION_RELEVANT_TEXT_4,
  PASS_LOCATION_LATITUDE_5,
  PASS_LOCATION_LONGITUDE_5,
  PASS_LOCATION_RELEVANT_TEXT_5,
  PASS_LOCATION_LATITUDE_6,
  PASS_LOCATION_LONGITUDE_6,
  PASS_LOCATION_RELEVANT_TEXT_6,
  PASS_LOCATION_LATITUDE_7,
  PASS_LOCATION_LONGITUDE_7,
  PASS_LOCATION_LONGITUDE_8,
  PASS_LOCATION_RELEVANT_TEXT_7,
  PASS_LOCATION_LATITUDE_8,
  PASS_LOCATION_LONGITUDE_10,
  PASS_LOCATION_LATITUDE_9,
  PASS_LOCATION_LONGITUDE_9,
  PASS_LOCATION_RELEVANT_TEXT_9,
  PASS_LOCATION_LATITUDE_10,
  PASS_LOCATION_RELEVANT_TEXT_10,
  PASS_LOCATION_RELEVANT_TEXT_8,
} from "../../domain/specs/inputData";
import { IFormData } from "../../boundary/IFormData";
import {
  ICreateTemplateService,
  ICreateTemplateServiceData,
} from "../../boundary/ICreateTemplateService";
import { ITemplateRepository } from "../../boundary/TemplateRepository/ITemplateRepository";
import { createTemplateUseCase } from "../../domain/useCases/createTemplateUseCase";
import { PositiveMessageEntity } from "../../domain/entities/messages/positiveEntity";
import { POSITIVE_TEMPLATE_CREATED } from "../../domain/specs/positiveCodes";
import {
  CheckboxMessage,
  isCheckboxMessage,
} from "../../domain/entities/messages/formElement/InputElement/checkboxMessage";
import { isArray, isEmpty } from "lodash";
import { IImageRepository } from "../../boundary/IImageRepository";

const inputs = [
  PASS_ORG_NAME,
  PASS_DESCRIPTION,
  PASS_ICON,
  PASS_LOGO,
  PASS_LOGO_TEXT,
  PASS_PRIMARY_VALUE,
  PASS_PRIMARY_KEY,
  PASS_PRIMARY_LABEL,
  PASS_HEADER_KEY_1,
  PASS_HEADER_KEY_2,
  PASS_HEADER_KEY_3,
  PASS_HEADER_LABEL_1,
  PASS_HEADER_LABEL_2,
  PASS_HEADER_LABEL_3,
  PASS_HEADER_VALUE_1,
  PASS_HEADER_VALUE_2,
  PASS_HEADER_VALUE_3,
  PASS_HEADER_ALIGNMENT_1,
  PASS_HEADER_ALIGNMENT_2,
  PASS_HEADER_ALIGNMENT_3,

  PASS_SECONDARY_LABEL_1,
  PASS_SECONDARY_VALUE_1,
  PASS_SECONDARY_KEY_1,
  PASS_SECONDARY_ALIGNMENT_1,

  PASS_SECONDARY_LABEL_2,
  PASS_SECONDARY_VALUE_2,
  PASS_SECONDARY_KEY_2,
  PASS_SECONDARY_ALIGNMENT_2,

  PASS_SECONDARY_LABEL_3,
  PASS_SECONDARY_VALUE_3,
  PASS_SECONDARY_KEY_3,
  PASS_SECONDARY_ALIGNMENT_3,

  PASS_SECONDARY_LABEL_4,
  PASS_SECONDARY_VALUE_4,
  PASS_SECONDARY_KEY_4,
  PASS_SECONDARY_ALIGNMENT_4,

  PASS_BACKGROUND_COLOR,
  PASS_FOREGROUND_COLOR,
  PASS_LABEL_COLOR,

  PASS_BARCODE_FORMAT,
  PASS_BARCODE_ENCODING,
  PASS_BARCODE_MESSAGE,
  PASS_BARCODE_ALT_TEXT,
  PASS_BARCODE_USE,

  PASS_LOCATION_LATITUDE_1,
  PASS_LOCATION_LONGITUDE_1,
  PASS_LOCATION_RELEVANT_TEXT_1,

  PASS_LOCATION_LATITUDE_2,
  PASS_LOCATION_LONGITUDE_2,
  PASS_LOCATION_RELEVANT_TEXT_2,

  PASS_LOCATION_LATITUDE_3,
  PASS_LOCATION_LONGITUDE_3,
  PASS_LOCATION_RELEVANT_TEXT_3,

  PASS_LOCATION_LATITUDE_4,
  PASS_LOCATION_LONGITUDE_4,
  PASS_LOCATION_RELEVANT_TEXT_4,

  PASS_LOCATION_LATITUDE_5,
  PASS_LOCATION_LONGITUDE_5,
  PASS_LOCATION_RELEVANT_TEXT_5,

  PASS_LOCATION_LATITUDE_6,
  PASS_LOCATION_LONGITUDE_6,
  PASS_LOCATION_RELEVANT_TEXT_6,

  PASS_LOCATION_LATITUDE_7,
  PASS_LOCATION_LONGITUDE_7,
  PASS_LOCATION_RELEVANT_TEXT_7,

  PASS_LOCATION_LATITUDE_8,
  PASS_LOCATION_LONGITUDE_8,
  PASS_LOCATION_RELEVANT_TEXT_8,

  PASS_LOCATION_LATITUDE_9,
  PASS_LOCATION_LONGITUDE_9,
  PASS_LOCATION_RELEVANT_TEXT_9,

  PASS_LOCATION_LATITUDE_10,
  PASS_LOCATION_LONGITUDE_10,
  PASS_LOCATION_RELEVANT_TEXT_10,
];

const isPassServiceMessageData = (
  value: IMessage
): value is InputElementMessage | CheckboxMessage => {
  return (
    (isInputElementMessage(value) || isCheckboxMessage(value)) &&
    inputs.includes(value.message)
  );
};

const handledFieldByErrorCode: Record<string, string> = {
  [ERROR_CODE_PASS_ENTITY_ORG_NAME_REQUIRED]: PASS_ORG_NAME,
  [ERROR_CODE_PASS_ENTITY_DESCRIPTION_REQUIRED]: PASS_DESCRIPTION,
  [ERROR_CODE_PASS_ENTITY_DESCRIPTION_TYPE]: PASS_DESCRIPTION,
  [ERROR_CODE_PASS_ENTITY_ICON_REQUIRED]: PASS_ICON,
  [ERROR_CODE_PASS_ENTITY_ICON_SIZE]: PASS_ICON,
  [ERROR_CODE_PASS_ENTITY_BARCODE_FORMAT_REQUIRED]: PASS_BARCODE_FORMAT,
  [ERROR_CODE_PASS_ENTITY_BARCODE_MESSAGE_REQUIRED]: PASS_BARCODE_MESSAGE,
  [ERROR_CODE_PASS_ENTITY_BARCODE_ENCODING_REQUIRED]: PASS_BARCODE_ENCODING,
};

export const createPassKeyByInputName: Record<string, keyof CreatePassEntity> =
  {
    [PASS_ORG_NAME]: "orgName",
    [PASS_DESCRIPTION]: "description",
    [PASS_ICON]: "iconId",
    [PASS_LOGO]: "logoId",
    [PASS_LOGO_TEXT]: "logoText",
    [PASS_PRIMARY_LABEL]: "body_primary_label",
    [PASS_PRIMARY_VALUE]: "body_primary_value",
    [PASS_PRIMARY_KEY]: "body_primary_key",
    [PASS_HEADER_KEY_1]: "header_field_1_key",
    [PASS_HEADER_KEY_2]: "header_field_2_key",
    [PASS_HEADER_KEY_3]: "header_field_3_key",
    [PASS_HEADER_LABEL_1]: "header_field_1_label",
    [PASS_HEADER_LABEL_2]: "header_field_2_label",
    [PASS_HEADER_LABEL_3]: "header_field_3_label",
    [PASS_HEADER_VALUE_1]: "header_field_1_value",
    [PASS_HEADER_VALUE_2]: "header_field_2_value",
    [PASS_HEADER_VALUE_3]: "header_field_3_value",
    [PASS_HEADER_ALIGNMENT_1]: "header_field_1_align",
    [PASS_HEADER_ALIGNMENT_2]: "header_field_2_align",
    [PASS_HEADER_ALIGNMENT_3]: "header_field_3_align",

    [PASS_SECONDARY_LABEL_1]: "body_secondary_1_label",
    [PASS_SECONDARY_VALUE_1]: "body_secondary_1_value",
    [PASS_SECONDARY_KEY_1]: "body_secondary_1_key",
    [PASS_SECONDARY_ALIGNMENT_1]: "body_secondary_1_align",

    [PASS_SECONDARY_LABEL_2]: "body_secondary_2_label",
    [PASS_SECONDARY_VALUE_2]: "body_secondary_2_value",
    [PASS_SECONDARY_KEY_2]: "body_secondary_2_key",
    [PASS_SECONDARY_ALIGNMENT_2]: "body_secondary_2_align",

    [PASS_SECONDARY_LABEL_3]: "body_secondary_3_label",
    [PASS_SECONDARY_VALUE_3]: "body_secondary_3_value",
    [PASS_SECONDARY_KEY_3]: "body_secondary_3_key",
    [PASS_SECONDARY_ALIGNMENT_3]: "body_secondary_3_align",

    [PASS_SECONDARY_LABEL_4]: "body_secondary_4_label",
    [PASS_SECONDARY_VALUE_4]: "body_secondary_4_value",
    [PASS_SECONDARY_KEY_4]: "body_secondary_4_key",
    [PASS_SECONDARY_ALIGNMENT_4]: "body_secondary_4_align",
    [PASS_BACKGROUND_COLOR]: "style_background_color",
    [PASS_FOREGROUND_COLOR]: "style_foreground_color",
    [PASS_LABEL_COLOR]: "style_label_color",

    [PASS_BARCODE_FORMAT]: "barcode_format",
    [PASS_BARCODE_ENCODING]: "barcode_encoding",
    [PASS_BARCODE_MESSAGE]: "barcode_message",
    [PASS_BARCODE_ALT_TEXT]: "barcode_alt_text",
    [PASS_BARCODE_USE]: "barcode_use",

    [PASS_LOCATION_LATITUDE_1]: "location_1_latitude",
    [PASS_LOCATION_LONGITUDE_1]: "location_1_longitude",
    [PASS_LOCATION_RELEVANT_TEXT_1]: "location_1_relevant_text",

    [PASS_LOCATION_LATITUDE_2]: "location_2_latitude",
    [PASS_LOCATION_LONGITUDE_2]: "location_2_longitude",
    [PASS_LOCATION_RELEVANT_TEXT_2]: "location_2_relevant_text",

    [PASS_LOCATION_LATITUDE_3]: "location_3_latitude",
    [PASS_LOCATION_LONGITUDE_3]: "location_3_longitude",
    [PASS_LOCATION_RELEVANT_TEXT_3]: "location_3_relevant_text",

    [PASS_LOCATION_LATITUDE_4]: "location_4_latitude",
    [PASS_LOCATION_LONGITUDE_4]: "location_4_longitude",
    [PASS_LOCATION_RELEVANT_TEXT_4]: "location_4_relevant_text",

    [PASS_LOCATION_LATITUDE_5]: "location_5_latitude",
    [PASS_LOCATION_LONGITUDE_5]: "location_5_longitude",
    [PASS_LOCATION_RELEVANT_TEXT_5]: "location_5_relevant_text",

    [PASS_LOCATION_LATITUDE_6]: "location_6_latitude",
    [PASS_LOCATION_LONGITUDE_6]: "location_6_longitude",
    [PASS_LOCATION_RELEVANT_TEXT_6]: "location_6_relevant_text",

    [PASS_LOCATION_LATITUDE_7]: "location_7_latitude",
    [PASS_LOCATION_LONGITUDE_7]: "location_7_longitude",
    [PASS_LOCATION_RELEVANT_TEXT_7]: "location_7_relevant_text",

    [PASS_LOCATION_LATITUDE_8]: "location_8_latitude",
    [PASS_LOCATION_LONGITUDE_8]: "location_8_longitude",
    [PASS_LOCATION_RELEVANT_TEXT_8]: "location_8_relevant_text",

    [PASS_LOCATION_LATITUDE_9]: "location_9_latitude",
    [PASS_LOCATION_LONGITUDE_9]: "location_9_longitude",
    [PASS_LOCATION_RELEVANT_TEXT_9]: "location_9_relevant_text",

    [PASS_LOCATION_LATITUDE_10]: "location_10_latitude",
    [PASS_LOCATION_LONGITUDE_10]: "location_10_longitude",
    [PASS_LOCATION_RELEVANT_TEXT_10]: "location_10_relevant_text",
  };

const saveTemplate = async (
  passEntity: Partial<CreatePassEntity>,
  stateMachine: StateSubject<ICreateTemplateServiceData>
) => {
  const container = getContainer();
  const templateRepository = container.getDependency(
    "templateRepository"
  ) as ITemplateRepository;
  const imageRepository = container.getDependency(
    "imageRepository"
  ) as IImageRepository;
  const messageBus = container.getDependency("messageBus") as IMessageBus;
  stateMachine.setState({
    loading: true,
    isLoaded: false,
    failed: false,
  });

  const result = await createTemplateUseCase(
    passEntity,
    templateRepository,
    imageRepository
  );

  const hasErrors = isArray(result) && !isEmpty(result);
  if (hasErrors) {
    result.forEach((message) => {
      const field = handledFieldByErrorCode[message.data.code];
      if (!field) {
        messageBus.send(message);
      }

      messageBus.send({
        type: "inputElementValidation",
        message: field,
        source: "createTemplateService",
        data: message.message,
      });
    });

    stateMachine.setState({
      loading: false,
      isLoaded: true,
      failed: true,
    });

    return;
  }
  const msg: PositiveMessageEntity = {
    type: "positiveEvent",
    message: "",
    source: "createTemplateService",
    data: POSITIVE_TEMPLATE_CREATED,
  };
  messageBus.send(msg);
  stateMachine.setState({
    loading: false,
    isLoaded: true,
    failed: false,
  });
};

export const init = (formData: IFormData): ICreateTemplateService => {
  let state: ICreateTemplateServiceData = {
    isLoaded: true,
    loading: false,
    failed: false,
    data: {},
  };
  const stateMachine = new StateSubject<ICreateTemplateServiceData>(state);

  formData
    .pipe(
      filter(isPassServiceMessageData),
      map((value) => {
        return {
          key: value.message,
          value: value.data,
        };
      })
    )
    .subscribe((v) => {
      stateMachine.setState({
        data: {
          [createPassKeyByInputName[v.key] || "nokey"]: v.value,
        },
      });
    });

  stateMachine.state$.subscribe((currentState) => {
    state = currentState;
  });

  return {
    data$: stateMachine.state$,
    submit: () => saveTemplate(state.data, stateMachine),
    saveTemplate,
  };
};
