import { Divider, Typography } from "@mui/material";
import { StepNavigation } from "../StepNavigation";
import { StepDetails } from "../StepDetails";
import { WizardHelp } from "../WizardHelp";
import { UsePromoCode } from "./UsePromoCode";

export const PromoCodeUsageStep: React.FC = () => {
  return (
    <>
      <Typography gutterBottom>
				Cashier uses promo code from shopper’s loyalty card and processes discounted amount in USD
      </Typography>
      <Divider variant="middle" flexItem sx={{ m: "20px" }} />
      <Typography gutterBottom>
        Simulate a USD100 transaction (pay with USD70 and a promo code)
      </Typography>
      <UsePromoCode />
      <StepDetails>
        <WizardHelp />
      </StepDetails>
      <StepNavigation />
    </>
  );
};
