import {
  CreateImagePayload,
  GetMediaResponseDTO,
  IImageRepository,
  ImageEntityType,
} from "../../../boundary/IImageRepository";
import { ImageEntity } from "../../../domain/entities/ImageEntity";
import { IMessageBus } from "@roketus/web-toolkit";
import { v4 } from "uuid";
import { toBase64 } from "../../../domain/utils";
import { getContainer } from "../../../diContainer/container";
import { ClientResponse, HTTPClient } from "../../../boundary/HTTPClient";

export interface CreateImageResponseDTO {
  data: {
    ImagePath: string;
    Base64Image: string;
  };
}

export class ImageRepository implements IImageRepository {
  private messageBus: IMessageBus;
  private data: ImageEntity[] = [];
  private unsavedDb: Record<
    string,
    {
      file: File;
      type: ImageEntityType;
    }
  > = {};
  constructor(messageBus: IMessageBus) {
    this.messageBus = messageBus;
  }

  async create(image: CreateImagePayload): Promise<ImageEntity> {
    const base64 = await toBase64(image.file);
    const id = v4();
    const imageEntity = {
      base64,
      id,
      type: image.type,
      isSaved: false,
    };
    this.data.push(imageEntity);
    this.unsavedDb[id] = image;
    return imageEntity;
  }

  async getById(id: string): Promise<ImageEntity | null> {
    const localImage = this.data.find((image) => id === image.id);

    if (localImage) return localImage;

    const container = getContainer();
    const client = container.getDependency(
      "privatePassServerClients"
    ) as HTTPClient;

    try {
      const result: ClientResponse<GetMediaResponseDTO> = await client.get(
        `api/media/${id}`
      );
      const image: ImageEntity = {
        base64: result.data.data.Base64Image,
        id,
        isSaved: true,
      };
      this.data.push(image);
      return image;
    } catch (e) {
      console.error("Image not found", e);
      return null;
    }
  }

  async save(id: string): Promise<ImageEntity> {
    const container = getContainer();
    const client = container.getDependency(
      "privatePassServerClients"
    ) as HTTPClient;
    const image = await this.getById(id);

    if (!image) throw new Error("Image not found");

    const imageData = this.unsavedDb[id];
    if (!imageData) throw new Error("Image data not found");

    const payload = new FormData();
    payload.append("ImageType", imageData.type);
    payload.append("Image", imageData.file);
    const { data }: ClientResponse<CreateImageResponseDTO> = await client.post(
      "api/media",
      payload
    );

    return {
      id: data.data.ImagePath,
      base64: data.data.Base64Image,
      isSaved: true,
    };
  }

  getMetaById(id: string) {
    return { file: this.unsavedDb[id]?.file } || null;
  }
}
