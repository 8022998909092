import moment from "moment";

// @TODO can move to webtoolkit
export const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const EVENT_NOTIFICATION_TIME_DIFF = 2;

export const getEventDateFromCurrent = () => {
  const eventDate = moment().add(EVENT_NOTIFICATION_TIME_DIFF, "h").format();

  return eventDate;
};
