import { IMessage } from "@roketus/web-toolkit";
import { get, isBoolean, isString } from "lodash";

export interface CheckboxMessage extends IMessage {
  type: "checkboxChanged";
  message: string; // aka input key
  source: string;
  data: boolean;
}

export const isCheckboxMessage = (value: unknown): value is CheckboxMessage => {
  const type = get(value, "type");
  const message = get(value, "message");
  const source = get(value, "source");
  const data = get(value, "data");

  return (
    type === "checkboxChanged" &&
    isString(message) &&
    isString(source) &&
    isBoolean(data)
  );
};
