import { Box, Button } from "@mui/material";
import { IWizardService } from "../../boundary/IWizardService";
import { getContainer } from "../../diContainer/container";

export const StepNavigation: React.FC = () => {
  const wizardService = getContainer().getDependency(
    "wizardService"
  ) as IWizardService;

  return (
    <Box sx={{ mb: 2 }}>
      <Button
        variant="contained"
        onClick={wizardService.proceedToNextStep}
        sx={{ mt: 1, mr: 1 }}
      >
        Next
      </Button>
      <Button onClick={wizardService.handlePreviousStep} sx={{ mt: 1, mr: 1 }}>
        Back
      </Button>
    </Box>
  );
};
