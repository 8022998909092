import { Layout } from "../Layout";
import { Box, CircularProgress, Container, Grid } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import OutputIcon from "@mui/icons-material/Output";
import RemoveIcon from "@mui/icons-material/Remove";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import WaterfallChartIcon from "@mui/icons-material/WaterfallChart";
import { getContainer } from "../../diContainer/container";
import { useObservable } from "@roketus/web-toolkit";
import { IAnalyticsService } from "../../boundary/AnalyticsService/AnalyticsService";
import AnalyticsCard from "../analytics/AnalyticsCard";
import { DatePeriodButtons } from "../analytics/DatePeriodButtons";
import { LineChart } from "../LineChart";
import { useLoadInitialDataEffect } from "../../adapters/services/analyticsService";

const colors = {
  passIssued: "#23b2e8",
  numberScans: "#009688",
  pointsReceived: "#f7b626",
  passesDeleted: "#7f4775",
  pointsUsed: "#f44336",
};

const defaultAnalyticsPeriodOffset = 30;

export const Analytics: FC = () => {
  const analyticsService = getContainer().getDependency(
    "analyticsService"
  ) as IAnalyticsService;

  const analyticsData = useObservable(analyticsService.state$);
  const analyticsWidgetsData = [
    {
      color: colors.passIssued,
      icon: React.createElement(CreateNewFolderIcon),
      title: "Passes Issued",
      count: analyticsData?.analytics.passIssued,
    },
    {
      color: colors.numberScans,
      icon: React.createElement(QrCodeScannerIcon),
      title: "Number of Scans",
      count: analyticsData?.analytics.numberScans,
    },
    {
      color: colors.pointsReceived,
      icon: React.createElement(WaterfallChartIcon),
      title: "Points Received",
      count: analyticsData?.analytics.pointsReceived,
    },
    {
      color: colors.pointsUsed,
      icon: React.createElement(OutputIcon),
      title: "Points Used",
      count: analyticsData?.analytics.pointsUsed,
    },
    {
      color: colors.passesDeleted,
      icon: React.createElement(RemoveIcon),
      title: "Passes Deleted",
      count: analyticsData?.analytics.passesDeleted,
    },
  ];

  const [dateRange, setDateRange] = useState<number>(
    defaultAnalyticsPeriodOffset
  );

  const handleDateRangeChange = (
    event: React.MouseEvent<HTMLElement>,
    buttonValue: number
  ) => {
    if (buttonValue === null) return;
    setDateRange(buttonValue);
  };
  useEffect(() => {
    // const params = analyticsService.getDateRangeFromStamp(dateRange);
    analyticsService.getWidgetsData(dateRange);
    // analyticsService.getAnalytics(params); @todo return when real API
  }, [analyticsService, dateRange]);

  // @todo remove when real API
  useLoadInitialDataEffect();

  return (
    <Layout pageTitle="Analytics">
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "right",
              mb: 6,
            }}
          >
            <DatePeriodButtons
              dateRange={dateRange}
              handleDateRangeChange={handleDateRangeChange}
            />
          </Grid>
        </Grid>
        {analyticsData?.loading ? (
          <CircularProgress />
        ) : (
          analyticsData?.isLoaded && (
            <Grid container spacing={2} justifyContent="space-between">
              {analyticsWidgetsData.map(({ color, icon, title, count }) => (
                <Grid item xs={12} md={6} lg={"auto"} key={title}>
                  <Box mb={1.5}>
                    <AnalyticsCard
                      color={color}
                      icon={icon}
                      title={title}
                      count={count}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          )
        )}

        {analyticsData && (
          <Grid container spacing={3}>
            {analyticsData.charts.activeUsersData && (
              <Grid item xs={10} md={10} lg={6}>
                <Grid container spacing={3}>
                  <Grid item xs={10} md={10} lg={10}>
                    <h2>Active Users</h2>
                  </Grid>
                </Grid>
                <LineChart
                  dateRange={dateRange}
                  data={analyticsData.charts.activeUsersData}
                />
              </Grid>
            )}

            {analyticsData.charts.conversionFunnelData && (
              <Grid item xs={10} md={10} lg={6}>
                <Grid container spacing={3}>
                  <Grid item xs={10} md={10} lg={10}>
                    <h2>Conversion Funnel</h2>
                  </Grid>
                </Grid>
                <LineChart
                  dateRange={dateRange}
                  options={{
                    scales: {
                      y: {
                        stacked: true,
                      },
                    },
                  }}
                  data={analyticsData.charts.conversionFunnelData}
                />
              </Grid>
            )}

            {analyticsData.charts.userRetentionData && (
              <Grid item xs={10} md={10} lg={6}>
                <Grid container spacing={3}>
                  <Grid item xs={10} md={10} lg={10}>
                    <h2>User Retention Monthly</h2>
                  </Grid>
                </Grid>
                <LineChart
                  dateRange={dateRange}
                  data={analyticsData.charts.userRetentionData}
                />
              </Grid>
            )}

            {analyticsData.charts.pointsData && (
              <Grid item xs={10} md={10} lg={6}>
                <Grid container spacing={3}>
                  <Grid item xs={10} md={10} lg={10}>
                    <h2>Points Received/Used Cumulative</h2>
                  </Grid>
                </Grid>
                <LineChart
                  dateRange={dateRange}
                  data={analyticsData.charts.pointsData}
                />
              </Grid>
            )}
          </Grid>
        )}
      </Container>
    </Layout>
  );
};
