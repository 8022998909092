import { Box, styled } from "@mui/material";
import { FC } from "react";

const BgContainer = styled(Box)<{
  color?: string;
  bgColor?: string;
  bgImageUrl?: string;
}>`
  width: 300px;
  height: 430px;
  border-radius: 12px;
  box-sizing: border-box;
  box-shadow: 0 4px 5px -2px rgb(0 0 0 / 20%), 0 7px 10px 1px rgb(0 0 0 / 14%),
    0 2px 16px 1px rgb(0 0 0 / 12%);
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};
  background-image: url(${(props) => props.bgImageUrl});
  background-repeat: no-repeat;
  background-size: cover;
`;

const Container = styled(Box)<{
  bgImageUrl?: string;
  bgSize?: string;
  bgPosition?: string;
}>`
  width: 100%;
  height: 100%;
  padding: 10px;
  padding-top: 0;
  display: flex;
  border-radius: 12px;
  background-image: url(${(props) => props.bgImageUrl});
  background-repeat: no-repeat;
  background-position: ${(props) => props.bgPosition || "top 50px right 0"};
  background-size: ${(props) => props.bgSize || "contain"};
  backdrop-filter: blur(8px);
`;

export const PassContainer: FC<{
  bgColor?: string;
  color?: string;
  bgImageUrl?: string;
  stripBase64?: string;
  stripStyles?: Record<string, string>;
}> = ({
  bgColor = "white",
  color = "black",
  bgImageUrl,
  stripBase64,
  stripStyles,
  children,
}) => {
  return (
    <BgContainer color={color} bgColor={bgColor} bgImageUrl={bgImageUrl}>
      <Container
        bgImageUrl={stripBase64}
        bgSize={stripStyles?.backgroundSize}
        bgPosition={stripStyles?.backgroundPosition}
      >
        {children}
      </Container>
    </BgContainer>
  );
};
