import { createGenericPassEntityFromCreatePassEntity } from "../entities/GenericPassEntity/GenericPassEntity";
import { createErrorMessage, ErrorMessage } from "@roketus/web-toolkit";
import {
  ERROR_CODE_TEMPLATE_FAILED_TO_CREATE,
  ERROR_CODE_UNKNOWN_ERROR,
} from "../specs/errorCodes";
import {
  CreatePassEntity,
  UnverifiedCreatePassEntity,
} from "../entities/CreatePassEntity/CreatePassEntity";
import { validateCreatePassEntity } from "../entities/CreatePassEntity/createPassEntityValidators";
import { ITemplateRepository } from "../../boundary/TemplateRepository/ITemplateRepository";
import { IImageRepository } from "../../boundary/IImageRepository";
import { createImagesUseCase } from "./createImagesUseCase/createImagesUseCase";

export const createTemplateUseCase = async (
  data: UnverifiedCreatePassEntity,
  templateRepository: ITemplateRepository,
  imageRepository: IImageRepository
): Promise<void | ErrorMessage[]> => {
  const errors = await validateCreatePassEntity(data, imageRepository);

  if (errors.length > 0) {
    return errors;
  }

  // since validation passed we treated data as CreatePassEntity
  const template = data as CreatePassEntity;
  try {
    const templateWithUploadedImages = await createImagesUseCase(
      template,
      imageRepository
    );
    const validEntity = await createGenericPassEntityFromCreatePassEntity(
      templateWithUploadedImages
    );

    try {
      await templateRepository.create(validEntity);
    } catch (err) {
      return [
        createErrorMessage({
          source: "templateRepository",
          message: "server error",
          error: err as Error,
          code: ERROR_CODE_TEMPLATE_FAILED_TO_CREATE,
        }),
      ];
    }
  } catch (err) {
    return [
      createErrorMessage({
        source: "createImagesUseCase",
        message: "Unknown error",
        error: err as Error,
        code: ERROR_CODE_UNKNOWN_ERROR,
      }),
    ];
  }
};
