import { FC } from "react";
import { CouponPassFront } from "./components/CouponPassFront";
import { IBarcodeTypeIOS } from "./sharedInterfaces";
import { PassField } from "./CreatePassEntity";

interface IProps {
  passBgColor?: string;
  passFgColor?: string;
  passLabelColor?: string;
  passBgImage?: string;
  logoText?: string;
  logoTextAlign?: "center" | "left";
  bodyPrimaryLabel?: string;
  bodyPrimaryValue?: string;
  bodyPrimaryStyleId?: string;
  barcode?: {
    text: string;
    type: IBarcodeTypeIOS;
  };
  headerFields: PassField[];
  secondaryFields: PassField[];
  auxiliaryFields: PassField[];
  stripBase64?: string;
  stripStyles?: Record<string, string>;
  thumbnailBase64?: string;
  logo: string;
}

export const CardTemplatePreview: FC<IProps> = ({
  passBgColor,
  passFgColor,
  passLabelColor,
  passBgImage,
  logoText,
  logoTextAlign,
  bodyPrimaryLabel,
  bodyPrimaryValue,
  bodyPrimaryStyleId,
  barcode,
  headerFields,
  secondaryFields,
  auxiliaryFields,
  stripBase64,
  stripStyles,
  thumbnailBase64,
  logo,
}) => {
  return (
    <CouponPassFront
      passBgColor={passBgColor}
      passFgColor={passFgColor}
      passLabelColor={passLabelColor}
      passBgImage={passBgImage}
      logoText={logoText}
      logoTextAlign={logoTextAlign}
      bodyPrimaryLabel={bodyPrimaryLabel}
      bodyPrimaryValue={bodyPrimaryValue}
      bodyPrimaryStyleId={bodyPrimaryStyleId}
      barcode={barcode}
      headerFields={headerFields}
      secondaryFields={secondaryFields}
      auxiliaryFields={auxiliaryFields}
      stripBase64={stripBase64}
      stripStyles={stripStyles}
      thumbnailBase64={thumbnailBase64}
      logo={logo}
    />
  );
};
