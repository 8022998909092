import { GetPromoCodeDTO } from "../../../boundary/PassRepository/PassRepositoryDTO";

export const convertToUsePromoCodeDTO = (
  passId: string
): GetPromoCodeDTO => {
  return {
    PassInfo: {
      PassInfoId: parseInt(passId, 10),
    },
    PassBack: [
      {
        Value: "Promo code was used",
        Key: "notification",
        Label: "Notification",
        ChangeMessage: "%@",
      },
      {
        Value: "",
        Key: "promo",
        Label: "Promo code",
        // AlignmentId: 1,
        ChangeMessage: "%@",
      },
    ],
  };
};
