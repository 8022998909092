import {
  createErrorMessage,
  ErrorMessageBus,
  IMessageBus,
  isErrorMessage,
  LoadableData,
  StateSubject,
} from "@roketus/web-toolkit";
import { sdk } from "@roketus/loyalty-js-sdk";
import { getContainer } from "../../diContainer/container";
import { ITemplateRepository } from "../../boundary/TemplateRepository/ITemplateRepository";
import { PagingData } from "../../boundary/PagingData";
import { TemplateEntity } from "../../domain/entities/TemplateEntity";
import { IListTemplateService } from "../../boundary/IListTemplateService";
import {
  ERROR_CODE_TEMPLATE_FAILED_TO_UPDATE,
  ERROR_CODE_UNKNOWN_ERROR,
} from "../../domain/specs/errorCodes";
import { IProfileService } from "../../boundary/ProfileService/ProfileService";
import { convertToUpdateRelevantDateDTO } from "../data/GenericPassEntity/convertToUpdateTemplateDTO";
import { UpdateTemplateDTO } from "../../boundary/TemplateRepository/TemplateRepositoryDTO";
import { POSITIVE_TEMPLATE_UPDATED } from "../../domain/specs/positiveCodes";
import { PositiveMessageEntity } from "../../domain/entities/messages/positiveEntity";

export const init = (repository: ITemplateRepository): IListTemplateService => {
  const stateMachine = new StateSubject<
    LoadableData & {
      templates: TemplateEntity[];
      pagingData: PagingData;
    }
  >({
    loading: false,
    templates: [],
    pagingData: {
      page: 1,
      pageSize: 10,
      totalData: 0,
      totalPages: 0,
    },
    isLoaded: false,
    failed: false,
  });

  const load: IListTemplateService["load"] = async ({ page }) => {
    const messageBus = getContainer().getDependency(
      "messageBus"
    ) as IMessageBus;

    stateMachine.setState({ loading: true });
    try {
      const result = await repository.list({ page });

      if (isErrorMessage(result)) {
        messageBus.send(result);
        stateMachine.setState({ failed: true, isLoaded: true, loading: false });
      } else {
        const { templates, pagingData } = result;
        stateMachine.setState({
          loading: false,
          isLoaded: true,
          templates,
          pagingData,
        });
      }
    } catch (e) {
      messageBus.send(
        createErrorMessage({
          message: "Error loading templates",
          error: e as Error,
          source: "IListTemplatesService",
        })
      );

      stateMachine.setState({
        loading: false,
        failed: true,
      });
    }
  };

  const setDefaultTemplate = async (templateId: string) => {
    const container = getContainer();

    const messageBus = container.getDependency("messageBus") as IMessageBus;

    const profileService = container.getDependency(
      "profileService"
    ) as IProfileService;

    stateMachine.setState({ loading: true });

    try {
      const result = await sdk.issuer.setSettings({
        templateId: parseInt(templateId, 10),
      });

      if (isErrorMessage(result)) {
        messageBus.send(result);
      }

      profileService.setDefaultTemplateIdToStorage(templateId.toString());

      stateMachine.setState({ failed: true, isLoaded: true, loading: false });
    } catch (e) {
      messageBus.send(
        createErrorMessage({
          message: "Error set default template",
          error: e as Error,
          source: "IListTemplatesService",
        })
      );

      stateMachine.setState({
        loading: false,
        failed: true,
      });
    }
  };

  const updateTemplateRelevantDate = async (id: string): Promise<void> => {
    const container = getContainer();
    const templateRepository = container.getDependency(
      "templateRepository"
    ) as ITemplateRepository;
    const messageBus = container.getDependency("messageBus") as IMessageBus;
    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
    });

    try {
      const payload: UpdateTemplateDTO = convertToUpdateRelevantDateDTO(id);

      await templateRepository.update(payload);

      const msg: PositiveMessageEntity = {
        source: "listTemplateService",
        type: "positiveEvent",
        message: "",
        data: POSITIVE_TEMPLATE_UPDATED,
      };
      messageBus.send(msg);
      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: false,
      });
    } catch (err) {
      messageBus.send(
        createErrorMessage({
          source: "listTemplateService",
          message: "server error",
          error: err as Error,
          code: ERROR_CODE_TEMPLATE_FAILED_TO_UPDATE,
        })
      );

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });
    }
  };

  const deleteTemplate = async (id: string): Promise<void> => {
    try {
      stateMachine.setState({
        loading: true,
        isLoaded: false,
      });
      await repository.delete(id);
      stateMachine.setState({
        loading: false,
        isLoaded: true,
      });
    } catch (e) {
      const messageBus = getContainer().getDependency(
        "errorMessageBus"
      ) as ErrorMessageBus;
      const errorMessage = createErrorMessage({
        code: ERROR_CODE_UNKNOWN_ERROR,
        source: "listTemplatesService",
        message: "Failed To delete template",
        error: e as Error,
      });
      messageBus.send(errorMessage);

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });
    }
  };

  return {
    state$: stateMachine.state$,
    load,
    updateTemplateRelevantDate,
    setDefaultTemplate,
    delete: deleteTemplate,
  };
};
