import React, { FC } from "react";
import { Box, Grid } from "@mui/material";

import { CardBarcode } from "./CardBarcode/CardBarcode";

import { PassFront } from "./PassFront";
import {
  PrimaryFieldsSection,
  PrimaryFieldsSectionInner,
} from "./PrimaryFieldsSection";
import styled from "@emotion/styled";
import { CouponTextBlock } from "./CouponTextBlock";
import { IBarcodeTypeIOS } from "../sharedInterfaces";
import { PassField } from "../CreatePassEntity";

interface IProps {
  passBgColor?: string;
  passFgColor?: string;
  passLabelColor?: string;
  passBgImage?: string;
  logoText?: string;
  logoTextAlign?: "center" | "left";
  bodyPrimaryLabel?: string;
  bodyPrimaryValue?: string;
  bodyPrimaryStyleId?: string;
  barcode?: {
    text: string;
    type: IBarcodeTypeIOS;
  };
  headerFields: PassField[];
  secondaryFields: PassField[];
  auxiliaryFields: PassField[];
  stripBase64?: string;
  stripStyles?: Record<string, string>;
  description?: string;
  thumbnailBase64?: string;
  logo: string;
}

export const PrimaryLabel = styled(Box)<{ color?: string; size?: number }>`
  text-align: inherit;
  color: ${(props) => props.color};
  font-size: ${(props) => props.size || 13}px;
  font-weight: 300;
  text-transform: none;
  line-height: 1;
`;

export const PrimaryValue = styled(PrimaryLabel)<{
  color?: string;
  size?: number;
}>`
  margin-top: 2px;
  font-size: ${(props) => props.size || 49}px;
  overflow: hidden;
  font-weight: 300;
  text-transform: uppercase;
`;

const Img = styled.img`
  display: block;
  height: 100px;
  width: 100px;
  padding-top: 13px;
`;

export const CouponPassFront: FC<IProps> = ({
  passBgColor,
  passFgColor,
  passLabelColor,
  passBgImage,
  logoText,
  logoTextAlign,
  bodyPrimaryLabel,
  bodyPrimaryValue,
  bodyPrimaryStyleId,
  barcode,
  headerFields,
  secondaryFields,
  auxiliaryFields,
  stripBase64,
  stripStyles,
  thumbnailBase64,
  logo,
}) => {
  const primaryColor =
    !!stripBase64 && bodyPrimaryStyleId === "1" ? "white" : passFgColor;
  const primaryLabelSize = bodyPrimaryStyleId === "2" ? 13 : 13;
  const primaryValueSize = bodyPrimaryStyleId === "2" ? 18 : 49;
  const primaryTextOrder =
    bodyPrimaryStyleId === "2" ? "column-reverse" : "column";

  return (
    <PassFront
      headerFields={headerFields}
      logoText={logoText}
      logoTextAlign={logoTextAlign}
      passLabelColor={passLabelColor}
      passFgColor={passFgColor}
      passBgColor={passBgColor}
      passBgImage={passBgImage}
      footer={
        barcode && <CardBarcode text={barcode.text} type={barcode.type} />
      }
      stripBase64={stripBase64}
      stripStyles={stripStyles}
      logo={logo}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <PrimaryFieldsSection>
            <PrimaryFieldsSectionInner order={primaryTextOrder}>
              <PrimaryValue color={primaryColor} size={primaryValueSize}>
                {bodyPrimaryValue}
              </PrimaryValue>
              <PrimaryLabel color={primaryColor} size={primaryLabelSize}>
                {bodyPrimaryLabel}
              </PrimaryLabel>
            </PrimaryFieldsSectionInner>
          </PrimaryFieldsSection>
        </Grid>
        {thumbnailBase64 && (
          <Grid item>
            <Img src={thumbnailBase64} />
          </Grid>
        )}
      </Grid>
      <CouponTextBlock
        labelColor={passLabelColor}
        valueColor={passFgColor}
        secondary={secondaryFields}
        auxiliary={auxiliaryFields}
      />
    </PassFront>
  );
};
