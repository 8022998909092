import { useState, FC, ChangeEvent, Dispatch } from "react";
import styles from "./ContactForm.module.css";
import { Button } from "@mui/material";
import { getContainer } from "../../../diContainer/container";
import { IWizardService } from "../../../boundary/IWizardService";

export const ContactForm: FC<{ onClose?: () => void }> = ({
  onClose = () => null,
}) => {
  const wizardService = getContainer().getDependency(
    "wizardService"
  ) as IWizardService;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const cleanUpForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    onClose();
  };

  const setValue = (
    e: ChangeEvent<HTMLInputElement>,
    setState: Dispatch<React.SetStateAction<string>>
  ) => setState(e.target.value);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    wizardService.submitFeedbackForm({ name, email, phone }, cleanUpForm);
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <label className={styles.form__label} data-title="Name">
        <input
          type="text"
          name="name"
          value={name}
          placeholder="Enter your name"
          onChange={(e) => setValue(e, setName)}
          ref={null}
        />
      </label>
      <label className={styles.form__label} data-title="Email *">
        <input
          type="email"
          name="email"
          value={email}
          placeholder="Enter your email"
          required
          onChange={(e) => setValue(e, setEmail)}
          ref={null}
        />
      </label>
      <label className={styles.form__label} data-title="Phone *">
        <input
          type="text"
          name="phone"
          value={phone}
          placeholder="Enter your phone number"
          required
          onChange={(e) => setValue(e, setPhone)}
          ref={null}
        />
      </label>
      <div className={styles.form__item}>
        <Button variant="contained" type="submit">
          Book
        </Button>
      </div>
    </form>
  );
};
