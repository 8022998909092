import { stripBase64EventTicket } from "./stripBase64";
import flashSaleLogoImg from "./flash-sale-logo.png";

export const eventTicketData = {
  description: "description",
  header: {
    headerFields: [],
  },
  body: {
    primaryFields: [
      {
        label: "Visit",
        value: "Sale Event",
        alignment: "1",
      },
    ],
    secondaryFields: [],
    auxiliaryFields: [],
  },
  barcode: {
    BarcodeFormatId: "2",
    BarcodeMessage: "lorem ipsum",
    MessageEncodingId: "2",
    UseBarcode: true,
  },
  design: {
    backgroundColor: "#84CCB7",
    foregroundColor: "#000000",
    labelColor: "#000000",
    backgroundImage: "./event-ticket-bg.png",
    styleId: "2",
  },
  designFiles: {
    icon: {},
    logo: {
      url: flashSaleLogoImg,
    },
    strip: {
      url: stripBase64EventTicket,
			backgroundPosition: "top 50px right 20px",
			backgroundSize: "100px 100px",
    },
  },
};
