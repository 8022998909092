import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";

interface IPOSPreviewProps {
  imageSrc: string;
  paragraph: string;
  paragraphPlacement?: "top" | "bottom";
}

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ImgWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;
  flex-grow: 1;
`;

const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: auto;
`;

export const POSPreview: React.FC<IPOSPreviewProps> = ({
  imageSrc,
  paragraph,
  paragraphPlacement = "top",
}) => {
  return (
    <Container>
      {paragraphPlacement === "top" && (
        <Typography sx={{ mb: "20px" }}>{paragraph}</Typography>
      )}
      <ImgWrapper>
        <Img src={imageSrc} alt="Demo image" />
      </ImgWrapper>
      {paragraphPlacement === "bottom" && (
        <Typography sx={{ mt: "20px" }}>{paragraph}</Typography>
      )}
    </Container>
  );
};
