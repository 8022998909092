import { FC } from "react";
import { Box, Button, Modal } from "@mui/material";
import { PreviewSection } from "../PreviewSection/PreviewSection";
import { getContainer } from "../../diContainer/container";
import { IWizardService } from "../../boundary/IWizardService";
import { useObservable } from "@roketus/web-toolkit";
import { ContactForm } from "../PreviewSection/ContactFrom/ContactForm";

interface props {
  open: boolean;
  onClose: () => void;
}

const containerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const style = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
  maxWidth: "400px",
  background: "#fff",
  padding: "20px",
  borderRadius: "5px",
  margin: "0 7px",
};

export const PreviewTemplateModal: FC<props> = ({ open, onClose }) => {
  const wizardService = getContainer().getDependency(
    "wizardService"
  ) as IWizardService;

  const wizardData = useObservable(wizardService.data$);
  const activeStep = wizardData?.activeStep;

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={containerStyle}
    >
      <Box sx={style}>
        {activeStep === 8 ? (
          <ContactForm onClose={onClose} />
        ) : (
          <PreviewSection />
        )}
        <Button sx={{ mt: 2 }} onClick={onClose} variant="outlined">
          Close
        </Button>
      </Box>
    </Modal>
  );
};
