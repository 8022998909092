import { Typography } from "@mui/material";
import { StepDetails } from "../StepDetails";
import { WizardHelp } from "../WizardHelp";
import { StepNavigation } from "../StepNavigation";

export const RelevantTimeStep: React.FC = () => {
  return (
    <>
      <Typography gutterBottom>
				Displays a shortcut to your pass on a user’s lock screen at a certain date and time.
      </Typography>
      <StepDetails>
        <WizardHelp />
      </StepDetails>
      <StepNavigation />
    </>
  );
};
