import { Box, Button, Tooltip } from "@mui/material";
import { IWizardService } from "../../../boundary/IWizardService";
import { getContainer } from "../../../diContainer/container";
import { useObservable } from "@roketus/web-toolkit";

export const UsePromoCode: React.FC = () => {
  const wizardService = getContainer().getDependency(
    "wizardService"
  ) as IWizardService;

  const wizardData = useObservable(wizardService.data$);

  const handleApply = () => {
    wizardService.usePromoCode();
  };

  const isDisabled = wizardData?.loading || wizardData?.isPromocodeUsed || !wizardData?.isPromocodeReceived;

	const tooltip = !wizardData?.isPromocodeReceived
		? "Please get the promo code in Promo code step"
		: "";

  return (
    <Box m="15px 0 10px 0">
			<Tooltip title={tooltip}>
				<span>
					<Button
						onClick={handleApply}
						variant="contained"
						disabled={isDisabled}
						sx={{ mb: "5px" }}
					>
						Execute
					</Button>
				</span>
			</Tooltip>
    </Box>
  );
};
