import { TemplateEntity } from "../../../domain/entities/TemplateEntity";
import { ErrorMessage } from "@roketus/web-toolkit";
import { GetTemplateResponseDTO } from "../../../boundary/TemplateRepository/GetTemplateResponseDTO";
import { head, isEmpty, isUndefined } from "lodash";
import { PartialPassBarcode } from "../../../boundary/PartialPassDTO";
import {
  convertBackTextField,
  convertBarcode,
  convertLocations,
  convertTextField,
} from "../TemplateListDTO/convertToTemplateEntity";
import { TemplateItem } from "../../../boundary/TemplateRepository/TemplateItem";
import { PassTypeEntity } from "../../../domain/entities/CreatePassEntity/CreatePassEntity";
import {
  defaultBackgroundColor,
  defaultForegroundColor,
  defaultLabelColor,
} from "../../../domain/specs/defaultValues";

const passTypeEntityByStyleID: Record<number, PassTypeEntity> = {
  1: PassTypeEntity.BoardingPass,
  2: PassTypeEntity.Coupon,
  3: PassTypeEntity.EventTicket,
  4: PassTypeEntity.Generic,
  5: PassTypeEntity.StoreCard,
};

export const convertStyleIDtoPassTypeEntity = (
  styleID: number
): PassTypeEntity => {
  const passType = passTypeEntityByStyleID[styleID];

  if (isUndefined(passType)) {
    throw new Error(`Unknown styleID: ${styleID}`);
  }
  return passType;
};

export const convertTemplateItemToEntity = (
  template: TemplateItem
): TemplateEntity => {
  const {
    PassInfo,
    PassStyle,
    PassPrimary,
    PassSecondary,
    PassAuxiliary,
    PassBack,
    PassHeader,
    PassBarcode,
    PassLocation,
  } = template;
  const {
    OrganizationName,
    Description,
    ExpirationDate,
    RelevantDate,
    LogoText,
    PassInfoId,
  } = PassInfo;
  const { IconUrl, LogoUrl, Label, Background, Foreground } = PassStyle;

  const primaryField = head(PassPrimary);

  const convertedTemplate: TemplateEntity = {
    orgName: OrganizationName,
    id: PassInfoId.toString(10),
    description: Description,
    expirationDate: ExpirationDate,
    relevantDate: RelevantDate,
    iconId: IconUrl,
    type: convertStyleIDtoPassTypeEntity(PassInfo.StyleId ?? 4),
    design: {
      backgroundColor: Background ?? defaultBackgroundColor,
      foregroundColor: Foreground ?? defaultForegroundColor,
      labelColor: Label ?? defaultLabelColor,
      backgroundId: PassStyle.BackgroundUrl,
      stripId: PassStyle.StripUrl,
      thumbnailId: PassStyle.ThumbnailUrl,
    },
    header: {
      headerFields: PassHeader.map(convertTextField),
      logoId: LogoUrl ?? undefined,
      logoText: LogoText ?? undefined,
    },
    body: {
      secondaryFields: PassSecondary.map(convertTextField),
      auxiliaryFields: PassAuxiliary.map(convertTextField),
      passBack: PassBack.map(convertBackTextField),
    },
  };

  if (!isUndefined(primaryField)) {
    convertedTemplate.body.primaryFields = convertTextField(primaryField);
  }

  if (!isEmpty(PassBarcode)) {
    const barcode = PassBarcode as PartialPassBarcode;
    convertedTemplate.barcode = convertBarcode(barcode);
  }

  convertedTemplate.location = !isEmpty(PassLocation)
    ? convertLocations(PassLocation)
    : [];

  return convertedTemplate;
};

export const convertGetTemplateResponseDTOToTemplateEntity = (
  payload: GetTemplateResponseDTO
): TemplateEntity | ErrorMessage => {
  return convertTemplateItemToEntity(payload.data);
};
