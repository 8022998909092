import previewImg from "./images/get-promo-code.png";
import { POSPreview } from "./POSPreview";

export const PromoCodePreview: React.FC = () => {
  return (
    <POSPreview
      paragraph="You can set up your own loyalty rules with our system, for example, a Promo code when the user installs your digital card"
      imageSrc={previewImg}
      paragraphPlacement="bottom"
    />
  );
};
