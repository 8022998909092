import { FC } from "react";

interface IProps {
	width?: number | string;
	height?: number | string;
}

const PlaneSvg: FC<IProps> = ({
	width = 288,
	height = 139,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 288 139"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M286.499 1L253.407 57.1038L238.668 45.4777L229.92 53.7756L224.69 34.4721L208.714 21.9201C210.084 20.9311 261.141 7.56129 286.499 1Z"
        fill="url(#paint0_linear_0_1)"
      />
      <path
        d="M253.407 57.1038L286.499 1M253.407 57.1038L244.659 29.5273M253.407 57.1038L238.668 45.4777M286.499 1L244.659 29.5273M286.499 1C261.141 7.56129 210.084 20.9311 208.714 21.9201M286.499 1L240.094 26.6746M244.659 29.5273L242.544 31.1439M231.346 39.7021L238.668 45.4777M231.346 39.7021L229.92 53.7756M231.346 39.7021L242.544 31.1439M238.668 45.4777L229.92 53.7756M229.92 53.7756L224.69 34.4721M208.714 21.9201L224.69 34.4721M208.714 21.9201L240.094 26.6746M224.69 34.4721L240.094 26.6746M240.094 26.6746L242.544 31.1439"
        stroke="white"
        strokeWidth="0.946845"
      />
      <path
        d="M228.267 138.04C150.017 137.413 -46.7698 -31.3409 -15.032 82.9656C9.82974 172.508 144.435 101.917 223.698 53.3139"
        stroke="url(#paint1_linear_0_1)"
        strokeWidth="1.89369"
        strokeDasharray="7.57 7.57"
      />
      <defs>
        <linearGradient
          id="paint0_linear_0_1"
          x1="236.456"
          y1="57.1037"
          x2="252.738"
          y2="-0.695818"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#059CC1" />
          <stop offset="0.551428" stopColor="#0E8A88" />
          <stop offset="0.775714" stopColor="#3EA440" />
          <stop offset="1" stopColor="#D59D10" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_0_1"
          x1="-19.7788"
          y1="78.2425"
          x2="231.339"
          y2="97.0768"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D59D10" />
          <stop offset="1" stopColor="#0A93A4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PlaneSvg;
