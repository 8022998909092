import {
  createErrorMessage,
  ErrorMessageBus,
  StateSubject,
} from "@roketus/web-toolkit";
import { getContainer } from "../../diContainer/container";
import { ClientResponse, HTTPClient } from "../../boundary/HTTPClient";
import { ERROR_CODE_GET_ANALYTICS_FAILED } from "../../domain/specs/errorCodes";
import {
  IAnalyticsService,
  IAnalyticsServiceData,
} from "../../boundary/AnalyticsService/AnalyticsService";
import { GetAnalyticsResponseDTO } from "../../boundary/AnalyticsService/AnalyticsServiceDTOs";
import {
  getConversionFunnelData,
  passesIssued,
  pointsReceived,
  pointsUsed,
  passesDeleted,
  numberScans,
  getActiveUsersData,
  getPointsData,
  getUserRetentionData,
} from "./analyticsService/analyticsDataTemp";
import { useEffect } from "react";

const UtcNow = () => {
  const now = new Date();
  return new Date(
    Date.UTC(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      now.getMinutes()
    )
  );
};

const getWidgetsData = (days: number) => {
  stateMachine.setState({
    isLoaded: true,
    analytics: {
      passIssued: getSumByPeriod(passesIssued, days),
      numberScans: getSumByPeriod(numberScans, days),
      pointsReceived: getSumByPeriod(pointsReceived, days),
      passesDeleted: getSumByPeriod(passesDeleted, days),
      pointsUsed: getSumByPeriod(pointsUsed, days),
    },
  });
};

const stateMachine = new StateSubject<IAnalyticsServiceData>({
  loading: true,
  isLoaded: false,
  failed: false,
  analytics: {
    passIssued: 0,
    numberScans: 0,
    pointsReceived: 0,
    passesDeleted: 0,
    pointsUsed: 0,
  },
  charts: {
    activeUsersData: null,
    userRetentionData: null,
    pointsData: null,
    conversionFunnelData: null,
  },
});

const getDateRangeFromStamp = (days: number) => {
  const endDate = UtcNow();
  const startDate = UtcNow();
  startDate.setDate(startDate.getDate() - days);
  return {
    from: startDate,
    to: endDate,
  };
};

const getSumByPeriod = (data: number[], days: number) => {
  const sum = data.slice(days * -1).reduce((a, b) => a + b);
  return Math.round(sum);
};

const getAnalytics = (params: { from: Date; to: Date }) => {
  const container = getContainer();
  const client = container.getDependency(
    "privatePassServerClients"
  ) as HTTPClient;

  stateMachine.setState({ loading: true });

  client
    .get("api/v2/analytics/passes", { params })
    .then((data: ClientResponse<GetAnalyticsResponseDTO>) => {
      stateMachine.setState({
        // analytics: data.data.data,  //fix when use real API
        failed: false,
        isLoaded: true,
        loading: false,
      });
    })
    .catch((e: unknown) => {
      stateMachine.setState({
        failed: true,
        isLoaded: false,
        loading: false,
      });
      const messageBus = container.getDependency(
        "errorMessageBus"
      ) as ErrorMessageBus;
      const errorMessage = createErrorMessage({
        code: ERROR_CODE_GET_ANALYTICS_FAILED,
        source: "analyticsService",
        message: "Failed To Get Analytics",
        error: e as Error,
      });
      messageBus.send(errorMessage);
    });
};

export const init = (): IAnalyticsService => {
  return {
    getAnalytics,
    getDateRangeFromStamp,
    getWidgetsData,
    state$: stateMachine.state$,
  };
};

export const useLoadInitialDataEffect = () => {
  useEffect(() => {
    stateMachine.setState({
      loading: false,
      isLoaded: true,
      charts: {
        activeUsersData: getActiveUsersData(),
        userRetentionData: getUserRetentionData(),
        pointsData: getPointsData(),
        conversionFunnelData: getConversionFunnelData(),
      },
    });
  }, []);
};
