import { filter, lastValueFrom, take } from "rxjs";
import { isEmpty, last } from "lodash";
import {
  ErrorMessageBus,
  IMessageBus,
  LoadableData,
  StateSubject,
  createErrorMessage,
} from "@roketus/web-toolkit";
import { sdk } from "@roketus/loyalty-js-sdk";
import { IBalanceInfo, ICardService } from "../../boundary/ICardService";
import { getContainer } from "../../diContainer/container";
import { IProfileService } from "../../boundary/ProfileService/ProfileService";
import { PositiveMessageEntity } from "../../domain/entities/messages/positiveEntity";
import { BONUS_SUBMITTED } from "../../domain/specs/positiveCodes";
import { AN_DEMO_CARD_DOWNLOADED_BY_QR } from "../../domain/specs/userAnalyticsCode";
import { isOS } from "../OS/isOS";

async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const init = (messageBus: IMessageBus): ICardService => {
  const stateMachine = new StateSubject<LoadableData & IBalanceInfo>({
    loading: false,
    isLoaded: true,
    failed: false,
    currentBalance: 0,
  });

  const getJWTByApiKey = async (apiKey: string) => {
    return await sdk.auth.getUserTokenByIssuerAPIKey(apiKey);
  };

  const getApiKey = async () => {
    const container = getContainer();

    const profileService = container.getDependency(
      "profileService"
    ) as IProfileService;

    profileService.fetchProfile();
    const updateApiKeysubscription$ = profileService.state$.pipe(
      filter((data) => !isEmpty(data.profile.ApiKey)),
      take(1)
    );
    const data = await lastValueFrom(updateApiKeysubscription$);
    return data.profile.ApiKey;
  };

  const reservePoints = async (amount: string) => {
    await sdk.user.card.addReserve({ amount: parseInt(amount, 10) });
  };

  const fetchBalance = async () => {
    try {
      stateMachine.setState({ loading: true });

      const response = await sdk.user.card.getBalanceHistory();
      const lastHistoryRecord = last(response.data.data);
      const currentBalance = lastHistoryRecord?.attributes.Balance;
      stateMachine.setState({
        currentBalance: currentBalance ?? 0,
        isLoaded: true,
        loading: false,
      });
    } catch (e) {
      stateMachine.setState({ failed: true, isLoaded: true, loading: false });
    }
  };

  const submitBonus = async (cardNumber: string, bonus: string) => {
    stateMachine.setState({ loading: true, isLoaded: false });

    const apiKey = await getApiKey();
    const jwt = await getJWTByApiKey(apiKey);
    sdk.user.setUserToken(jwt);

    await sdk.user.card.addPoints({
      cardNumber: cardNumber,
      description: "present from Santa",
      amount: parseInt(bonus, 10),
    });

    stateMachine.setState({ loading: false, isLoaded: true });
    const msg: PositiveMessageEntity = {
      data: BONUS_SUBMITTED,
      message: "",
      source: "bonusService",
      type: "positiveEvent",
    };
    messageBus.send(msg);
  };

  const simulatePurchase = async (cardNumber: string) => {
    await submitBonus(cardNumber, "4");
    await sleep(6000);
    await fetchBalance();
  };

  const sendNotificationByCard = async ({
    cardNumber,
    message,
  }: {
    cardNumber: string;
    message: string;
  }) => {
    stateMachine.setState({ loading: true });

    await sdk.issuer.sendNotificationToCards({
      cardNumbers: [cardNumber],
      message: message,
    });
    stateMachine.setState({ loading: false });
  };

  const updateUsername = async (
    cardNumber: string,
    name: { firstName: string; lastName: string }
  ) => {
    const { firstName, lastName } = name;

    stateMachine.setState({ loading: true, isLoaded: false });

    await sdk.user.card.updateCard({
      cardNumber,
      data: {
        first_name: firstName,
        last_name: lastName,
      },
    });
    stateMachine.setState({ loading: false, isLoaded: true });
    // const msg: PositiveMessageEntity = {
    //   data: BONUS_SUBMITTED,
    //   message: "",
    //   source: "bonusService",
    //   type: "positiveEvent",
    // };
    // messageBus.send(msg);
  };

  const downloadCard = async (iOSUrl: string = "", androidUrl: string = "") => {
    try {
      const isIOSSystem = isOS();

      window.location.href = isIOSSystem ? iOSUrl : androidUrl;

      messageBus.send({
        type: "GAanalyticsEvent",
        message: AN_DEMO_CARD_DOWNLOADED_BY_QR,
        source: "wizardService",
      });
    } catch (e) {
      const errorMessageBus = getContainer().getDependency(
        "errorMessageBus"
      ) as ErrorMessageBus;

      const msg = createErrorMessage({
        message: "Failed to download card",
        error: e as Error,
        source: "DownloadCard",
      });
      errorMessageBus.send(msg);
    }
  };

  const downloadAndroidCard = async (cardJwt: string) => {
    try {
      sdk.user.setUserToken(cardJwt);

      const url = await sdk.user.card.getAndroidCardDownloadLink();

      if (url) {
        window.open(url, "_self");
      }
    } catch (e) {
      const errorMessageBus = getContainer().getDependency(
        "errorMessageBus"
      ) as ErrorMessageBus;

      const msg = createErrorMessage({
        message: "Failed to download Android card",
        error: e as Error,
        source: "DownloadCardAndroid",
      });

      errorMessageBus.send(msg);
    }
  };

  return {
    data$: stateMachine.state$,
    getJWTByApiKey,
    getApiKey,
    reservePoints,
    fetchBalance,
    updateUsername,
    submitBonus,
    simulatePurchase,
    sendNotificationByCard,
    downloadCard,
    downloadAndroidCard,
  };
};
