import { GenericPassEntity } from "../../../domain/entities/GenericPassEntity/GenericPassEntity";
import { CreatePassDTO } from "../../../boundary/PassRepository/PassRepositoryDTO";

export const convertToCreatePassDTO = (
  pass: GenericPassEntity
): CreatePassDTO => {
  return {
    CreatedDate: new Date().toISOString(),
    PassAuxiliary: [],
    PassBack: [],
    PassBarcode: {
      BarcodeAltText: "979507116766808",
      BarcodeFormatId: 1,
      BarcodeMessage: "979507116766808",
      MessageEncodingId: 1,
      UseBarcode: true,
    },
    PassBeacon: [],
    PassHeader: pass.header.headerFields.map((field) => ({
      ChangeMessage: "%@",
      TypeId: 1,
      AlignmentId: parseInt(field.alignment, 10),
      Key: field.key,
      Label: field.label,
      Value: field.value,
    })),
    PassInfo: {
      AppLauncher: null,
      AuthenticationToken: null,
      CertificateId: null,
      CompanionApps: null,
      Description: pass.description ?? "",
      ExpirationDate: null,
      GroupingIdentifier: null,
      IgnoreTimezone: false,
      LogoText: pass.header.logoText ?? null,
      NonFree: false,
      OrganizationName: pass.orgName,
      PassTypeIdentifier: null,
      PreviewImgId: 0,
      RelevantDate: null,
      SerialNumber: null,
      StyleId: 4,
      TeamIdentifier: null,
      VoidPass: false,
      WebServiceURL: null,
      iTunesIdentifier: null,
    },
    PassLocation: [],
    PassPrimary: pass.body.primaryFields
      ? [
          {
            AlignmentId: 1,
            Key: pass.body.primaryFields.key,
            Label: pass.body.primaryFields.label,
            Value: pass.body.primaryFields.value,
            ChangeMessage: "%@",
            TypeId: 1,
          },
        ]
      : [],
    PassSecondary: pass.body.secondaryFields.map((field) => ({
      ChangeMessage: "%@",
      TypeId: 1,
      AlignmentId: parseInt(field.alignment, 10),
      Key: field.key,
      Label: field.label,
      Value: field.value,
    })),
    PassStyle: {
      Background: "#ec008c",
      BackgroundUrl: null,
      FooterUrl: null,
      Foreground: "#ffffff",
      IconUrl: "1",
      ImageStyleId: "1",
      Label: "#ffffff",
      LogoUrl: "2",
      StripUrl: null,
      ThumbnailUrl: null,
      TransitType: "PKTransitTypeAir",
    },
    Preview: "",
    TemplateId: 60,
  };
};
