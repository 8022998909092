import previewImg from "./images/pos-history.png";
import { POSPreview } from "./POSPreview";

export const PosHistoryPreview: React.FC = () => {
  return (
    <POSPreview
      paragraph="On the main Card Menu screen cashier will see all transactions history with statuses and Refund option"
      imageSrc={previewImg}
    />
  );
};
