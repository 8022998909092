// @ts-nocheck
// eslint-disable
// This file is generated by create-validator-ts
import Ajv from "ajv";
import * as apiTypes from "./GetTemplateResponseDTO";

export const SCHEMA = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $ref: "#/definitions/GetTemplateResponseDTO",
  definitions: {
    GetTemplateResponseDTO: {
      type: "object",
      properties: {
        status: {
          type: "string",
          const: "ok",
        },
        type: {
          type: "string",
          const: "data",
        },
        data: {
          $ref: "#/definitions/TemplateItem",
        },
      },
      required: ["status", "type", "data"],
      additionalProperties: false,
    },
    TemplateItem: {
      type: "object",
      properties: {
        PassInfo: {
          $ref: "#/definitions/PartialPassInfoDTO",
        },
        PassBeacon: {
          type: "array",
          minItems: 0,
          maxItems: 0,
        },
        PassBarcode: {
          anyOf: [
            {
              $ref: "#/definitions/PartialPassBarcode",
            },
            {
              type: "object",
              additionalProperties: false,
            },
          ],
        },
        PassStyle: {
          $ref: "#/definitions/PartialPassStyle",
        },
        PassHeader: {
          type: "array",
          items: {
            $ref: "#/definitions/PassPartialTextField",
          },
        },
        PassPrimary: {
          type: "array",
          items: {
            $ref: "#/definitions/PassPartialTextField",
          },
        },
        PassSecondary: {
          type: "array",
          items: {
            $ref: "#/definitions/PassPartialTextField",
          },
        },
        PassAuxiliary: {
          type: "array",
          items: {
            $ref: "#/definitions/PassPartialTextField",
          },
        },
        PassBack: {
          type: "array",
          items: {
            $ref: "#/definitions/PassPartialBackField",
          },
        },
        PassLocation: {
          type: "array",
          items: {
            $ref: "#/definitions/PartialPassLocation",
          },
        },
      },
      required: [
        "PassInfo",
        "PassBeacon",
        "PassBarcode",
        "PassStyle",
        "PassHeader",
        "PassPrimary",
        "PassSecondary",
        "PassAuxiliary",
        "PassBack",
        "PassLocation",
      ],
      additionalProperties: false,
    },
    PartialPassInfoDTO: {
      type: "object",
      properties: {
        Description: {
          type: "string",
        },
        LogoText: {
          type: ["string", "null"],
        },
        OrganizationName: {
          type: "string",
        },
        PassInfoId: {
          type: "number",
        },
        SerialNumber: {
          type: ["number", "string", "null"],
        },
        iTunesIdentifier: {
          type: ["string", "null"],
        },
        ExpirationDate: {
          type: ["string", "null"],
        },
        RelevantDate: {
          type: ["string", "null"],
        },
        StyleId: {
          type: "number",
        },
      },
      required: [
        "Description",
        "LogoText",
        "OrganizationName",
        "PassInfoId",
        "SerialNumber",
        "iTunesIdentifier",
        "ExpirationDate",
        "RelevantDate",
      ],
      additionalProperties: false,
    },
    PartialPassBarcode: {
      type: "object",
      properties: {
        PassInfoId: {
          type: "number",
        },
        UseBarcode: {
          type: "boolean",
        },
        BarcodeFormatId: {
          type: "number",
        },
        MessageEncodingId: {
          type: "number",
        },
        BarcodeMessage: {
          type: "string",
        },
        BarcodeAltText: {
          type: ["string", "null"],
        },
      },
      required: [
        "PassInfoId",
        "UseBarcode",
        "BarcodeFormatId",
        "MessageEncodingId",
        "BarcodeMessage",
        "BarcodeAltText",
      ],
      additionalProperties: false,
    },
    PartialPassStyle: {
      type: "object",
      properties: {
        PassInfoId: {
          type: "number",
        },
        ImageStyleId: {
          type: "string",
        },
        Background: {
          type: ["string", "null"],
        },
        Foreground: {
          type: ["string", "null"],
        },
        Label: {
          type: ["string", "null"],
        },
        LogoUrl: {
          type: ["string", "null"],
        },
        IconUrl: {
          type: "string",
        },
        StripUrl: {
          type: ["string", "null"],
        },
        ThumbnailUrl: {
          type: ["string", "null"],
        },
        BackgroundUrl: {
          type: ["string", "null"],
        },
        FooterUrl: {
          type: ["string", "null"],
        },
        TransitType: {
          type: "string",
        },
      },
      required: [
        "PassInfoId",
        "ImageStyleId",
        "Background",
        "Foreground",
        "Label",
        "LogoUrl",
        "IconUrl",
        "StripUrl",
        "ThumbnailUrl",
        "BackgroundUrl",
        "FooterUrl",
        "TransitType",
      ],
      additionalProperties: false,
    },
    PassPartialTextField: {
      type: "object",
      properties: {
        PassInfoId: {
          type: "number",
        },
        PassFieldId: {
          type: "number",
        },
        Value: {
          type: ["string", "null"],
        },
        Key: {
          type: ["string", "null"],
        },
        Label: {
          type: ["string", "null"],
        },
        AlignmentId: {
          type: ["number", "null"],
        },
        ChangeMessage: {
          type: ["string", "null"],
        },
        TypeId: {
          type: "number",
        },
      },
      required: [
        "PassInfoId",
        "PassFieldId",
        "Value",
        "Key",
        "Label",
        "AlignmentId",
        "TypeId",
      ],
      additionalProperties: false,
    },
    PassPartialBackField: {
      type: "object",
      properties: {
        PassInfoId: {
          type: "number",
        },
        PassBackId: {
          type: "number",
        },
        Value: {
          type: ["string", "null"],
        },
        Key: {
          type: ["string", "null"],
        },
        Label: {
          type: ["string", "null"],
        },
        AlignmentId: {
          type: ["number", "null"],
        },
        ChangeMessage: {
          type: ["string", "null"],
        },
      },
      required: [
        "PassInfoId",
        "PassBackId",
        "Value",
        "Key",
        "Label",
        "AlignmentId",
      ],
      additionalProperties: false,
    },
    PartialPassLocation: {
      type: "object",
      properties: {
        Latitude: {
          type: "number",
        },
        Longitude: {
          type: "number",
        },
        RelevantText: {
          type: ["string", "null"],
        },
        PassLocationId: {
          type: "number",
        },
        LocationName: {
          type: ["string", "null"],
        },
      },
      required: ["Latitude", "Longitude", "LocationName"],
      additionalProperties: false,
    },
  },
};
const ajv = new Ajv({ removeAdditional: true }).addSchema(SCHEMA, "SCHEMA");
export function validateGetTemplateResponseDTO(
  payload: unknown
): apiTypes.GetTemplateResponseDTO {
  /** Schema is defined in {@link SCHEMA.definitions.GetTemplateResponseDTO } **/
  const validator = ajv.getSchema("SCHEMA#/definitions/GetTemplateResponseDTO");
  const valid = validator(payload);
  if (!valid) {
    const error = new Error(
      "Invalid GetTemplateResponseDTO: " +
        ajv.errorsText(validator.errors, { dataVar: "GetTemplateResponseDTO" })
    );
    error.name = "ValidationError";
    throw error;
  }
  return payload;
}

export function isGetTemplateResponseDTO(
  payload: unknown
): payload is apiTypes.GetTemplateResponseDTO {
  try {
    validateGetTemplateResponseDTO(payload);
    return true;
  } catch (error) {
    return false;
  }
}
