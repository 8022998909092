import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BarChartIcon from "@mui/icons-material/BarChart";
import { NavLink } from "./NavLink/NavLink";
import { Link, Typography } from "@mui/material";

export const MainListItems: React.FC<{ isOpen: boolean }> = ({ isOpen }) => {
  const typographyProps = isOpen
    ? { paddingLeft: "45px", align: "left" as const }
    : { paddingLeft: "0", fontSize: "8px", align: "center" as const };

  return (
    <>
      <NavLink to="/dashboard">
        <ListItemButton>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
      </NavLink>

      <NavLink to="/analytics">
        <ListItemButton>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary="Analytics" />
        </ListItemButton>
      </NavLink>

      <Typography variant="body2" marginTop="30px" {...typographyProps}>
        {isOpen && <>Visit </>}
        <Link href="https://atomicloyalty.com/" target="_blank">
          AtomicLoyalty
        </Link>
      </Typography>
    </>
  );
};
