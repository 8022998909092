import {
  CircularProgress,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@mui/material";
import { useObservable } from "@roketus/web-toolkit";
import { getContainer } from "../../diContainer/container";
import { IWizardService } from "../../boundary/IWizardService";
import { IFeatureToggleService } from "../../boundary/IFeatureToggleService";
import { createElement, useMemo } from "react";
import { getSteps } from "../../adapters/services/wizardService/wizardService";
import Typography from "@mui/material/Typography";

const titles = [
  "Let’s Begin: Create your Loyalty Program",
  "Loyalty Program QR",
  "Loyalty Program Rules: Welcome Bonus Example",
  "Cashier: Scan a QR on the digital card",
  "POS Terminal Preview",
  "POS Terminal Preview",
  "POS Terminal Preview",
  "POS Terminal Preview",
  "Setup your Atomic Loyalty Account",
];

export const Wizard: React.FC = () => {
  const wizardService = getContainer().getDependency(
    "wizardService"
  ) as IWizardService;

  const wizardData = useObservable(wizardService.data$);

  const featureToggleService = getContainer().getDependency(
    "featureToggleService"
  ) as IFeatureToggleService;

  const featureData = useObservable(featureToggleService.data$);

  const isLoading =
    (wizardData && wizardData.loading) || (featureData && featureData.loading);

  const stepIndex = wizardData?.activeStep || 0;

  const steps = useMemo(
    () => getSteps(wizardData?.templateType),
    [wizardData?.templateType]
  );

  return (
    <>
      <Typography variant="h4" gutterBottom>
        {titles[stepIndex] || titles[0]}
      </Typography>

      {wizardData && (
        <>
          {isLoading && <CircularProgress />}
          <Stepper activeStep={wizardData.activeStep} orientation="vertical">
            {steps.map(({ label, component }) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>{createElement(component)}</StepContent>
              </Step>
            ))}
          </Stepper>
        </>
      )}
    </>
  );
};
