import { StepNavigation } from "../StepNavigation";
import { StepDetails } from "../StepDetails";
import { AddBonus } from "./AddBonus";
import { Divider, Typography } from "@mui/material";
import { WizardHelp } from "../WizardHelp";

export const PayWithPointsStep: React.FC = () => {
  return (
    <>
      <Typography gutterBottom>
        Cashier withdraws points from shopper’s bonus account and processes
        discounted amount in USD
      </Typography>
      <Divider variant="middle" flexItem sx={{ m: "20px" }} />
      <Typography gutterBottom>
        Simulate a USD100 transaction (pay with USD90 and 10 points) and get 9
        points (10% tier) from this transaction
      </Typography>
      <AddBonus />
      <StepDetails>
        <WizardHelp />
      </StepDetails>
      <StepNavigation />
    </>
  );
};
