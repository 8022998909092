import { GetPromoCodeDTO } from "../../../boundary/PassRepository/PassRepositoryDTO";

export const convertToGetPromoCodeDTO = (
  passId: string
): GetPromoCodeDTO => {
  return {
    PassInfo: {
      PassInfoId: parseInt(passId, 10),
    },
    PassBack: [
      {
        Value: "Promo code was added to your card",
        Key: "notification",
        Label: "Notification",
        ChangeMessage: "%@",
      },
      {
        Value: "JEANS30",
        Key: "promo",
        Label: "Promo code",
        // AlignmentId: 1,
        ChangeMessage: "%@",
      },
    ],
  };
};
