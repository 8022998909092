import { IImageRepository } from "../../../boundary/IImageRepository";
import { CreatePassEntity } from "../../entities/CreatePassEntity/CreatePassEntity";
import { isString } from "lodash";

const saveImageIfExists = async (
  data: CreatePassEntity,
  imageRepository: IImageRepository,
  key: keyof CreatePassEntity
) => {
  const imageId = data[key];
  if (!isString(imageId)) return data;

  const currentImage = await imageRepository.getById(imageId);
  if (currentImage?.isSaved) return data;

  const image = await imageRepository.save(imageId);
  // @ts-ignore unknown error about string can be assigned to never
  data[key] = image.id;

  return data;
};

export const createImagesUseCase = async (
  data: CreatePassEntity,
  imageRepository: IImageRepository
): Promise<CreatePassEntity> => {
  const currentIcon = await imageRepository.getById(data.iconId);
  if (!currentIcon?.isSaved) {
    const icon = await imageRepository.save(data.iconId);
    data.iconId = icon.id;
  }

  let newData = await saveImageIfExists(data, imageRepository, "logoId");
  newData = await saveImageIfExists(newData, imageRepository, "thumbnailId");
  newData = await saveImageIfExists(newData, imageRepository, "stripId");
  return await saveImageIfExists(newData, imageRepository, "backgroundId");
};
