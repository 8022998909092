import styled from "@emotion/styled";
import { getContainer } from "../../diContainer/container";
import { IWizardService } from "../../boundary/IWizardService";
import { useObservable } from "@roketus/web-toolkit";
import { getSteps } from "../../adapters/services/wizardService/wizardService";
import { isUndefined } from "lodash";
import { Box } from "@mui/material";

export const PreviewContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
`;

export const PreviewSection: React.FC = () => {
  const wizardService = getContainer().getDependency(
    "wizardService"
  ) as IWizardService;

  const wizardData = useObservable(wizardService.data$);
  const activeStep = wizardData?.activeStep;
  const templateType = wizardData?.templateType;
  
  const title = !isUndefined(activeStep)
    ? getSteps(templateType)[activeStep]?.helpTitle
    : "";

  const PreviewComponent = !isUndefined(activeStep)
    ? getSteps(templateType)[activeStep].previewComponent
    : Box;

  return (
    <>
      {title && <h2>{title}</h2>}
      <PreviewContainer>
        <PreviewComponent />
      </PreviewContainer>
    </>
  );
};
