import { FC, useEffect, useRef } from "react";
import { isNil } from "lodash";
import { ICardService } from "../../boundary/ICardService";
import { getContainer } from "../../diContainer/container";
import { useQuery } from "../../adapters/hooks/useQuery";
import {
  ANDROID_CARD_DOWNLOAD,
  IOS_CARD_DOWNLOAD,
} from "../../domain/specs/urlParams";
import CardDownloadMessageBox from "../CardDownloadMessageBox";

export const DownloadCard: FC = () => {
  let query = useQuery();
  const iOSUrl = query.get(IOS_CARD_DOWNLOAD);
  const androidUrl = query.get(ANDROID_CARD_DOWNLOAD);
  const isDownloading = useRef(false);

  const cardService = getContainer().getDependency(
    "cardService"
  ) as ICardService;

  const isValidParams = !isNil(iOSUrl) && !isNil(androidUrl);

  useEffect(() => {
    if (!isValidParams) return;

    if (isDownloading.current) return;

    cardService.downloadCard(iOSUrl, androidUrl);

    isDownloading.current = true;
  }, [cardService, iOSUrl, androidUrl, isValidParams]);

  return isValidParams ? <CardDownloadMessageBox /> : null;
};
