import { Typography } from "@mui/material";
import { StepNavigation } from "../StepNavigation";
import { StepDetails } from "../StepDetails";
import { WizardHelp } from "../WizardHelp";
import { GetPromoCode } from "./GetPromoCode";

export const PromoCodeStep: React.FC = () => {
  return (
    <>
      <Typography gutterBottom>
        You will receive a promo code on your card
      </Typography>
      <Typography gutterBottom>
        *You can check your promo code in Pass Details (three dots menu)
      </Typography>
      <Typography gutterBottom>
        *It may take up to 30 sec.
      </Typography>
      <GetPromoCode />
      <StepDetails>
        <WizardHelp />
      </StepDetails>
      <StepNavigation />
    </>
  );
};
