export const POSITIVE_CODE_UNKNOWN = "POSITIVE_CODE_UNKNOWN";
export const POSITIVE_TEMPLATE_CREATED = "POSITIVE_TEMPLATE_CREATED";
export const POSITIVE_TEMPLATE_UPDATED = "POSITIVE_TEMPLATE_UPDATED";
export const POSITIVE_NOTIFICATION_SEND = "POSITIVE_NOTIFICATION_SEND";
export const POSITIVE_SIGNUP = "POSITIVE_SIGNUP";
export const BONUS_SUBMITTED = "BONUS_SUBMITTED";
export const POSITIVE_PASS_CREATED = "POSITIVE_PASS_CREATED";
export const POSITIVE_PASS_UPDATED = "POSITIVE_PASS_UPDATED";
export const POSITIVE_TEMPLATE_DELETED = "POSITIVE_TEMPLATE_DELETED";
export const SIMULATE_PURCHASE_SUCCESS = "SIMULATE_PURCHASE_SUCCESS";
export const USERNAME_UPDATED = "USERNAME_UPDATED";
export const TEMPLATE_CHOSEN = "TEMPLATE_CHOSEN";
export const PASS_CREATED = "PASS_CREATED";
export const CHECK_DEVICE_NOTIFICATION = "CHECK_DEVICE_NOTIFICATION";
export const SUCCESS_SUBMIT_FEEDBACK_FORM = "SUCCESS_SUBMIT_FEEDBACK_FORM";
export const PROMO_CODE_RECEIVED = "PROMO_CODE_RECEIVED";
export const PROMO_CODE_USED = "PROMO_CODE_USED";
