import { getContainer } from "../../../diContainer/container";
import { ClientResponse, HTTPClient } from "../../../boundary/HTTPClient";
import { ITemplateRepository } from "../../../boundary/TemplateRepository/ITemplateRepository";
import {
  GenericPassEntity,
} from "../../../domain/entities/GenericPassEntity/GenericPassEntity";
import { convertToCreateTemplateDTO } from "../../data/GenericPassEntity/convertToCreateTemplateDTO";
import {
  CreateTemplateDTO,
  UpdateTemplateDTO,
} from "../../../boundary/TemplateRepository/TemplateRepositoryDTO";
import { convertTemplateListResponseDTOToTemplateEntity } from "../../data/TemplateListDTO/convertToTemplateEntity";
import {
  ERROR_CODE_TEMPLATE_REPOSITORY_NETWORK,
  ERROR_CODE_TEMPLATE_REPOSITORY_VALIDATION,
} from "../../../domain/specs/errorCodes";
import {
  createErrorMessage,
  ErrorMessageBus,
  isErrorMessage,
} from "@roketus/web-toolkit";
import { TemplateEntity } from "../../../domain/entities/TemplateEntity";
import { convertGetTemplateResponseDTOToTemplateEntity } from "../../data/GetTemplateResponseDTO/convertToTemplateEntity";
import { validateGetTemplateResponseDTO } from "../../../boundary/TemplateRepository/GetTemplateResponseDTO.validator";
import { validateTemplateListDTO } from "../../../boundary/TemplateRepository/TemplateListDTO.validator";

export const create = async (template: GenericPassEntity): Promise<void> => {
  const container = getContainer();
  const client = container.getDependency(
    "privatePassServerClients"
  ) as HTTPClient;

  const payload: CreateTemplateDTO = convertToCreateTemplateDTO(template);

  try {
    return client.post("/api/templateFullPassInfo", payload);
  } catch (e) {
    console.log(e);
  }
};

export const update = async (payload: UpdateTemplateDTO): Promise<void> => {
  const container = getContainer();
  const client = container.getDependency(
    "privatePassServerClients"
  ) as HTTPClient;

  try {
    return client.put("/api/templateFullPassInfo", payload);
  } catch (e) {
    console.log(e);
  }
};

const list = async ({ page = 0 }) => {
  const container = getContainer();
  const client = container.getDependency(
    "privatePassServerClients"
  ) as HTTPClient;

  const data = await client.get("api/templatePassInfo", {
    params: { page },
  });

  try {
    const validData = validateTemplateListDTO(data.data);

    return convertTemplateListResponseDTOToTemplateEntity(validData);
  } catch (e) {
    const messageBus = container.getDependency(
      "errorMessageBus"
    ) as ErrorMessageBus;
    const errorMessage = createErrorMessage({
      code: ERROR_CODE_TEMPLATE_REPOSITORY_VALIDATION,
      source: "templateRepository",
      message: "TemplatesDTO not valid",
      error: e as Error,
    });
    messageBus.send(errorMessage);

    return {
      templates: [],
      pagingData: {
        page: 0,
        pageSize: 0,
        totalData: 0,
        totalPages: 0,
      },
    };
  }
};

const deleteTemplate = async (id: string): Promise<void> => {
  const container = getContainer();
  try {
    const client = container.getDependency(
      "privatePassServerClients"
    ) as HTTPClient;

    await client.delete(`api/templatePassInfo/${id}`);
  } catch (e) {
    const messageBus = container.getDependency(
      "errorMessageBus"
    ) as ErrorMessageBus;
    const errorMessage = createErrorMessage({
      code: ERROR_CODE_TEMPLATE_REPOSITORY_NETWORK,
      source: "templateRepository",
      message: "Failed To delete template",
      error: e as Error,
    });
    messageBus.send(errorMessage);
  }
};

const getById = async (id: string): Promise<TemplateEntity | null> => {
  const container = getContainer();
  try {
    const client = container.getDependency(
      "privatePassServerClients"
    ) as HTTPClient;

    const data: ClientResponse<unknown> = await client.get(
      `api/templatePassInfo/${id}`
    );

    const validatedData = validateGetTemplateResponseDTO(data.data);
    const result = convertGetTemplateResponseDTOToTemplateEntity(validatedData);

    if (isErrorMessage(result)) {
      return null;
    }

    return result;
  } catch (e) {
    const messageBus = container.getDependency(
      "errorMessageBus"
    ) as ErrorMessageBus;
    const errorMessage = createErrorMessage({
      code: ERROR_CODE_TEMPLATE_REPOSITORY_NETWORK,
      source: "templateRepository",
      message: "Failed To get template",
      error: e as Error,
    });
    messageBus.send(errorMessage);

    return null;
  }
};

export const templateRepository: ITemplateRepository = {
  create,
  update,
  list,
  getById,
  delete: deleteTemplate,
};
