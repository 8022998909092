import { IMessageBus } from "@roketus/web-toolkit";
import { filter } from "rxjs";
import { isFileElementMessage } from "../../domain/entities/messages/formElement/FileElement/FileElementMessage";
import { createInputElementMessage } from "../../domain/entities/messages/formElement/InputElement/InputElementMessageBuilder";
import { getContainer } from "../../diContainer/container";
import {
  IImageRepository,
  ImageEntityType,
} from "../../boundary/IImageRepository";
import { PASS_ICON, PASS_LOGO } from "../../domain/specs/inputData";

const typeByKeyMap: Record<string, ImageEntityType> = {
  [PASS_ICON]: "icon",
  [PASS_LOGO]: "logo",
};

export const init = () => {
  const container = getContainer();
  const messageBus = container.getDependency("messageBus") as IMessageBus;
  const imageRepository = container.getDependency(
    "imageRepository"
  ) as IImageRepository;

  messageBus.stream$
    .pipe(filter(isFileElementMessage))
    .subscribe(async (file) => {
      const type = typeByKeyMap[file.message];
      const isSupportedKey = !!type;

      if (isSupportedKey) {
        const image = await imageRepository.create({
          file: file.data,
          type,
        });

        const payload = createInputElementMessage({
          message: file.message,
          source: "imageSaver",
          data: image.id,
        });
        messageBus.send(payload);
      }
    });
};
