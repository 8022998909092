import {
  Feature,
  IFeatureToggleData,
  IFeatureToggleService,
  IFeatureToggleServiceResponseDTO,
} from "../../boundary/IFeatureToggleService";
import {
  createErrorMessage,
  ErrorMessageBus,
  IHTTPClientInstance,
  IHTTPClientResponse,
  StateSubject,
} from "@roketus/web-toolkit";
import { Features } from "../../domain/specs/features";
import { ERROR_CODE_FAILED_FETCH_FEATURES_DATA } from "../../domain/specs/errorCodes";

const stateMachine = new StateSubject<IFeatureToggleData>({
  loading: false,
  isLoaded: false,
  failed: false,
  getFeatureByName: () => undefined,
});

const fetchFeaturesAPI = async function (
  issuerLogin: string,
  client: IHTTPClientInstance
): Promise<IFeatureToggleServiceResponseDTO> {
  const responseFeatures: IHTTPClientResponse<{
    data: IFeatureToggleServiceResponseDTO;
  }> = await client.get("/getconfig", {
    baseURL: "https://pwa-config.roket.us",
    params: {
      env: `${process.env.REACT_APP_ENVIRONMENT as string}/${issuerLogin}`,
      ver: process.env.REACT_APP_VERSION as string,
    },
    headers: {
      "x-api-key": process.env.REACT_APP_PARAM_API_KEY as string,
    },
  });

  return responseFeatures.data.data;
};

const fetchFeatures = async function (
  userName: string,
  errorMessageBus: ErrorMessageBus,
  client: IHTTPClientInstance
): Promise<void> {
  try {
    const responseFeatures = await fetchFeaturesAPI(userName, client);

    const getFeatureByName = <R extends Feature>(
      feature: Features
    ): R | undefined => responseFeatures[feature] as R | undefined;

    stateMachine.setState({
      loading: false,
      isLoaded: true,
      getFeatureByName,
    });
  } catch (e) {
    stateMachine.setState({
      loading: false,
      isLoaded: true,
      failed: true,
    });
    const errorMessage = createErrorMessage({
      code: ERROR_CODE_FAILED_FETCH_FEATURES_DATA,
      source: "FeatureToggleService",
      message: "Failed To Fetch Features",
      error: e as Error,
    });
    errorMessageBus.send(errorMessage);
  }
};

const fetchFeaturesIfAuthed = (
  errorMessageBus: ErrorMessageBus,
  client: IHTTPClientInstance
) =>
  async function (): Promise<void> {
    stateMachine.setState({
      loading: true,
    });

    await fetchFeatures(
      process.env.REACT_APP_FEATURE_KEY || "",
      errorMessageBus,
      client
    );
  };
export const init = function (
  errorMessageBus: ErrorMessageBus,
  client: IHTTPClientInstance
): IFeatureToggleService {
  return {
    fetchFeatures: fetchFeaturesIfAuthed(errorMessageBus, client),
    data$: stateMachine.state$,
  };
};
