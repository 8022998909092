import { FC } from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import { createRenderBarcode } from "../../barcodeHelper";

const Container = styled(Box)`
  display: block;
  width: 95px;
  height: 95px;
  padding: 9px;
  background-color: white;
  border-radius: 4px;
  box-sizing: content-box;
`;

const Canvas = styled.canvas`
  width: 95px;
  height: 95px;
`;

export const BarcodeQR: FC<{ text: string }> = ({ text }) => {
  const renderBarcode = createRenderBarcode({
    type: "QR_CODE",
    text,
  });
  return (
    <Container>
      <Canvas ref={renderBarcode} />
    </Container>
  );
};
