import { createErrorMessage, ErrorMessage } from "@roketus/web-toolkit";
import {
  ERROR_CODE_PASS_ENTITY_DESCRIPTION_REQUIRED,
  ERROR_CODE_PASS_ENTITY_BARCODE_ENCODING_REQUIRED,
  ERROR_CODE_PASS_ENTITY_BARCODE_FORMAT_REQUIRED,
  ERROR_CODE_PASS_ENTITY_BARCODE_MESSAGE_REQUIRED,
  ERROR_CODE_PASS_ENTITY_DESCRIPTION_TYPE,
  ERROR_CODE_PASS_ENTITY_ICON_REQUIRED,
  ERROR_CODE_PASS_ENTITY_ICON_SIZE,
  ERROR_CODE_PASS_ENTITY_ICON_TYPE,
  ERROR_CODE_PASS_ENTITY_LOGO_SIZE,
  ERROR_CODE_PASS_ENTITY_LOGO_TYPE,
  ERROR_CODE_PASS_ENTITY_ORG_NAME_REQUIRED,
} from "../../specs/errorCodes";
import { isNull, isString } from "lodash";
import { UnverifiedCreatePassEntity } from "./CreatePassEntity";
import {
  MAX_ICON_SIZE,
  MAX_LOGO_WIDTH,
  MAX_LOGO_HEIGHT,
} from "../../specs/imageSizes";
import { IImageRepository } from "../../../boundary/IImageRepository";

const validateImageFile = async (
  imgFile: File,
  condition: (params: { width: number; height: number }) => boolean
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const _URL = window.URL || window.webkitURL;
    const img = new Image();
    const objectUrl = _URL.createObjectURL(imgFile);
    img.onload = () => {
      if (condition({ width: img.width, height: img.height })) {
        resolve();
      } else {
        reject();
      }
      _URL.revokeObjectURL(objectUrl);
    };
    img.src = objectUrl;
  });
};

export const isValidIconSize = ({
  width,
  height,
}: {
  width: number;
  height: number;
}) => width <= MAX_ICON_SIZE && height <= MAX_ICON_SIZE;

export const validateIconFile = async (icon: File): Promise<void> => {
  return validateImageFile(icon, isValidIconSize);
};

export const isValidLogoSize = ({
  width,
  height,
}: {
  width: number;
  height: number;
}) => width <= MAX_LOGO_WIDTH && height <= MAX_LOGO_HEIGHT;

export const validateLogoFile = async (logo: File): Promise<void> => {
  return validateImageFile(logo, isValidLogoSize);
};

const source = "validateCreatePassEntity";
export const validateCreatePassEntity = async (
  value: UnverifiedCreatePassEntity,
  imageRepository: IImageRepository
): Promise<ErrorMessage[]> => {
  const errors: ErrorMessage[] = [];

  if (!value.orgName) {
    errors.push(
      createErrorMessage({
        source,
        message: "Orgname is required",
        code: ERROR_CODE_PASS_ENTITY_ORG_NAME_REQUIRED,
      })
    );
  }
  if (!value.description) {
    errors.push(
      createErrorMessage({
        source,
        message: "Description is required",
        code: ERROR_CODE_PASS_ENTITY_DESCRIPTION_REQUIRED,
      })
    );
  }
  if (!isString(value.iconId)) {
    errors.push(
      createErrorMessage({
        source,
        message: "icon is required",
        code: ERROR_CODE_PASS_ENTITY_ICON_REQUIRED,
      })
    );
  } else {
    const iconFile = await imageRepository.getMetaById(value.iconId);
    if (isNull(iconFile)) {
      errors.push(
        createErrorMessage({
          source,
          message: "icon should be a File",
          code: ERROR_CODE_PASS_ENTITY_ICON_TYPE,
        })
      );
    } else {
      try {
        await validateIconFile(iconFile.file);
      } catch (err) {
        errors.push(
          createErrorMessage({
            source,
            message: `icon should be ${MAX_ICON_SIZE}x${MAX_ICON_SIZE}`,
            code: ERROR_CODE_PASS_ENTITY_ICON_SIZE,
          })
        );
      }
    }
  }

  if (value.description && !isString(value.description)) {
    errors.push(
      createErrorMessage({
        source,
        message: "description should be string",
        code: ERROR_CODE_PASS_ENTITY_DESCRIPTION_TYPE,
      })
    );
  }

  if (isString(value.logoId)) {
    const logoFile = await imageRepository.getMetaById(value.logoId);
    if (isNull(logoFile)) {
      errors.push(
        createErrorMessage({
          source,
          message: "logo should be a File",
          code: ERROR_CODE_PASS_ENTITY_LOGO_TYPE,
        })
      );
    } else {
      try {
        await validateLogoFile(logoFile.file);
      } catch (err) {
        errors.push(
          createErrorMessage({
            source,
            message: `logo should be ${MAX_LOGO_WIDTH}x${MAX_LOGO_HEIGHT}`,
            code: ERROR_CODE_PASS_ENTITY_LOGO_SIZE,
          })
        );
      }
    }
  }

  if (value.barcode_use) {
    if (!value.barcode_encoding) {
      errors.push(
        createErrorMessage({
          source,
          message: "barcode encoding is required",
          code: ERROR_CODE_PASS_ENTITY_BARCODE_ENCODING_REQUIRED,
        })
      );
    }

    if (!value.barcode_format) {
      errors.push(
        createErrorMessage({
          source,
          message: "barcode format is required",
          code: ERROR_CODE_PASS_ENTITY_BARCODE_FORMAT_REQUIRED,
        })
      );
    }

    if (!value.barcode_message) {
      errors.push(
        createErrorMessage({
          source,
          message: "barcode message is required",
          code: ERROR_CODE_PASS_ENTITY_BARCODE_MESSAGE_REQUIRED,
        })
      );
    }
  }
  return errors.length ? errors : [];
};
