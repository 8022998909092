import { LoadableData, StateSubject } from "@roketus/web-toolkit";
import { sdk } from "@roketus/loyalty-js-sdk";
import { IPassNotificationService } from "../../boundary/IPassNotificationService";

export const init = (): IPassNotificationService => {
  const stateMachine = new StateSubject<LoadableData>({
    loading: false,
    isLoaded: false,
    failed: false,
  });

  const sendNotificationsAll = async (message: string) => {
    try {
      stateMachine.setState({ loading: true });
      await sdk.issuer.sendToAllIssuerCards({
        title: "Notify",
        message,
      });
    } catch (e) {
      stateMachine.setState({ failed: true, isLoaded: true, loading: false });
    }
  };

  return {
    sendNotificationsAll,
    state$: stateMachine.state$,
  };
};
