import { Box, Typography } from "@mui/material";
import androidCard from "./android-card.jpg";
import iosCard from "./ios-card.jpg";
import styled from "@emotion/styled";

const BoxContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const Img = styled.img`
  width: 100%;
  max-width: 220px;
  height: 100%;
`;

export const WelcomeBonusPreview: React.FC = () => {
  return (
    <Box>
      <BoxContainer>
        <Box>
          <Img src={iosCard} alt="IOS card preview" />
        </Box>
        <Box>
          <Img src={androidCard} alt="Android card preview" />
        </Box>
      </BoxContainer>
      <Typography sx={{ mt: "20px" }}>
        You can set up your own loyalty rules with our system, for example, a
        Welcome Bonus when the user installs your digital card
      </Typography>
    </Box>
  );
};
