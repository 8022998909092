import { Box, Button, Typography } from "@mui/material";
import { IWizardService } from "../../../boundary/IWizardService";
import { getContainer } from "../../../diContainer/container";
import { useObservable } from "@roketus/web-toolkit";

export const AddStampCardBonus: React.FC = () => {
  const wizardService = getContainer().getDependency(
    "wizardService"
  ) as IWizardService;

  const wizardData = useObservable(wizardService.data$);

  const handleApply = () => {
    wizardService.addStampCardBonus();
  };

  const isTransactionActivated =
    wizardData?.loading || wizardData?.isExecutedTransaction;

  return (
    <Box m="15px 0 10px 0">
      <Button
        onClick={handleApply}
        variant="contained"
        disabled={isTransactionActivated}
        sx={{ mb: "5px" }}
      >
        Stamp
      </Button>
      {isTransactionActivated && (
        <Typography>Check your downloaded card for updated bonuses</Typography>
      )}
    </Box>
  );
};
