import { ExistingPassEntity } from "../../../domain/entities/GenericPassEntity/GenericPassEntity";
import { PagingData } from "../../../boundary/PagingData";
import { createErrorMessage, ErrorMessage } from "@roketus/web-toolkit";
import { get, head, isArray, isEmpty, isNumber } from "lodash";
import { PartialPassBarcode } from "../../../boundary/PartialPassDTO";
import {
  convertBackTextField,
  convertBarcode,
  convertTextField,
} from "../TemplateListDTO/convertToTemplateEntity";
import { PassListResponseDTO } from "../../../boundary/PassRepository/PassListResponseDTO";
import { convertStyleIDtoPassTypeEntity } from "../GetTemplateResponseDTO/convertToTemplateEntity";
import {
  defaultBackgroundColor,
  defaultForegroundColor,
  defaultLabelColor,
} from "../../../domain/specs/defaultValues";

export const convertPassListResponseDTOToPassEntity = (
  payload: PassListResponseDTO
): { passes: ExistingPassEntity[]; pagingData: PagingData } | ErrorMessage => {
  const result: {
    passes: ExistingPassEntity[];
    pagingData: PagingData;
  } = {
    passes: [],
    pagingData: {
      page: 0,
      pageSize: 0,
      totalData: 0,
      totalPages: 0,
    },
  };

  const { data } = payload;
  const passes = data.Passes;
  const pagingData = data.PagingData;

  if (!isArray(passes)) {
    return createErrorMessage({
      message: "Passes is not an array",
      source: "convertPassListResponseDTOToPassEntity",
    });
  }

  passes.forEach((pass) => {
    const {
      PassInfo,
      PassStyle,
      PassPrimary,
      PassSecondary,
      PassAuxiliary,
      PassHeader,
      PassBack,
      PassBarcode,
    } = pass;

    const {
      OrganizationName,
      Description,
      LogoText,
      ExpirationDate,
      RelevantDate,
      PassInfoId,
    } = PassInfo;
    const { IconUrl, LogoUrl, Label, Background, Foreground } = PassStyle;
    const primaryField = head(PassPrimary);

    const convertedPass: ExistingPassEntity = {
      orgName: OrganizationName,
      id: PassInfoId.toString(10),
      description: Description,
      expirationDate: ExpirationDate,
      relevantDate: RelevantDate,
      iconId: IconUrl,
      type: convertStyleIDtoPassTypeEntity(PassInfo.StyleId ?? 4),
      design: {
        backgroundColor: Background ?? defaultBackgroundColor,
        foregroundColor: Foreground ?? defaultForegroundColor,
        labelColor: Label ?? defaultLabelColor,
        backgroundId: PassStyle.BackgroundUrl,
        stripId: PassStyle.StripUrl,
        thumbnailId: PassStyle.ThumbnailUrl,
      },
      header: {
        headerFields: PassHeader.map(convertTextField),
        logoId: LogoUrl ?? undefined,
        logoText: LogoText ?? undefined,
      },
      body: {
        primaryFields: primaryField
          ? convertTextField(primaryField)
          : undefined,
        secondaryFields: PassSecondary.map(convertTextField),
        auxiliaryFields: PassAuxiliary.map(convertTextField),
        passBack: PassBack.map(convertBackTextField),
      },
    };

    if (!isEmpty(PassBarcode)) {
      const barcode = PassBarcode as PartialPassBarcode;
      convertedPass.barcode = convertBarcode(barcode);
    }

    result.passes.push(convertedPass);
  });

  const page = get(pagingData, "Page");
  const pageSize = get(pagingData, "PageSize");
  const totalData = get(pagingData, "TotalData");
  const totalPages = get(pagingData, "TotalPages");

  if (!isNumber(page)) {
    return createErrorMessage({
      message: "Page is not a number",
      source: "convertPassListResponseDTOToPassEntity",
    });
  }

  if (!isNumber(pageSize)) {
    return createErrorMessage({
      message: "pageSize is not a number",
      source: "convertPassListResponseDTOToPassEntity",
    });
  }

  if (!isNumber(totalData)) {
    return createErrorMessage({
      message: "totalData is not a number",
      source: "convertPassListResponseDTOToPassEntity",
    });
  }

  if (!isNumber(totalPages)) {
    return createErrorMessage({
      message: "totalPages is not a number",
      source: "convertPassListResponseDTOToPassEntity",
    });
  }

  result.pagingData = {
    page,
    pageSize,
    totalData,
    totalPages,
  };

  return result;
};
