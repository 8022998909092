import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const PrimaryFieldsSection = styled.div`
  display: flex;
  height: 72px;
  flex: 1;
  margin: 27px 4px 2px;
`;

export const PrimaryFieldsSectionInner = styled(Box)<{ order?: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${(props) => props.order || "column"};
  justify-content: ${(props) => props.order === "column" ? "flex-start" : "flex-end"};
  white-space: nowrap;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #fff;
`;
