import { sdk, ISDK } from "@roketus/loyalty-js-sdk";
import { filter } from "rxjs";
import { isUndefined } from "lodash";
import { isAuthMessageEntity } from "../domain/entities/messages/authEntity/authEntity";
import { IMessageBus } from "@roketus/web-toolkit/dist/cjs/reactive/MessageBus";

export const initSdk = (messageBus: IMessageBus): ISDK => {
  sdk.init(process.env.REACT_APP_LOYALTY_API_URL);
  sdk.logger.setup({
    serviceName: "us-demo-loyalty-dashboard",
    environment: process.env.REACT_APP_ENVIRONMENT as
      | "develop"
      | "stage"
      | "production",
  });

  messageBus.stream$.pipe(filter(isAuthMessageEntity)).subscribe((authData) => {
    if (!isUndefined(authData.data.token)) {
      sdk.issuer.setIssuerToken(authData.data.token);
    }
  });

  return sdk;
};
