import { useState } from "react";
import { Button, Box } from "@mui/material";
import { PreviewTemplateModal } from "../modals/PreviewTemplateModal";

interface IWizardHelpProps {
  buttonLabel?: string;
  buttonType?: "outlined" | "contained";
}

export const WizardHelp: React.FC<IWizardHelpProps> = ({
  buttonLabel = "Preview",
  buttonType = "outlined",
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <Box>
      <Button
        sx={{ mt: 2 }}
        onClick={() => setIsModalOpen(true)}
        variant={buttonType}
      >
        {buttonLabel}
      </Button>
      <PreviewTemplateModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </Box>
  );
};
