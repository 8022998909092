import previewImg from "./images/relevant-time.png";
import { POSPreview } from "./POSPreview";

export const RelevantTimePreview: React.FC = () => {
  return (
    <POSPreview
      paragraph="Apple Wallet or Google Pay determines the timing when the shortcut appears and varies by pass type"
      imageSrc={previewImg}
      paragraphPlacement="bottom"
    />
  );
};
