import { IMessage } from "@roketus/web-toolkit";
import { InputElementMessage } from "./InputElementMessage";

const TYPE = "inputElementUpdate";

type InputUpdateMessageType = typeof TYPE;
export interface InputUpdateMessage extends IMessage {
  type: InputUpdateMessageType;
  message: string; // aka input key
  source: string;
  data: string | boolean;
}

export const isUpdateMessage = (
  value: IMessage
): value is InputElementMessage => {
  return value.type === TYPE;
};
