import { Button, Typography, Link } from "@mui/material";
import { getContainer } from "../../../diContainer/container";
import { IWizardService } from "../../../boundary/IWizardService";
import { StepDetails } from "../StepDetails";
import { Android, Apple } from "@mui/icons-material";
import { IMessageBus, useObservable } from "@roketus/web-toolkit";
import { AN_DEMO_CARD_DOWNLOADED_BY_QR } from "../../../domain/specs/userAnalyticsCode";
import { WizardHelp } from "../WizardHelp";
import { useIsMobile } from "../../../adapters/hooks/useIsMobile";
import { StepNavigation } from "../StepNavigation";

export const InstallCardPhoneStep: React.FC = () => {
  const wizardService = getContainer().getDependency(
    "wizardService"
  ) as IWizardService;

  const data$ = useObservable(wizardService.data$);

  const isMobile = useIsMobile();

  const sendDownloadEvent = () => {
    const messageBus = getContainer().getDependency(
      "messageBus"
    ) as IMessageBus;

    messageBus.send({
      type: "GAanalyticsEvent",
      message: AN_DEMO_CARD_DOWNLOADED_BY_QR,
      source: "wizardService",
    });
  };

  return (
    <>
      <Typography gutterBottom>
        Follow the instructions {isMobile ? "in Preview" : "on the right"}
      </Typography>
      <Typography gutterBottom>
        Click NEXT when the card is installed to your wallet to continue with
        the demo
      </Typography>
      <StepDetails>
        <>
          <Link
            href={data$?.downloadIOSCardUrl}
            target="_blank"
            underline="none"
            onClick={sendDownloadEvent}
          >
            <Button
              startIcon={<Apple />}
              variant="outlined"
              sx={{ mt: 1, mr: 1 }}
              color="secondary"
              about="IOS"
            >
              IOS
            </Button>
          </Link>
          <Link
            href={data$?.downloadAndroidCardUrl}
            target="_blank"
            underline="none"
            onClick={sendDownloadEvent}
          >
            <Button
              startIcon={<Android />}
              variant="outlined"
              sx={{ mt: 1, mr: 1 }}
              color="secondary"
              about="Android"
            >
              Android
            </Button>
          </Link>
          <WizardHelp />
        </>
      </StepDetails>
      <StepNavigation />
    </>
  );
};
