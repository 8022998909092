import { IMessage } from "@roketus/web-toolkit";
import { get, isString } from "lodash";

export interface FileElementMessage extends IMessage {
  type: "fileElementChanged";
  message: string; // aka input key
  source: string;
  data: File;
}

// @TODO add test
// @TODO remove ts-ignore
export const isFileElementMessage = (
  value: unknown
): value is FileElementMessage => {
  const type = get(value, "type");
  const message = get(value, "message");
  const source = get(value, "source");
  const data = get(value, "data");

  return (
    type === "fileElementChanged" &&
    isString(message) &&
    isString(source) &&
    //@ts-ignore
    data instanceof File
  );
};
