import { TextFieldAlignment, TextFieldEntity } from "../TextFieldEntity";
import { BarcodeFormat } from "../BarcodeEntity";

export enum PassTypeEntity {
  BoardingPass,
  Coupon,
  EventTicket,
  Generic,
  StoreCard,
}

export interface CreatePassEntity {
  orgName: string;
  description: string;
  expirationDate: string | null;
  relevantDate: string | null;
  iconId: string;
  logoId?: string;
  logoText?: string;
  type: string;

  header_field_1_label?: string;
  header_field_1_value?: string;
  header_field_1_key?: string;
  header_field_1_align?: TextFieldAlignment;
  header_field_2_label?: string;
  header_field_2_value?: string;
  header_field_2_key?: string;
  header_field_2_align?: TextFieldAlignment;
  header_field_3_label?: string;
  header_field_3_value?: string;
  header_field_3_key?: string;
  header_field_3_align?: TextFieldAlignment;

  body_primary_label?: string;
  body_primary_value?: string;
  body_primary_key?: string;

  body_secondary_1_label?: string;
  body_secondary_1_value?: string;
  body_secondary_1_key?: string;
  body_secondary_1_align?: TextFieldAlignment;

  body_secondary_2_label?: string;
  body_secondary_2_value?: string;
  body_secondary_2_key?: string;
  body_secondary_2_align?: TextFieldAlignment;

  body_secondary_3_label?: string;
  body_secondary_3_value?: string;
  body_secondary_3_key?: string;
  body_secondary_3_align?: TextFieldAlignment;

  body_secondary_4_label?: string;
  body_secondary_4_value?: string;
  body_secondary_4_key?: string;
  body_secondary_4_align?: TextFieldAlignment;

  headerFields: TextFieldEntity[];

  style_background_color?: string;
  style_foreground_color?: string;
  style_label_color?: string;

  barcode_format?: BarcodeFormat;
  barcode_encoding?: string;
  barcode_message?: string;
  barcode_alt_text?: string;
  barcode_use?: boolean;

  location_1_latitude?: string;
  location_1_longitude?: string;
  location_1_relevant_text?: string;
  location_1_location_name?: string;

  location_2_latitude?: string;
  location_2_longitude?: string;
  location_2_relevant_text?: string;
  location_2_location_name?: string;

  location_3_latitude?: string;
  location_3_longitude?: string;
  location_3_relevant_text?: string;
  location_3_location_name?: string;

  location_4_latitude?: string;
  location_4_longitude?: string;
  location_4_relevant_text?: string;
  location_4_location_name?: string;

  location_5_latitude?: string;
  location_5_longitude?: string;
  location_5_relevant_text?: string;
  location_5_location_name?: string;

  location_6_latitude?: string;
  location_6_longitude?: string;
  location_6_relevant_text?: string;
  location_6_location_name?: string;

  location_7_latitude?: string;
  location_7_longitude?: string;
  location_7_relevant_text?: string;
  location_7_location_name?: string;

  location_8_latitude?: string;
  location_8_longitude?: string;
  location_8_relevant_text?: string;
  location_8_location_name?: string;

  location_9_latitude?: string;
  location_9_longitude?: string;
  location_9_relevant_text?: string;
  location_9_location_name?: string;

  location_10_latitude?: string;
  location_10_longitude?: string;
  location_10_relevant_text?: string;
  location_10_location_name?: string;

  thumbnailId?: string;
  stripId?: string;
  backgroundId?: string;

  body_auxiliary_1_label?: string;
  body_auxiliary_1_value?: string;
  body_auxiliary_1_key?: string;
  body_auxiliary_1_align?: TextFieldAlignment;

  body_auxiliary_2_label?: string;
  body_auxiliary_2_value?: string;
  body_auxiliary_2_key?: string;
  body_auxiliary_2_align?: TextFieldAlignment;

  body_auxiliary_3_label?: string;
  body_auxiliary_3_value?: string;
  body_auxiliary_3_key?: string;
  body_auxiliary_3_align?: TextFieldAlignment;

  body_auxiliary_4_label?: string;
  body_auxiliary_4_value?: string;
  body_auxiliary_4_key?: string;
  body_auxiliary_4_align?: TextFieldAlignment;

  body_auxiliary_5_label?: string;
  body_auxiliary_5_value?: string;
  body_auxiliary_5_key?: string;
  body_auxiliary_5_align?: TextFieldAlignment;

  body_back_1_label?: string;
  body_back_1_value?: string;
  body_back_1_key?: string;

  body_back_2_label?: string;
  body_back_2_value?: string;
  body_back_2_key?: string;

  body_back_3_label?: string;
  body_back_3_value?: string;
  body_back_3_key?: string;

  body_back_4_label?: string;
  body_back_4_value?: string;
  body_back_4_key?: string;

  body_back_5_label?: string;
  body_back_5_value?: string;
  body_back_5_key?: string;
}

type UnverifiedEntity<Entity> = {
  [Properties in keyof Entity]?: unknown;
};

export interface PassField {
  align?: "left" | "right" | "center" | "inherit";
  value?: string;
  label?: string;
  key?: string;
}

export type UnverifiedCreatePassEntity = UnverifiedEntity<CreatePassEntity>;
